import React, { useState, useContext } from "react";
import { Navigate, Routes, Route, NavLink, useMatch } from "react-router-dom";

import API from '../classes/API';

import LOCATION_TYPES from '../classes/LOCATION_TYPES';

import VacationRequest from './VacationRequest';
import OutOfOfficeRequest from './OutOfOfficeRequest';
import SickRequest from './SickRequest';
import HomeofficeRequest from './HomeofficeRequest';
import HelpLink from './../components/HelpLink';

import { AppStateContext } from '../components/AppStateProvider';

export default function Requests({ sessionToken }) {
    const { AddSnackbar } = useContext(AppStateContext);
    const [requestType, setRequestType] = useState(null);
    const [factor, setFactor] = useState("1");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [isRequestSuccessful, setIsRequestSuccessful] = useState(false);

    const formatDate = (date, format) => {
        let newDate = new Date(date);
        let day = newDate.getDate();
        let month = newDate.getMonth();

        month = month + 1;

        if ((String(day)).length === 1) {
            day = '0' + day;
        }

        if ((String(month)).length === 1) {
            month = '0' + month;
        }

        if (format === "yyyy-mm-dd") {
            return newDate.getFullYear() + "-" + month + "-" + day;
        } else {
            return day + '.' + month + '.' + newDate.getFullYear();
        }

    }

    const _setStartDateHandler = (newStartDate = null) => {
        if (newStartDate === null) { return; }
        if (factor === "0.5" || new Date(newStartDate).setHours(0, 0, 0, 0) > new Date(endDate).setHours(0, 0, 0, 0)) {
            setStartDate(newStartDate, setEndDate(newStartDate));
        } else {
            setStartDate(newStartDate);
        }
    }

    const _setEndDateHandler = (newEndDate = null) => {
        if (newEndDate === null) { return; }
        if (new Date(startDate).setHours(0, 0, 0, 0) > new Date(newEndDate).setHours(0, 0, 0, 0)) {
            //TODO Snackbar for Warning
            return;
        }
        setEndDate(newEndDate);

    }

    const _getRandomRequestTitle = () => {
        var titles = [
            "Yeah! 🎉",
            "Cool! 🕶",
            "Yippie! 🎊",
            "Aye! ⚓️",
            "Arrrr! 🦜",
            "Okeydokey! 👌",
            "Geil! 🔥",
            "Juhu! 🙌",
            "Roger Roger! 🤖",
            "Stark! 💪"
        ];
        return titles[(Math.floor(Math.random() * ((titles.length - 1) - 0)) + 0)];
    }

    if (isRequestSuccessful) {
        return (<Navigate push to={`${process.env.PUBLIC_URL}/dashboard/finish`} />);
    }


    if (requestType === null || requestType === undefined) {
        var locationTypes = [];
        for (var locationType in LOCATION_TYPES) {
            if (!LOCATION_TYPES[locationType].hide) {
                locationTypes.push(
                    <li key={`REQUEST_TYPE_${LOCATION_TYPES[locationType].name}`} className={LOCATION_TYPES[locationType].name.toLowerCase()}>
                        <NavLink to={`${process.env.PUBLIC_URL}/antrag-hinzufuegen/${LOCATION_TYPES[locationType].label.toLowerCase().replace(/\s/gm, "-")}`}>
                                <b>{LOCATION_TYPES[locationType].label}</b>{(LOCATION_TYPES[locationType].name === "SICK" || LOCATION_TYPES[locationType].name === "SICK_INOFFICIAL") ? "einreichen" : "beantragen"}
                                <span>
                                    <svg width="13px" height="14px" viewBox="0 0 13 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                                            <g transform="translate(-359.000000, -318.000000)" stroke="#1F1F24" strokeWidth="2">
                                                <g>
                                                    <g transform="translate(18.000000, 240.000000)">
                                                        <g transform="translate(328.507000, 66.507000)">
                                                            <g transform="translate(13.090000, 13.090000)">
                                                                <line x1="0.403" y1="5.403" x2="10.403" y2="5.403" id="Linie_41" strokeLinejoin="round"></line>
                                                                <polyline id="Pfad_35" points="5.403 0 10.803 5.4 5.403 10.8"></polyline>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                        </NavLink>
                    </li>
                );
            }
        }
        return (
            <div className="we-request">
                <Routes>
                    <Route path="/" element={
                        <>
                            <h2>Hinzuf&uuml;gen</h2>
                            <p>Was m&ouml;chtest du hinzuf&uuml;gen</p>
                            <ul className="we-request__actions">
                                {locationTypes}
                            </ul>
                        </>
                    }/>
                        
                    <Route path="homeoffice" element={<HomeofficeRequest
                            sessionToken={sessionToken}
                            setRequestType={setRequestType}
                            startDate={startDate}
                            setStartDate={_setStartDateHandler}
                            endDate={endDate}
                            setEndDate={_setEndDateHandler}
                            factor={factor}
                            formatDate={formatDate}
                            setIsRequestSuccessful={setIsRequestSuccessful}
                            AddSnackbar={AddSnackbar}
                            _getRandomRequestTitle={_getRandomRequestTitle}
                            API={API}
                        />} />
                        
                    <Route path="/urlaub" element={<VacationRequest
                            sessionToken={sessionToken}
                            requestType={requestType}
                            setRequestType={setRequestType}
                            startDate={startDate}
                            setStartDate={_setStartDateHandler}
                            endDate={endDate}
                            setEndDate={_setEndDateHandler}
                            factor={factor}
                            setFactor={setFactor}
                            formatDate={formatDate}
                            setIsRequestSuccessful={setIsRequestSuccessful}
                            AddSnackbar={AddSnackbar}
                            _getRandomRequestTitle={_getRandomRequestTitle}
                            API={API}
                        />} />
                        
                    <Route path="/krankmeldung" element={<SickRequest
                            sessionToken={sessionToken}
                            setRequestType={setRequestType}
                            startDate={startDate}
                            setStartDate={_setStartDateHandler}
                            endDate={endDate}
                            setEndDate={_setEndDateHandler}
                            factor={factor}
                            formatDate={formatDate}
                            setIsRequestSuccessful={setIsRequestSuccessful}
                            AddSnackbar={AddSnackbar}
                            API={API}
                        />}/>
                        
                    <Route path="/out-of-office" element={<OutOfOfficeRequest
                            sessionToken={sessionToken}
                            setRequestType={setRequestType}
                            startDate={startDate}
                            setStartDate={_setStartDateHandler}
                            endDate={endDate}
                            setEndDate={_setEndDateHandler}
                            factor={factor}
                            formatDate={formatDate}
                            setIsRequestSuccessful={setIsRequestSuccessful}
                            AddSnackbar={AddSnackbar}
                            API={API}
                        />} />
                        
                </Routes>
                <HelpLink />
            </div>
        )
    } else {
        if (requestType === LOCATION_TYPES.HOMEOFFICE.name) {
            return (
                <>
                    <HomeofficeRequest
                        sessionToken={sessionToken}
                        setRequestType={setRequestType}
                        startDate={startDate}
                        setStartDate={_setStartDateHandler}
                        endDate={endDate}
                        setEndDate={_setEndDateHandler}
                        factor={factor}
                        formatDate={formatDate}
                        setIsRequestSuccessful={setIsRequestSuccessful}
                        AddSnackbar={AddSnackbar}
                        API={API}
                    />
                    <HelpLink />
                </>
            )
        } else if (requestType === LOCATION_TYPES.VACATION.name || requestType === LOCATION_TYPES.VACATION_SPECIAL.name) {
            return (
                <>
                    <VacationRequest
                        sessionToken={sessionToken}
                        requestType={requestType}
                        setRequestType={setRequestType}
                        startDate={startDate}
                        setStartDate={_setStartDateHandler}
                        endDate={endDate}
                        setEndDate={_setEndDateHandler}
                        factor={factor}
                        setFactor={setFactor}
                        formatDate={formatDate}
                        setIsRequestSuccessful={setIsRequestSuccessful}
                        AddSnackbar={AddSnackbar}
                        API={API}
                    />
                    <HelpLink />
                </>
            )
        } else if (requestType === LOCATION_TYPES.SICK.name || requestType === LOCATION_TYPES.SICK_INOFFICIAL.name || requestType === LOCATION_TYPES.SICK_CHILD.name ) {
            return (
                <>
                    <SickRequest
                        sessionToken={sessionToken}
                        setRequestType={setRequestType}
                        startDate={startDate}
                        setStartDate={_setStartDateHandler}
                        endDate={endDate}
                        setEndDate={_setEndDateHandler}
                        factor={factor}
                        formatDate={formatDate}
                        setIsRequestSuccessful={setIsRequestSuccessful}
                        AddSnackbar={AddSnackbar}
                        API={API}
                    />
                    <HelpLink />
                </>
            )
        } else if( requestType === LOCATION_TYPES.OUTOFOFFICE.name ) {
            return (
                <>
                    <OutOfOfficeRequest
                        sessionToken={sessionToken}
                        setRequestType={setRequestType}
                        startDate={startDate}
                        setStartDate={_setStartDateHandler}
                        endDate={endDate}
                        setEndDate={_setEndDateHandler}
                        factor={factor}
                        formatDate={formatDate}
                        setIsRequestSuccessful={setIsRequestSuccessful}
                        AddSnackbar={AddSnackbar}
                        API={API}
                    />  
                    <HelpLink />
                </>
            )    
        }
    }
}