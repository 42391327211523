import React, { useState, useEffect } from "react";
import ReactMarkdown from 'react-markdown';
import Loading from "./Loading";


const input = '# This is a header\n\nAnd this is a paragraph';
const FILE_PATH  = "/release-notes/";

export default function ReleaseNotes({setShowReleaseNotes}) {
    const [isLoading, setIsLoading] = useState(true);
    const [fileData, setFileData] = useState({});
    const [selectedFile, setSelectedFile] = useState( null );
    const versions = ["version_Q2104.md", "version_Q2201.md"];
    var data = {};
    var currentVersion = "";

    useEffect(function() {
        if( isLoading ) {
            Promise.all(
                versions.map(id => {
                    currentVersion = id;
                  return new Promise((resolve) => {
                    fetch(`${process.env.PUBLIC_URL}${FILE_PATH}${id}`)
                      .then(r => {
                        return new Promise(() => {
                          r.text()
                            .then(text => {
                                data[id] = text;
                              resolve()
                            })
                        })
                      })
                  })
                })
              )
              .then(() => {
                  setFileData( data, setIsLoading(false) );
              });
        }

    });

    if( isLoading ) {
        return(
            <Loading />
        )
    } 

    return(
        <div className="we-release-notes">
            <h2>Was ist Neu?</h2>
            <ul className="we-release-notes__list">
                {Object.keys(fileData).map(file => {
                    return <li className="we-release-note">
                            <input type="checkbox" id={file} />
                            <label className="we-btn" for={file}>{file.replace(/.md/gm, "").replace(/_/gm, " ")}</label>
                            <div className="we-release-note__content">
                                <ReactMarkdown children={fileData[file]} />
                            </div>
                        </li>;
                })}
            </ul>
        </div>
    )
}