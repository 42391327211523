import React, { useState, useContext } from "react";
import { Link, Navigate } from "react-router-dom";

import { AppStateContext } from '../components/AppStateProvider';

import LOCATION_TYPES from "../classes/LOCATION_TYPES";
import REQUEST_STATUS from "../classes/REQUEST_STATUS";

import API from '../classes/API';

export default function RequestDetails({sessionToken, request, selectedRequest, setSelectedRequest, backURL, _deleteRequestHandler}) {
    const { AddSnackbar } = useContext(AppStateContext);
    var requestType = request ? LOCATION_TYPES[request.type].name !== undefined ? LOCATION_TYPES[request.type].label : "" : "";
    const [requestTitle, setRequestTitle] = useState( (request && request.title) ? request.title : "" );
    const [requestIsEmpty, setRequestIsEmpty] = useState( false );
    const [requestDates, setRequestDates] = useState( (request && request.dates) || [] );
    
    const formatDateShort = ( date ) => {
        date = date.split("-").reverse();
        date.pop();
        date = date.join('.');
        return date;
    }

    const formatDate = (date) => {
        date = date.split("-").reverse();
        date = date.join('.');
        return date;
    }

    if( requestIsEmpty || !request ) {
        return ( <Navigate push to={`${process.env.PUBLIC_URL}/profil`} /> )
    } else {
        return(
            <div className="we-request-details">
                {setSelectedRequest ? 
                    <button className="we-request-details__back-btn" onClick={() => setSelectedRequest( null)}>
                        <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                                <g transform="translate(-35.000000, -70.000000)" stroke="#FFFFFF" strokeWidth="2">
                                    <g>
                                        <g transform="translate(18.000000, 53.000000)">
                                            <g transform="translate(24.104000, 23.903000) rotate(180.000000) translate(-24.104000, -23.903000) translate(18.604000, 18.403000)">
                                                <line x1="0.403" y1="5.403" x2="10.403" y2="5.403" id="Linie_41" strokeLinejoin="round"></line>
                                                <polyline points="5.403 0 10.803 5.4 5.403 10.8"></polyline>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </button>
                : 
                    <Link to={backURL ? backURL : `${process.env.PUBLIC_URL}/profil`} className="we-request-details__back-btn">
                        <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                                <g transform="translate(-35.000000, -70.000000)" stroke="#FFFFFF" strokeWidth="2">
                                    <g>
                                        <g transform="translate(18.000000, 53.000000)">
                                            <g transform="translate(24.104000, 23.903000) rotate(180.000000) translate(-24.104000, -23.903000) translate(18.604000, 18.403000)">
                                                <line x1="0.403" y1="5.403" x2="10.403" y2="5.403" id="Linie_41" strokeLinejoin="round"></line>
                                                <polyline points="5.403 0 10.803 5.4 5.403 10.8"></polyline>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </Link>
                }
                <p className="we-request-details__type">
                    {requestType}
                    <small>
                        {request.dates.length > 1 ?
                            formatDate(request.dates[0]) + " - " + formatDate(request.dates[(request.dates.length - 1)])
                            :
                            formatDate(request.dates[0])
                        }
                    </small>
                </p>
                <form>
                    <label className={`we-request-details__title ${requestType.toLowerCase()}`}>
                        Titel
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
                        <input 
                            type="text"
                            onChange={(e) => setRequestTitle( e.target.value )}
                            value={requestTitle}
                            placeholder="z.B. Sommerurlaub"
                            disabled
                        />
                    </label>
                </form>
                <ul className="we-request-details__dates">
                    {requestDates.map((date, index) => 
                        <li key={`REQUEST_DATE_${index}`}>
                            <b>{formatDateShort( date )}</b> 
                            {new Date(date).toLocaleString(
                                "default", 
                                { weekday: "long" })}{(request.status === REQUEST_STATUS.REQUESTED || request.type === LOCATION_TYPES.HOMEOFFICE.name || request.type === LOCATION_TYPES.OUTOFOFFICE.name) ? <button onClick={() => AddSnackbar("Achtung! ☝️", "Möchtest du " + date + " wirklich stornieren?", "warning", () => _deleteRequestHandler( request, date ), "stornieren", "Abbrechen")}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button> : ""}
                        </li>)}
                </ul>
            </div>
        )
    }
}