import React, { useState } from "react";
import API from './../classes/API';
import Confetti from 'react-confetti';
import Snowfall from 'react-snowfall'

export const AppStateContext = React.createContext(null);

export default function AppStateProvider({ children }) {
    const [snackbars, setSnackbars] = useState([]);
    const [snackbarsVisible, setSnackbarsVisible] = useState( snackbars.length > 0 );
    const [openRequestsNumber, setOpenRequestsNumber] = useState( null );
    const [isUserBirtday, setIsUserBirthday] = useState( false );
    const [isChrsitmasTime, setIsChrsitmasTime] = useState( false );
    const [isNewYear, setIsNewYear] = useState( false );
    var windowWidth = window.innerWidth;
    var windowHeight = window.innerHeight;
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var confetti = "";

    /** @function addSnackbar */
    // the above is the same as:
    /** @function
     * @name addSnackbar 
     * @param {string} title - The title of the book.
     * @param {string} message - The author of the book.
     * */
    const addSnackbar = (title = "", message = "", type = "", link = null, linkTitle = "", declineText = "") => {
        var newSnackbars = [...snackbars];
        newSnackbars.unshift( { id: (snackbars.length + 1), title: title, message: message, type: type, link: link, linkTitle: linkTitle, declineText: declineText } )
        setSnackbars(newSnackbars, setSnackbarsVisible( newSnackbars.length > 0 ) );
    }

    const closeSnackbar = (snackbarID) => {
        var newSnackbars = snackbars.filter(snackbar => snackbar.id !== snackbarID);
        setSnackbars(newSnackbars, setSnackbarsVisible( newSnackbars.length > 0 ));
    }

    const updateOpenRequestNumber = () => {
        var storageData = API.getStorageUser();
        if( storageData !== undefined && storageData !== null ) {
            /*API.getOpenRequestsNumber(storageData.token).then( number => {
                setOpenRequestsNumber( number );
            });*/
        }
    }

    const isUserBirthdayHandler = () => {
        var storageData = API.getStorageUser();
        if( storageData !== undefined && storageData !== null ) {
            API.getProfile(storageData.token).then( response => {
                if( response.success ) {
                    var birthday = response.data.user.birthdate;
                    var today = new Date();
                    today.setHours(0,0,0,0);
                    if( birthday ) {
                        birthday = birthday.split("-");
                        birthday[0] = new Date().getFullYear();
                        birthday = birthday.join("-");
                        
                        var birthdayDate = new Date( birthday );
                        birthdayDate.setHours(0,0,0,0);
                        setIsUserBirthday( today.getTime() === birthdayDate.getTime() );
                    }
                }
            });
        }
    }

    const state = {
        AddSnackbar: addSnackbar,
        snackbarsVisible: snackbarsVisible,
        openRequestsNumber: openRequestsNumber,
        UpdateOpenRequestNumber: updateOpenRequestNumber,
        Snackbars: snackbars,
        CloseSnackbar: closeSnackbar
    };

    React.useEffect(function() {
        if( openRequestsNumber === null ) {
            updateOpenRequestNumber();
        }
        isUserBirthdayHandler();
    }, [openRequestsNumber]);

    

    if( day >= 13 && day <= 30 && month === 12 && !isChrsitmasTime ) {
        setIsChrsitmasTime( true );
    }

    if( (day === 31 && month === 12 && !isNewYear) || (day === 1 && month === 1 && !isNewYear) ) {
        setIsNewYear( true );
    }

    if( isUserBirtday ) {
        confetti = <Confetti className="user-birthday-canvas" width={windowWidth} height={windowHeight} />;
    } else if ( isNewYear ) {
        confetti = <div class="we-fireworks">
                        <div class="before"></div>
                        <div class="after"></div>
                    </div>;
    } else if ( isChrsitmasTime ) {
        var snowFlakeAmount = 100;
        if( window.matchMedia("(min-width: 40.063em)").matches ) {
            snowFlakeAmount = 200;
        }
        confetti = <Snowfall 
                        className="user-birthday-canvas" 
                        style={{zIndex: -1, position: "fixed"}}
                        snowflakeCount={snowFlakeAmount}
                        speed={[0,0.5]}
                        wind={[0,0.5]}
                    />;
        //confetti = <Confetti className="user-birthday-canvas" width={windowWidth} height={windowHeight} />;
    }

    return (
        <AppStateContext.Provider value={state}>
            {children}
            {confetti}
        </AppStateContext.Provider>
    );
}
