import React, { useState, useEffect } from "react";
import { Link, Routes, Route } from "react-router-dom";
import ProfileEditPhoneNumber from "../components/ProfileEditPhoneNumber";
import ReleaseNotes from "../components/ReleaseNotes";

export default function ProfileSettings({sessionToken, API, logoutHandler}) {

    return(
        <Routes>
            <Route path="/" element={
                <div className="we-profile-settings">
                    <div className="we-profile-settings__wrapper">
                        <div className="we-profile-settings__headline">
                            <Link to={`${process.env.PUBLIC_URL}/profil`} className="we-profile-settings__back-btn">
                                <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                                        <g transform="translate(-35.000000, -70.000000)" stroke="#FFFFFF" strokeWidth="2">
                                            <g>
                                                <g transform="translate(18.000000, 53.000000)">
                                                    <g transform="translate(24.104000, 23.903000) rotate(180.000000) translate(-24.104000, -23.903000) translate(18.604000, 18.403000)">
                                                        <line x1="0.403" y1="5.403" x2="10.403" y2="5.403" id="Linie_41" strokeLinejoin="round"></line>
                                                        <polyline points="5.403 0 10.803 5.4 5.403 10.8"></polyline>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </Link>
                            <p>Einstellungen</p>
                        </div>
                        <ul className="we-profile-settings__items">
                            <li className="we-profile-setting-item">
                                <Link to="phone" >
                                    Telefonnummer 
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12.817" height="13.634" viewBox="0 0 12.817 13.634">
                                            <g id="arrow-right" transform="translate(1 1.414)">
                                                <line id="Linie_41" data-name="Linie 41" x2="10" transform="translate(0 5.403)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                <path id="Pfad_35" data-name="Pfad 35" d="M0,10.806,5.4,5.4,0,0" transform="translate(5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                            </g>
                                        </svg>
                                    </span>
                                </Link>
                            </li>
                            <li className="we-profile-setting-item">
                                <Link to="release-notes" >
                                    Release Notes
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12.817" height="13.634" viewBox="0 0 12.817 13.634">
                                            <g id="arrow-right" transform="translate(1 1.414)">
                                                <line id="Linie_41" data-name="Linie 41" x2="10" transform="translate(0 5.403)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                <path id="Pfad_35" data-name="Pfad 35" d="M0,10.806,5.4,5.4,0,0" transform="translate(5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                            </g>
                                        </svg>
                                    </span>
                                </Link>
                            </li>
                        </ul>
                        <button className="we-profile-settings__logout-btn we-btn small" onClick={logoutHandler}>Ausloggen</button>
                    </div>
                </div>
            } />
            <Route path="/phone" element={
                <div className="we-profile-settings">
                    <div className="we-profile-settings__wrapper">
                        <div className="we-profile-settings__headline">
                            <Link to={`${process.env.PUBLIC_URL}/profil/settings`} className="we-profile-settings__back-btn">
                                <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                                        <g transform="translate(-35.000000, -70.000000)" stroke="#FFFFFF" strokeWidth="2">
                                            <g>
                                                <g transform="translate(18.000000, 53.000000)">
                                                    <g transform="translate(24.104000, 23.903000) rotate(180.000000) translate(-24.104000, -23.903000) translate(18.604000, 18.403000)">
                                                        <line x1="0.403" y1="5.403" x2="10.403" y2="5.403" id="Linie_41" strokeLinejoin="round"></line>
                                                        <polyline points="5.403 0 10.803 5.4 5.403 10.8"></polyline>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </Link>
                            <p>Telefonnummern<br/>bearbeiten</p>
                        </div>
                    </div>
                    <ProfileEditPhoneNumber sessionToken={sessionToken} API={API} />
                </div>
            } />
            <Route path="/mail" element={
                <div className="we-profile-settings">
                    <div className="we-profile-settings__wrapper">
                        <div className="we-profile-settings__headline">
                            <Link to={`${process.env.PUBLIC_URL}/profil/settings`} className="we-profile-settings__back-btn">
                                <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                                        <g transform="translate(-35.000000, -70.000000)" stroke="#FFFFFF" strokeWidth="2">
                                            <g>
                                                <g transform="translate(18.000000, 53.000000)">
                                                    <g transform="translate(24.104000, 23.903000) rotate(180.000000) translate(-24.104000, -23.903000) translate(18.604000, 18.403000)">
                                                        <line x1="0.403" y1="5.403" x2="10.403" y2="5.403" id="Linie_41" strokeLinejoin="round"></line>
                                                        <polyline points="5.403 0 10.803 5.4 5.403 10.8"></polyline>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </Link>
                            <p>E-Mail bearbeiten</p>
                        </div>
                    </div>
                </div>
            } />
            <Route path="/release-notes" element={
                <div className="we-profile-settings">
                    <div className="we-profile-settings__wrapper">
                        <div className="we-profile-settings__headline">
                            <Link to={`${process.env.PUBLIC_URL}/profil/settings`} className="we-profile-settings__back-btn">
                                <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                                        <g transform="translate(-35.000000, -70.000000)" stroke="#FFFFFF" strokeWidth="2">
                                            <g>
                                                <g transform="translate(18.000000, 53.000000)">
                                                    <g transform="translate(24.104000, 23.903000) rotate(180.000000) translate(-24.104000, -23.903000) translate(18.604000, 18.403000)">
                                                        <line x1="0.403" y1="5.403" x2="10.403" y2="5.403" id="Linie_41" strokeLinejoin="round"></line>
                                                        <polyline points="5.403 0 10.803 5.4 5.403 10.8"></polyline>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </Link>
                            <p>
                                Release notes
                                <small>In den Release-Notes erfährst du was sich durch die Updates geändert hat.</small>
                            </p>
                            <p></p>
                        </div>
                        <ReleaseNotes />
                    </div>
                </div>
            } />
        </Routes>
    )
}