import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function EmployeeFilter({API, sessionToken, teamIDFilter, setTeamIDFilter, employeeNameFilter, setEmployeeNameFilter}) {
    const [_teamIDFilter, _setTeamIDFilter] = useState( teamIDFilter || 1 );
    const [_lastTeamIDFIlter, _setLastTeamIDFIlter] = useState( teamIDFilter || 1 );
    const [_userNameFilter, _setUserNameFilter] = useState( employeeNameFilter || "" );
    const [teams, setTeams] = useState( [] );

    const _setTeamIDFilterHadnler = ( teamID ) => {
        _setTeamIDFilter(
            teamID,
            setTeamIDFilter( teamID )
        );
    }

    const _setUserNameFilterHandler = ( username ) => {
        if( username.replace(/\s/g, "") === "" ) {
            _setUserNameFilter(
                username,
                setEmployeeNameFilter( 
                    username,
                    _setTeamIDFilter(
                        _lastTeamIDFIlter,
                        setTeamIDFilter( _lastTeamIDFIlter )
                    )
                )
            );
        } else {
            if( teamIDFilter === 0 ) {
                _setUserNameFilter(
                    username,
                    setEmployeeNameFilter( 
                        username,
                        _setTeamIDFilter(
                            0,
                            setTeamIDFilter( 0 )
                        )
                    )
                );
            } else {
                _setUserNameFilter(
                    username,
                    setEmployeeNameFilter( 
                        username,
                        _setLastTeamIDFIlter(
                            teamIDFilter,
                            _setTeamIDFilter(
                                0,
                                setTeamIDFilter( 0 )
                            )
                        )
                    )
                );
            }
        }
        
    }

    useEffect(() => {
        API.getTeams( sessionToken ).then(request => {
            if (request !== null) {
                setTeams( request.teams );
            }
        });

        if( !teamIDFilter ) {
            setTeamIDFilter( _teamIDFilter );
        }

    }, []);

    return(
        <div className="we-employee-filter">
            <div className="we-select__wrapper">
                <select 
                    className="we-select" 
                    onChange={(e) => _setTeamIDFilterHadnler(e.target.value)}
                    value={_teamIDFilter}
                >
                    <option key={`FILTER_OPTION_0`} value="0" >Alle anzeigen</option>
                    {teams.map( (team, index) => <option key={`FILTER_OPTION_${index}`} value={team.id} >{team.name}</option>)}
                </select>
                <span className="we-select__arrow">
                    <svg width="12px" height="8px" viewBox="0 0 12 8" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                            <g transform="translate(-212.000000, -257.000000)" stroke="#FFFFFF" strokeWidth="2">
                                <g>
                                    <g transform="translate(18.000000, 240.000000)">
                                        <g transform="translate(200.000000, 20.000000) rotate(90.000000) translate(-200.000000, -20.000000) translate(189.000000, 9.000000)">
                                            <polyline transform="translate(12.000000, 11.000000) rotate(-90.000000) translate(-12.000000, -11.000000) " points="7.712 8.856 12 13.144 16.288 8.856"></polyline>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </span>
            </div>
            <div className="we-input__wrapper">
                <div className="we-change-dashboard">
                    {window.location.pathname.includes("planungs-uebersicht") ? 
                        <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-columns"><path d="M12 3h7a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-7m0-18H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h7m0-18v18"></path></svg>
                        </Link>
                    : 
                        <Link to={`${process.env.PUBLIC_URL}/dashboard/planungs-uebersicht`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-list"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg>
                        </Link>
                    }
                </div>
                <div>
                    <input className="we-input" onChange={(e) => _setUserNameFilterHandler( e.target.value )} value={_userNameFilter}/>
                    <svg className="we-employee-filter__search-icon" width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                            <g transform="translate(-265.000000, -251.000000)" stroke="#868686" strokeWidth="2">
                                <g>
                                    <g transform="translate(251.000000, 240.000000)">
                                        <g transform="translate(15.000000, 12.000000)">
                                            <circle cx="7" cy="7" r="7"></circle>
                                            <line x1="16" y1="16" x2="12.133" y2="12.133" id="Linie_40"></line>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    {_userNameFilter !== "" ? <button className="we-input__clear" onClick={() => _setUserNameFilterHandler("")}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button> : "" }
                </div>
            </div>
        </div>
    )
}