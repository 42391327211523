import React, { useEffect, useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { AppStateContext } from './../components/AppStateProvider';
import USER_ROLES from './../classes/USER_ROLES';

export default function Navigation({sessionToken, reportList}) {
    const { openRequestsNumber, UpdateOpenRequestNumber } = useContext(AppStateContext);
    const [isSmall, setIsSmall] = useState(false);

    useEffect(function() {
        UpdateOpenRequestNumber();
    })

    var navigationList = [
        <li key="1" >
            <NavLink to={`${process.env.PUBLIC_URL}/dashboard`} activeClassName='is-active'>
                <svg width="30px" height="23px" viewBox="0 0 30 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                        <g transform="translate(-63.000000, -813.000000)" stroke="#868686" strokeWidth="2">
                            <g id="Gruppe_153">
                                <g transform="translate(51.000000, 813.000000)">
                                    <g id="Gruppe_90" transform="translate(12.500000, 0.437000)">
                                        <line x1="9.5" y1="21.563" x2="28.5" y2="21.563" id="Linie_22"></line>
                                        <line x1="9.5" y1="14.563" x2="28.5" y2="14.563" id="Linie_25"></line>
                                        <line x1="9.5" y1="7.563" x2="28.5" y2="7.563" id="Linie_27"></line>
                                        <line x1="9.5" y1="0.563" x2="28.5" y2="0.563" id="Linie_29"></line>
                                        <line x1="0.5" y1="21.563" x2="3.5" y2="21.563" id="Linie_23"></line>
                                        <line x1="0.5" y1="14.563" x2="3.5" y2="14.563" id="Linie_24"></line>
                                        <line x1="0.5" y1="7.563" x2="3.5" y2="7.563" id="Linie_26"></line>
                                        <line x1="0.5" y1="0.563" x2="3.5" y2="0.563" id="Linie_28"></line>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                <span>&Uuml;bersicht</span>
            </NavLink>
        </li>,
        <li key="2">
            <NavLink to={`${process.env.PUBLIC_URL}/antrag-hinzufuegen`} activeClassName='is-active'>
                <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.999" strokeLinecap="round">
                        <g transform="translate(-192.000000, -809.000000)" stroke="#868686" strokeWidth="2">
                            <g id="Gruppe_152">
                                <g transform="translate(174.000000, 810.000000)">
                                    <g id="Hinzufügen_Icon" transform="translate(19.052000, 0.000000)">
                                        <g id="Gruppe_6" transform="translate(6.467000, 6.467000)">
                                            <line x1="7.481" y1="0" x2="7.481" y2="14.963" id="Linie_2"></line>
                                            <line x1="14.963" y1="7.481" x2="0" y2="7.481" id="Linie_3"></line>
                                        </g>
                                        <circle id="Ellipse_2" cx="13.949" cy="13.949" r="13.949"></circle>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                <span>Hinzuf&uuml;gen</span>
            </NavLink>
        </li>,
        <li key="3">
            <NavLink exact={true} to={`${process.env.PUBLIC_URL}/profil`} activeClassName='is-active'>
                <svg width="30px" height="32px" viewBox="0 0 30 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                        <g id="WE_Office_iPhoneX_03_Profil" transform="translate(-330.000000, -809.000000)" stroke="#868686" strokeWidth="2">
                            <g transform="translate(-41.000000, 0.000000)" id="Gruppe_151">
                                <g transform="translate(371.000000, 810.000000)">
                                    <g id="Profil_Icon" transform="translate(0.347000, 0.000000)">
                                        <circle id="Ellipse_1" cx="14.653" cy="7.409" r="7.409"></circle>
                                        <path d="M20.001,16.771 C28.301,19.623 28.301,29.171 28.301,29.171 L1.001,29.171 C1.001,29.171 0.451,19.2 9.265,16.59" id="Pfad_1"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                <span>Profil</span>
            </NavLink>
        </li>
    ];

    if( sessionToken.role === USER_ROLES.CEO || sessionToken.role === USER_ROLES.TEAMLEADER ) {
        navigationList.push(
            <li key="4">
                {openRequestsNumber > 0 ? <span className="we-notification-bubble">{openRequestsNumber}</span> : ""}
                <NavLink exact={true} to={`${process.env.PUBLIC_URL}/antraege-verwalten`} activeClassName='is-active'>
                    <svg width="24px" height="31px" viewBox="0 0 24 31" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                            <g transform="translate(-351.000000, -810.000000)" stroke="#868686" strokeWidth="2">
                                <g transform="translate(0.000000, 789.000000)">
                                    <g transform="translate(340.000000, 21.000000)">
                                        <g transform="translate(12.352000, 0.689000)">
                                            <path d="M15.973,2.662 L18.635,2.662 C20.1604018,2.71735243 21.3528587,3.99750977 21.3,5.523 L21.3,25.548 C21.3528805,27.0723615 20.1622144,28.3520368 18.638,28.409 L2.662,28.409 C1.13778556,28.3520368 -0.0528805182,27.0723615 8.8817842e-16,25.548 L8.8817842e-16,5.523 C-0.0528805182,3.99863846 1.13778556,2.71896316 2.662,2.662 L5.324,2.662" id="Pfad_155" strokeLinejoin="round"></path>
                                            <rect strokeLinejoin="round" x="5.648" y="0.311" width="10" height="5" rx="1"></rect>
                                            <g transform="translate(3.803000, 9.748000)">
                                                <line x1="0.846" y1="12.563" x2="12.846" y2="12.563" id="Linie_22-2"></line>
                                                <line x1="0.846" y1="8.563" x2="12.846" y2="8.563" id="Linie_25-2"></line>
                                                <line x1="0.846" y1="4.563" x2="12.846" y2="4.563" id="Linie_27-2"></line>
                                                <line x1="0.846" y1="0.563" x2="12.846" y2="0.563" id="Linie_29-2"></line>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span>Antr&auml;ge</span>
                </NavLink>
            </li>
        );
    }

    if (reportList && reportList.length > 0 ) {
        navigationList.push(
            <li key="5">
                <NavLink to={`${process.env.PUBLIC_URL}/reporting`} activeClassName='is-active'>
                    <svg width="25px" height="24px" viewBox="0 0 25 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                            <g id="WE_Office_Desktop_05_Report_1" transform="translate(-71.000000, -537.000000)" stroke="#868686" strokeWidth="2">
                                <g id="WE_Office_Desktop_04_Anträge-1_1">
                                    <g id="Gruppe_381" transform="translate(46.320000, 260.599000)">
                                        <g id="Gruppe_444" transform="translate(25.608000, 277.313000)">
                                            <line x1="4.918" y1="6.844" x2="4.918" y2="21.416" id="Linie_70"></line>
                                            <line x1="8.131" y1="13.484" x2="8.131" y2="21.416" id="Linie_71"></line>
                                            <line x1="17.767" y1="13.484" x2="17.767" y2="21.416" id="Linie_72"></line>
                                            <line x1="11.338" y1="9.307" x2="11.338" y2="21.408" id="Linie_73"></line>
                                            <line x1="14.551" y1="6.83" x2="14.551" y2="21.402" id="Linie_74"></line>
                                            <polyline id="Pfad_185" points="0.46 0.09 0.46 21.415 22.15 21.415"></polyline>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span>Report</span>
                </NavLink>
            </li>
        );
    }

    if( sessionToken.role === USER_ROLES.HR ) {
        navigationList.push(
            <li key="6">
                <NavLink to={`${process.env.PUBLIC_URL}/mitarbeiter-hinzufuegen`} activeClassName='is-active'>
                <svg width="38px" height="34px" viewBox="0 0 38 34" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                        <g id="WE_Office_iPhoneX_06_Mitarbeiter_anlegen_1" transform="translate(-344.000000, -808.000000)" stroke="#868686" strokeWidth="2">
                            <g id="Gruppe_298" transform="translate(0.000000, 789.000000)">
                                <g id="Gruppe_294" transform="translate(330.000000, 19.000000)">
                                    <g id="Gruppe_448" transform="translate(14.573000, 0.529000)">
                                        <g id="Profil_Icon" transform="translate(5.344000, 12.494000)">
                                            <circle id="Ellipse_1" cx="5.962" cy="3.457" r="3.457"></circle>
                                            <path d="M8.055,6.525 C11.68,7.956 11.923,13.279 11.923,13.279 L0,13.279 C0,13.279 0.01,7.835 3.859,6.525" id="Pfad_1"></path>
                                        </g>
                                        <rect id="Rechteck_313" strokeLinejoin="round" x="0.711" y="6.557" width="35.432" height="25.152" rx="4"></rect>
                                        <g id="Gruppe_447" transform="translate(21.501000, 12.980000)">
                                            <line x1="11.445" y1="1" x2="0" y2="1" id="Linie_79"></line>
                                            <line x1="11.445" y1="6.153" x2="0" y2="6.153" id="Linie_80"></line>
                                            <line x1="11.445" y1="11.306" x2="0" y2="11.306" id="Linie_81"></line>
                                        </g>
                                        <path d="M18.353,0.93 L18.501,0.93 C20.1578542,0.93 21.501,2.27314575 21.501,3.93 L21.501,6.557 L15.353,6.557 L15.353,3.93 C15.353,2.27314575 16.6961458,0.93 18.353,0.93 Z" id="Rechteck_314"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                    <span>Mitarbeiter</span>
                </NavLink>
            </li>
        );
    }

    navigationList.push(
        <li key="7" className="hideMobile">
            <NavLink to={`https://weoffice.ws-epic.de/checkin/`} activeClassName='is-active'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#868686" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <circle cx="12" cy="12" r="10"/>
                    <line x1="12" y1="6" x2="12" y2="12"/>
                    <line x1="12" y1="12" x2="16" y2="16"/>
                </svg>
                <span>Checkin</span>
            </NavLink>
        </li>
    );

    return (
        <nav className={`we-navigation ${isSmall ? " is--small" : ""}`}>
            <ul>
                {navigationList}
            </ul>
            <button 
                className="we-navigation__shrink-btn"
                onClick={() => setIsSmall(!isSmall)}
            >
                {isSmall ? 
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevrons-right"><polyline points="13 17 18 12 13 7"></polyline><polyline points="6 17 11 12 6 7"></polyline></svg>
                : 
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevrons-left"><polyline points="11 17 6 12 11 7"></polyline><polyline points="18 17 13 12 18 7"></polyline></svg>
                }
            </button>
        </nav>
    )
}