import React from "react";
import { NavLink } from "react-router-dom";

import USER_ROLES from "../classes/USER_ROLES";

import Confetti from 'react-confetti';

export default function EmployeeDetails({employee, calendar, selectedDay, isSmall, isBirthday, userInformations}) {
    var lastType = "";
    var isSmall = isSmall === "true" ? true : false;
    var isTeamLeader = false;
    var sessionToken = window.getSessionToken();
    var userInitials = "";  
    
    // getting user's initials
    if(employee.firstname){
        userInitials = employee.firstname.charAt(0);
    }
    if(employee.lastname){
        userInitials = userInitials+employee.lastname.charAt(0);
    }

    const next = function(db, key) {
        var keys = Object.keys(db)
          , i = keys.indexOf(key);
        return i !== -1 && keys[i + 1] && db[keys[i + 1]];
    };

    if( userInformations ) {
        if( userInformations[employee.userId] ) {
            isTeamLeader = userInformations[employee.userId].isTeamleader ? true : false;
        }
    }

    return(
        <div className={`we-employee-details${employee.status && employee.status === "requested" ? " is--muted" : ""}${isTeamLeader ? " is--teamleader" : ""}`}>
            <div className="wrapper">
                <div className="we-employee-details__information">
                    <div>
                        {isBirthday ? 
                            <Confetti 
                                className="is--birthday" 
                                width={"75px"} 
                                height={"75px"} 
                                drawShape={ctx => {
                                    ctx.beginPath()
                                    ctx.beginPath();
                                    ctx.rect(3, 3, 3, 3);
                                    ctx.stroke();
                                  }}
                            /> 
                        : ""}
                        {(employee.img && employee.img !== "" ) ? 
                            <img className="we-employee-details__img" src={`data:image/png;base64,${employee.img}`} alt="" title="" />
                            : <span className="we-employee-details__img">{userInitials}</span>
                        }
                    </div>
                    <div>
                        <p className="we-employee-details__name">{employee.firstname ? employee.firstname : ""} {employee.lastname ? employee.lastname : "" }{employee.nickname ? <small></small> : ""}</p>
                        {!isSmall ?
                            <ul className="we-employee-details__actions">
                                {(employee.email && employee.email !== "") ? <li><a href={`mailto:${employee.email}`} title=""><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg></a></li> : <li><button disabled><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg></button></li>}
                                {(employee.phone && employee.phone !== "") ? <li><a href={`tel:${employee.phone}`} title=""><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg></a></li> : <li><button disabled><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-phone-off"><path d="M10.68 13.31a16 16 0 0 0 3.41 2.6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7 2 2 0 0 1 1.72 2v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.42 19.42 0 0 1-3.33-2.67m-2.67-3.34a19.79 19.79 0 0 1-3.07-8.63A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91"></path><line x1="23" y1="1" x2="1" y2="23"></line></svg></button></li>}
                                {sessionToken.role === USER_ROLES.CEO || sessionToken.role === USER_ROLES.HR ? <li><NavLink className="we-employee-details__link" to={{pathname: `${process.env.PUBLIC_URL}/mitarbeiter/${employee.userId}`, state: {lastLocation: window.location.pathname}}}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg></NavLink></li> : ""}
                            </ul>
                        : ""}

                    </div>
                    
                </div>
                <div>
                    {calendar ? 
                    <div className="we-employee-details__calendar">
                        <div>M</div>
                        <div>D</div>
                        <div>M</div>
                        <div>D</div>
                        <div>F</div>
                        { calendar ? Object.values(calendar).map( week => { 
                            var dayCounter = 0;
                            return Object.keys( week ).map( (date, index) => {
                                var dayClasses = "";
                                dayCounter++;
                                if( dayCounter > 5 ) return null;
                                if( week[date].length <= 0 ) {
                                    if( date === selectedDay ) {
                                        dayClasses += " is-today";
                                    }
                                    return <div key={`${index}_DAY_${date}`} className="day">{date.split("-").reverse()[0]}</div>;
                                }
                                return week[date].map( (day, index) => {
                                    dayClasses = `day ${day.type.toLowerCase()}`;
                                    if( date === selectedDay ) {
                                        dayClasses += " is-today";
                                    }
                                    if( day.factor === "0.50" || day.factor === 0.5) {
                                        dayClasses += " is-halfday";
                                    }
                                    if( dayCounter === 1 || lastType !== day.type.toLowerCase() ) {
                                        lastType = day.type.toLowerCase();
                                        dayClasses += " is-first";
                                    }
                                    if( dayCounter === 5 || next(week, date ) === undefined || next(week, date ).length <= 0 || next(week, date )[0].type.toLowerCase() !== day.type.toLowerCase() ) {
                                        dayClasses += " is-last";
                                    }

                                    if( day.status === "requested" ) {
                                        dayClasses += " is--muted";
                                    }
                                    return <div key={`${index}_DAY_${date}`} className={ dayClasses }><span>{date.split("-").reverse()[0]}</span></div>;
                                });
                            });
                        }) : ""}
                    </div>
                    : ""}
                </div>
            </div>
        </div>
    )
}