import React, {useEffect, useState} from "react";
import { NavLink } from "react-router-dom";

import LOCATION_TYPES from '../classes/LOCATION_TYPES';

export default function HomeofficeRequest({ sessionToken, setRequestType, startDate, setStartDate, endDate, setEndDate, factor, formatDate, setIsRequestSuccessful, AddSnackbar, _getRandomRequestTitle, API }) {
    const [limitWeekdays, setLimitWeekdays] = useState(false);
    const [weekdays, setWeekdays] = useState([]);

    const _handleSubmit = (e) => {
        e.preventDefault();
        API.sendHolidayRequest(sessionToken, formatDate(startDate,'yyyy-mm-dd'), formatDate(endDate,'yyyy-mm-dd'), factor, LOCATION_TYPES.HOMEOFFICE.name, "", "", weekdays.map(weekday => weekday) ).then(response => {
            if (response.success) {
                AddSnackbar(_getRandomRequestTitle(), "Dein Antrag auf Homeoffice wird bearbeitet.", LOCATION_TYPES.HOMEOFFICE.name, "profil", "Im Profil anzeigen");
                setIsRequestSuccessful(true);
            } else {
                AddSnackbar("Hoppla! ⚠️", "Da ist wohl etwas schief gelaufen.", "warning", `/profile`, "Im Profil anzeigen");
            }
        });
    }

    const _handlerSelectWeekdays = ( e ) => {
        if( e.target.checked ) {
            setWeekdays([...weekdays, e.target.value]);
        } else {
            setWeekdays([...weekdays.filter(weekDay => weekDay !== e.target.value )]);
        }
    }

    const _maxDateHandler = () => {
        var d = new Date( startDate );
        d.setDate( d.getDate() + 31 )
        return formatDate( d ).split(".").reverse().join("-") ;
    }

    useEffect(function() {
        if( !limitWeekdays && weekdays.length > 0  ) {
            setWeekdays([]);
        }
    });

    return (
        <div className="we-request homeoffice">
            <NavLink to={`${process.env.PUBLIC_URL}/antrag-hinzufuegen`} className="we-request__back-btn" onClick={() => setRequestType(null)}>
                <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                        <g transform="translate(-35.000000, -70.000000)" stroke="#FFFFFF" strokeWidth="2">
                            <g>
                                <g transform="translate(18.000000, 53.000000)">
                                    <g transform="translate(24.104000, 23.903000) rotate(180.000000) translate(-24.104000, -23.903000) translate(18.604000, 18.403000)">
                                        <line x1="0.403" y1="5.403" x2="10.403" y2="5.403" id="Linie_41" strokeLinejoin="round"></line>
                                        <polyline points="5.403 0 10.803 5.4 5.403 10.8"></polyline>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </NavLink>
            <h2>Hinzuf&uuml;gen</h2>
            <h3><b>Homeoffice</b> beantragen</h3>
            <form onSubmit={_handleSubmit}>
                <label>
                    Startdatum
                    <input
                        type="date"
                        className="we-input"
                        name="startDate"
                        value={formatDate(startDate, "yyyy-mm-dd")}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                </label>
                <label>
                    Enddatum
                    <input
                        type="date"
                        className="we-input"
                        name="endDate"
                        value={formatDate(endDate, "yyyy-mm-dd")}
                        max={_maxDateHandler()}
                        onChange={(e) => setEndDate(e.target.value)}
                        min={startDate}
                    />
                </label>
                <label className="we-checkbox">
                    Wochentage einschränken
                    <input
                        type="checkbox"
                        checked={limitWeekdays}
                        onChange={e => setLimitWeekdays(!limitWeekdays)}
                    />
                    <span className="we-checkbox__input">&nbsp;</span>
                </label>
                {limitWeekdays ? 
                <>
                    <p className="we-request__note">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
                        Der Antrag umfasst für den angegebenen Zeitraum nur die ausgewählten Wochentage.
                    </p>
                    <div className="we-add-employee__days">
                        <label className="we-checkbox">
                            Mo.
                            <input
                                type="checkbox"
                                value="1"
                                checked={weekdays.filter(day => day === "1").length > 0}
                                onChange={_handlerSelectWeekdays}
                            />
                            <span className="we-checkbox__input">&nbsp;</span>
                        </label>
                        <label className="we-checkbox">
                            Di.
                            <input
                                type="checkbox"
                                value="2"
                                checked={weekdays.filter(day => day === "2").length > 0}
                                onChange={_handlerSelectWeekdays}
                            />
                            <span className="we-checkbox__input">&nbsp;</span>
                        </label>
                        <label className="we-checkbox">
                            Mi.
                            <input
                                type="checkbox"
                                value="3"
                                checked={weekdays.filter(day => day === "3").length > 0}
                                onChange={_handlerSelectWeekdays}
                            />
                            <span className="we-checkbox__input">&nbsp;</span>
                        </label>
                        <label className="we-checkbox">
                            Do.
                            <input
                                type="checkbox"
                                value="4"
                                checked={weekdays.filter(day => day === "4").length > 0}
                                onChange={_handlerSelectWeekdays}
                            />
                            <span className="we-checkbox__input">&nbsp;</span>
                        </label>
                        <label className="we-checkbox">
                            Fr.
                            <input
                                type="checkbox"
                                value="5"
                                checked={weekdays.filter(day => day === "5").length > 0}
                                onChange={_handlerSelectWeekdays}
                            />
                            <span className="we-checkbox__input">&nbsp;</span>
                        </label>
                    </div>
                </>
                :""}
                <button className="we-btn" type="submit">Beantragen</button>
            </form>
        </div>
    )
}
