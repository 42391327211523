import React, { useEffect, useState, useContext } from "react";
import { Routes, Route, useMatch, useParams } from "react-router-dom";

import { AppStateContext } from './../components/AppStateProvider';

import Loading from './../components/Loading';
import RequestCard from '../components/RequestCard';
import RequestDetails from '../pages/RequestDetails';

import LOCATION_TYPES from './../classes/LOCATION_TYPES';

import API from './../classes/API';

function EmployeeCard({employee, setSelectedEmployee, _acceptHolidayRequestHandler, _deleteRequestHandler, _acceptAllRequestsHandler}) {
    const [localUser] = useState( API.getStorageUser() );
    var hasOpenRequests = true;
    var openRequests = [];
    
    Object.keys(employee.requests).map(key => { if( !employee.requests[key].approvals || localUser.userID && !employee.requests[key].approvals[localUser.userID] ){openRequests.push( employee.requests[key].requestId)}} );
    
    if( localUser !== null ) {
        if( typeof employee.requests === 'object' && employee.requests !== null ) {
            if( Object.keys( employee.requests ).length === Object.keys( employee.requests ).filter( key => (employee.requests && employee.requests[key].approvals && employee.requests[key].approvals[localUser.userID] ) ).length ) {
                hasOpenRequests = false;
            }
        }
    }
    return (
        <li className={`we-employee-details ${!hasOpenRequests ? "is--muted" : "is--notificated"}`}>
            <div className="wrapper">
                <button className="we-employee-details__btn" onClick={() => setSelectedEmployee( employee ) }>&nbsp;</button>
                <div className="we-employee-details__information">
                    <div>
                        {employee.img ? 
                            <img className="we-employee-details__img" src={`data:image/png;base64,${employee.img}`} alt="" title="" />
                            : <span className="we-employee-details__img">{employee.firstname.charAt(0)+employee.firstname.charAt(employee.firstname.indexOf(" ") + 1)}</span>
                        }
                    </div>
                    <div>
                        <p className="we-employee-details__name">{employee.firstname}{employee.nickname ? <small></small> : ""}<b>{Object.keys(employee.requests).filter( key => (employee.requests && employee.requests[key].approvals && !employee.requests[key].approvals[localUser.uid] ) ).length}</b></p>
                        <ul className="we-employee-details__actions">
                            {employee.email !== "" ? <li><a href={`mailto:${employee.email}`} title=""><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg></a></li> : ""}
                            {employee.phone !== "" ? <li><a href={`tel:${employee.phone}`} title=""><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg></a></li> : ""}
                        </ul>
                        
                    </div>
                    <span className="we-employee-details__arrow-btn">
                        <svg width="8px" height="12px" viewBox="0 0 8 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                                <g transform="translate(-361.000000, -361.000000)" stroke="#FFFFFF" strokeWidth="2">
                                    <g id="Gruppe_85">
                                        <g transform="translate(18.000000, 289.000000)">
                                            <g id="Gruppe_70" transform="translate(335.000000, 67.000000)">
                                                <polyline id="chevron-down" transform="translate(12.000000, 11.000000) rotate(-90.000000) translate(-12.000000, -11.000000) " points="7.712 8.856 12 13.144 16.288 8.856"></polyline>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>  
                    </span>
                </div>
            </div>
            <ul className="we-employee-details__requests">
                {employee.requests ? Object.values( employee.requests ).map( (request, index) => <RequestCard key={`REQUEST_CARD_${index}`} employeeID={employee.userId} request={request} acceptHolidayRequest={_acceptHolidayRequestHandler} deleteRequest={_deleteRequestHandler} hideTitle={true}/> ) : ""}
            </ul>
            {openRequests.length > 0 ? <div className="we-employee-details__accept-all-requests-btn"><button className="we-btn small" onClick={() => {_acceptAllRequestsHandler(openRequests)}}><svg width="14px" height="10px" viewBox="0 0 14 10" version="1.1" xmlns="http://www.w3.org/2000/svg"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round"><g transform="translate(-260.000000, -252.000000)" stroke="#FFFFFF" strokeWidth="2"><g transform="translate(254.555000, 244.555000)"><polyline points="7.241 12.445 10.71 15.914 17.649 8.976"></polyline></g></g></g></svg>alle Anträge annehmen </button></div> : ""}
        </li>
    )
}

function ManageRequestDetails({sessionToken, employee, setSelectedEmployee, _acceptHolidayRequestHandler, _deleteRequestHandler}) {
    const [employeeRequests ] = useState( employee.requests ? employee.requests : [] );
    var requests = {};

    Object.keys( employeeRequests ).map( key => {
        if( !requests.hasOwnProperty( employeeRequests[key].type ) ) {
            requests[employeeRequests[key].type] = [];
        }
        requests[employeeRequests[key].type].push( employeeRequests[key] )
    });

    return(
        <div className={`we-manage-request-details `}>
            <button className="we-back-btn" onClick={() => setSelectedEmployee( null )}>
                <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                        <g transform="translate(-35.000000, -70.000000)" stroke="#FFFFFF" strokeWidth="2">
                            <g>
                                <g transform="translate(18.000000, 53.000000)">
                                    <g transform="translate(24.104000, 23.903000) rotate(180.000000) translate(-24.104000, -23.903000) translate(18.604000, 18.403000)">
                                        <line x1="0.403" y1="5.403" x2="10.403" y2="5.403" id="Linie_41" strokeLinejoin="round"></line>
                                        <polyline points="5.403 0 10.803 5.4 5.403 10.8"></polyline>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </button>
            <p className="we-title">Antr&auml;ge<small>{employee.firstname}</small></p>
            
            {Object.keys( requests ).map( (requestType, i) => {
                if( requests[requestType] && requests[requestType].length > 0 ) {
                    return <>
                        <p key={`REQUEST_TITLE_${i}`} className="we-manage-request-details__title">{LOCATION_TYPES[requestType].label}</p>
                        <ul>
                            {requests[requestType].map( (request, index) => <RequestCard key={`REQUEST_CARD_${index}`} request={request} acceptHolidayRequest={_acceptHolidayRequestHandler} deleteRequest={_deleteRequestHandler} employeeID={employee.userId} />  ) }
                        </ul>
                    </>
                }
            })}
        </div>
    )
}

export default function ManageRequests({sessionToken}) {
    const [employees, setEmployees] = useState( null );
    const [isLoading, setIsLoading] = useState( true );
    const [selectedEmployee, setSelectedEmployee] = useState( null );
    const { UpdateOpenRequestNumber, AddSnackbar } = useContext(AppStateContext);
    
    let { requestID } = useParams();

    const _acceptHolidayRequestHandler = ( request, employeeID ) => {
        var user = API.getStorageUser();
        if( user !== null ) {
            API.approveRequest(sessionToken, request.requestDate , employeeID).then(data => {
                if( data ) {
                    setIsLoading( true );
                    API.getOpenRequests(sessionToken).then(data => {
                        setSelectedEmployee( null, setEmployees( data.data , setIsLoading( false, UpdateOpenRequestNumber() ) ) );
                    });
                }
            });
        }
    }

    const _acceptAllRequestsHandler = ( openRequests )  => {
        API.approveRequests(sessionToken,openRequests).then(data => {
            if( data ) {
                setIsLoading( true );
                API.getOpenRequests(sessionToken).then(data => {
                    setSelectedEmployee( null, setEmployees( data.data , setIsLoading( false, UpdateOpenRequestNumber() ) ) );
                });
            }
        });

    }

    const _deleteRequestHandler = ( request, employeeID ) => {
        var user = API.getStorageUser();
        if( user !== null ) {
            API.denyRequest( sessionToken, request.requestDate, employeeID ).then(data => { 
                if( data ) {
                    setIsLoading( true );
                    API.getOpenRequests(sessionToken).then(data => {
                        setSelectedEmployee( null, setEmployees( data.data , setIsLoading( false, UpdateOpenRequestNumber() ) ) );
                    });
                }
            });
        }
    }

    useEffect(() => {
        API.getOpenRequests( sessionToken ).then( data => {
            setEmployees( data.data , setIsLoading( false ) );
        });
    }, []);

    if (employees === null || isLoading) {
        return (
            <div className={`we-manage-requests `}>
                <Loading />
            </div>
        )

    } else {
        return(
            <Routes>
                <Route path="/" element={<>
                        {selectedEmployee !== null ? <ManageRequestDetails sessionToken={sessionToken} employee={selectedEmployee} setSelectedEmployee={setSelectedEmployee} _acceptHolidayRequestHandler={(request, employeeID) => AddSnackbar("Achtung! ☝️", "Möchtest du diesen " + LOCATION_TYPES[request.type].label + " Antrag wirklich annehmen?", "warning", () => {_acceptHolidayRequestHandler(request, employeeID)}, "Annehmen", "Abbrechen")} _deleteRequestHandler={(request, employeeID) => AddSnackbar("Achtung! ☝️", "Möchtest du diesen " + LOCATION_TYPES[request.type].label + " Antrag wirklich ablehnen?", "warning", () => {_deleteRequestHandler(request, employeeID)}, "Ablehnen", "Abbrechen")} /> : ""}
                        <div className={`we-manage-requests ${selectedEmployee !== null ? "blur" : ""}`}>
                            <p className="we-title">Antr&auml;ge</p>
                            {employees.length <= 0 ? <p className="we-manage-requests__empty">Zurzeit liegen keine Antr&auml;ge vor</p> : 
                                <ul className="we-manage-requests__list">
                                    {employees.map( (employee, index) => <EmployeeCard 
                                        key={`EMPLOYEE_CARD_${index}`} 
                                        employee={employee} 
                                        setSelectedEmployee={setSelectedEmployee} 
                                        _acceptHolidayRequestHandler={(request, employeeID) => AddSnackbar("Achtung! ☝️", "Möchtest du diesen " + LOCATION_TYPES[request.type].label + " Antrag wirklich annehmen?", "warning", () => {_acceptHolidayRequestHandler(request, employeeID)}, "Annehmen", "Abbrechen")} _deleteRequestHandler={(request, employeeID) => AddSnackbar("Achtung! ☝️", "Möchtest du diesen " + LOCATION_TYPES[request.type].label + " Antrag wirklich ablehnen?", "warning", () => {_deleteRequestHandler(request, employeeID)}, "Ablehnen", "Abbrechen")} 
                                        _acceptAllRequestsHandler={(openRequests) => AddSnackbar("Achtung! ☝️", "Möchtest du wirklich alle Anträge annehmen?", "warning", () => {_acceptAllRequestsHandler(openRequests)}, "Annehmen", "Abbrechen")}
                                    /> )}
                                </ul>
                            }
                            
                        </div>
                    </>}/>
                <Route path="/:requestID" element={<RequestDetailsLink sessionToken={sessionToken} selectedEmployee={selectedEmployee} path={"/antraege-verwalten/"} />} />
            </Routes>
        )
    }
}

function RequestDetailsLink({sessionToken, selectedEmployee, path}) {
    let { requestID } = useParams();
    var request = null;

    if( selectedEmployee ) {
        if( selectedEmployee.requests ) {
            Object.keys(selectedEmployee.requests).forEach(key => {
                if( btoa( key ) === requestID ) {
                    request = selectedEmployee.requests[key];
                }
            });
        }
    }

    return(
        <RequestDetails sessionToken={sessionToken} request={request} backURL={path} />
    )
}