import React from "react";
import { NavLink } from "react-router-dom";
import EmployeeDetails from './../components/EmployeeDetails';
import LOCATION_TYPES from '../classes/LOCATION_TYPES';


export default function LocationCard({ userData, selectedDay, locationName, employees, setSelectedLocationType, locationType, userInformations }) {
    var employeeBubbles = [];
    var lastTitle = "";

    function hasObjectPath(obj /*, level1, level2, ... levelN*/) {
        var args = Array.prototype.slice.call(arguments, 1);
      
        for (var i = 0; i < args.length; i++) {
          if (!obj || !obj.hasOwnProperty(args[i])) {
            return false;
          }
          obj = obj[args[i]];
        }
        return true;
    }

    employees.sort(function(a,b){
        if( !a || !b ) { return false;}
        if( !a.hasOwnProperty("status") || !b.hasOwnProperty("status") ) { return false;}
        return (a.status > b.status) ? 1 : ((b.status > a.status) ? -1 : 0);
    });

    
    if( locationName === LOCATION_TYPES["OUTOFOFFICE"].label ) {
        employees.sort(function(a,b){
            if( !a || !b ) { return false;}
            if( !a.hasOwnProperty("title") || !b.hasOwnProperty("title") ) { return false;}
            return (b.title > a.title) ? 1 : ((a.title > b.title) ? -1 : 0);
        });
    }

    for( var i = 0; i < employees.length; i++ ) {
        let isBirthday = false;
        if( window.matchMedia("(min-width: 40.063em)").matches  && locationName === LOCATION_TYPES.OUTOFOFFICE.label && lastTitle !== employees[i].title ) {
            lastTitle = employees[i].title;
            employeeBubbles.push(<li key={`FIRST_USER_TITLE_${i}`} className="we-location-card__title">{lastTitle}</li>);
        }
        if( window.matchMedia("(min-width: 40.063em)").matches === false && i >= 4 ) continue;
        var employeeBubble = {img: null, label: ""};
        if( !employees[i] ) { continue; }
        if( employees[i].hasOwnProperty("img") ) {
            employeeBubble["img"] = employees[i].img;
        }
        if( employees[i].hasOwnProperty("label") ) {
            employeeBubble["label"] += employees[i].label.charAt(0);
        }
        if( employees[i].birthdate && selectedDay ) {
            if( employees[i].birthdate.split("-")[1] === selectedDay.split("-")[1] && employees[i].birthdate.split("-")[2] === selectedDay.split("-")[2] ) {
                isBirthday = true;
            }
        }
        employeeBubbles.push( <li key={`FIRST_USER_DETAIL_${i}`}><EmployeeDetails key={`USER_DETAIL_${i}`} employee={employees[i]} calendar={userData[employees[i].userId]} selectedDay={selectedDay} isBirthday={isBirthday} userInformations={userInformations} /></li> );
    }
    employeeBubbles.sort(function(a, b) {
        if( !a || !b ){ return false; }
        if( !a.hasOwnProperty("props") || !b.hasOwnProperty("props") ){ return false; }
        if( !a.props.hasOwnProperty("children") || !b.props.hasOwnProperty("children") ){ return false; }
        if( !a.props.children || !b.props.children ){ return false; }
        if( !a.props.children.hasOwnProperty("props") || !b.props.children.hasOwnProperty("props") ){ return false; }
        if( !a.props.children.props.hasOwnProperty("isBirthday") || !b.props.children.props.hasOwnProperty("isBirthday") ){ return false; }
        return (a.props.children.props.isBirthday === b.props.children.props.isBirthday)? 0 : a.props.children.props.isBirthday? -1 : 1;
    });

    employeeBubbles = employeeBubbles.sort(function(a, b){
        if( !hasObjectPath(a, 'props', 'children', 'props', 'employee', 'isTeamleader') || !hasObjectPath(b, 'props', 'children', 'props', 'employee', 'isTeamleader') ) { return false; }
        return (a.props.children.props.employee.isTeamleader === b.props.children.props.employee.isTeamleader)? 0 : a.props.children.props.employee.isTeamleader? -1 : 1;
    });

    if( window.matchMedia("(min-width: 40.063em)").matches ) {
        return (
            <li key={`${Math.random() * (999999 - 0) + 0}_${Math.random() * (999999 - 0) + 0}`} className={`we-location-card ${locationName ? locationName.toLowerCase().replace(/\s/g, "") : ""}`}>
                <div className="we-location-card__btn">
                    <p className="we-location-card__label">
                        {locationName || ""}
                        <b>{employees.length}</b>
                    </p>
                    <ul className="we-location-card__employees">
                        {/*employeeBubbles.map( (employeeBubble, index) => <li key={`EMPLOYEE_BUBBLE_${index}`}>{(employeeBubble.img !== null && employeeBubble.img !== "") ? <img src={`data:image/png;base64,${employeeBubble.img}`} alt="" title="" /> : ""}{employeeBubble.label}</li>)*/}
                        {employeeBubbles}
                        {(window.matchMedia("(min-width: 40.063em)").matches === false && employees.length > 4) ?
                        <span className="we-employee-more">+ {employees.length - 4}</span>
                        : ""}
                    </ul>
                    <span className="we-location-card__details-btn" aria-label="Details">
                        <svg width="8px" height="12px" viewBox="0 0 8 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                                <g transform="translate(-361.000000, -361.000000)" stroke="#FFFFFF" strokeWidth="2">
                                    <g id="Gruppe_85">
                                        <g transform="translate(18.000000, 289.000000)">
                                            <g id="Gruppe_70" transform="translate(335.000000, 67.000000)">
                                                <polyline id="chevron-down" transform="translate(12.000000, 11.000000) rotate(-90.000000) translate(-12.000000, -11.000000) " points="7.712 8.856 12 13.144 16.288 8.856"></polyline>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </span>
                </div>
            </li>
        )
    } else {
        return (
            <li key={`${Math.random() * (999999 - 0) + 0}_${Math.random() * (999999 - 0) + 0}`} className={`we-location-card ${locationName ? locationName.toLowerCase().replace(/\s/g, "") : ""}`}>
                <NavLink 
                    to={`${process.env.PUBLIC_URL}/dashboard/${LOCATION_TYPES[locationType].label.toLowerCase()}`} 
                    className="we-location-card__btn" 
                    onClick={() => setSelectedLocationType( locationType )} 
                    disabled={window.matchMedia("(min-width: 40.063em)").matches} 
                >
                    <p className="we-location-card__label">
                        {locationName || ""}
                        <b>{employees.length}</b>
                    </p>
                    <ul className="we-location-card__employees">
                        {/*employeeBubbles.map( (employeeBubble, index) => <li key={`EMPLOYEE_BUBBLE_${index}`}>{(employeeBubble.img !== null && employeeBubble.img !== "") ? <img src={`data:image/png;base64,${employeeBubble.img}`} alt="" title="" /> : ""}{employeeBubble.label}</li>)*/}
                        {employeeBubbles}
                        {(window.matchMedia("(min-width: 40.063em)").matches === false && employees.length > 4) ?
                        <span className="we-employee-more">+ {employees.length - 4}</span>
                        : ""}
                    </ul>
                    <span className="we-location-card__details-btn" aria-label="Details">
                        <svg width="8px" height="12px" viewBox="0 0 8 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                                <g transform="translate(-361.000000, -361.000000)" stroke="#FFFFFF" strokeWidth="2">
                                    <g id="Gruppe_85">
                                        <g transform="translate(18.000000, 289.000000)">
                                            <g id="Gruppe_70" transform="translate(335.000000, 67.000000)">
                                                <polyline id="chevron-down" transform="translate(12.000000, 11.000000) rotate(-90.000000) translate(-12.000000, -11.000000) " points="7.712 8.856 12 13.144 16.288 8.856"></polyline>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </span>
                </NavLink>
            </li>
        )
    }
    
}