import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import LOCATION_TYPES from '../classes/LOCATION_TYPES';

export default function VacationRequest({ sessionToken, setRequestType, startDate, setStartDate, endDate, setEndDate, factor, setFactor, formatDate, setIsRequestSuccessful, AddSnackbar, _getRandomRequestTitle, API }) {
    const [requestTitle, setRequestTitle] = useState("");
    const [specialVacationTitle, setSpecialVacationTitle] = useState("");
    const [_requestType, _setRequestType] = useState(LOCATION_TYPES.VACATION.name);
    const [maxVacationDays, setMaxVacationDays] = useState( null );

    const _handleSubmit = (e) => {
        e.preventDefault();
        var title = _requestType === LOCATION_TYPES.VACATION_SPECIAL.name ? specialVacationTitle : requestTitle;
        API.sendHolidayRequest(sessionToken, formatDate(startDate,'yyyy-mm-dd'), formatDate(endDate,'yyyy-mm-dd'), factor, _requestType, title).then(response => {
            if (response.success) {
                AddSnackbar(_getRandomRequestTitle(), "Dein Urlaubsantrag wird bearbeitet.", LOCATION_TYPES.VACATION.name, "profil", "Im Profil anzeigen");
                setIsRequestSuccessful(true);
            } else {
                AddSnackbar("Hoppla! ⚠️", "Da ist wohl etwas schief gelaufen.", "warning");
            }
        });
    }

    const _factorHandler = (value) => {
        if (value === factor) {
            setFactor("1");
            return;
        }
        setFactor(value, setEndDate(startDate));
    }

    const _specialVacationHandler = (value) => {
        if (_requestType === value) {
            _setRequestType(LOCATION_TYPES.VACATION.name);
        } else {
            _setRequestType(LOCATION_TYPES.VACATION_SPECIAL.name, setFactor("1"));
        }
    }

    const _maxDateHandler = () => {
        var d = new Date( startDate );
        d.setDate( d.getDate() + maxVacationDays )
        return formatDate( d ).split(".").reverse().join("-") ;
    }

    useEffect(function () {
        if( maxVacationDays === null ) {
            var user = API.getStorageUser();
            if( user && user.maxVacationDays ) {
                setMaxVacationDays( user.maxVacationDays );
            }
        }
    }, [maxVacationDays,sessionToken]);

    return (
        <div className="we-request vacation">
            <NavLink to={`${process.env.PUBLIC_URL}/antrag-hinzufuegen`} className="we-request__back-btn" onClick={() => setRequestType(null)}>
                <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                        <g transform="translate(-35.000000, -70.000000)" stroke="#FFFFFF" strokeWidth="2">
                            <g>
                                <g transform="translate(18.000000, 53.000000)">
                                    <g transform="translate(24.104000, 23.903000) rotate(180.000000) translate(-24.104000, -23.903000) translate(18.604000, 18.403000)">
                                        <line x1="0.403" y1="5.403" x2="10.403" y2="5.403" id="Linie_41" strokeLinejoin="round"></line>
                                        <polyline points="5.403 0 10.803 5.4 5.403 10.8"></polyline>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </NavLink>
            <h2>Hinzuf&uuml;gen</h2>
            <h3><b>Urlaub</b> beantragen</h3>
            <form onSubmit={_handleSubmit}>
                <label>
                    Startdatum
                <input
                    type="date"
                    className="we-input"
                    name="startDate"
                    value={formatDate(startDate, "yyyy-mm-dd")}
                    onChange={(e) => setStartDate(e.target.value)}
                    required
                />
                </label>
                <label>
                    Enddatum
                <input
                    type="date"
                    className="we-input"
                    name="endDate"
                    value={formatDate(endDate, "yyyy-mm-dd")}
                    onChange={(e) => setEndDate(e.target.value)}
                    min={startDate}
                    max={_maxDateHandler()}
                    disabled={factor === "0.5" || _requestType === LOCATION_TYPES.VACATION_SPECIAL.name}
                    required
                />
                </label>
                <div className="we-request__checkboxes">
                    <label className="we-checkbox">
                        Halbtags
                        <input
                            type="checkbox"
                            value="0.5"
                            checked={factor === "0.5"}
                            onChange={e => _factorHandler(e.target.value)}
                            disabled={_requestType === LOCATION_TYPES.VACATION_SPECIAL.name}
                        />
                        <span className="we-checkbox__input">&nbsp;</span>
                    </label>
                    <label className="we-checkbox">
                        Sonderurlaub
                        <input
                            type="checkbox"
                            value={LOCATION_TYPES.VACATION_SPECIAL.name}
                            checked={_requestType === LOCATION_TYPES.VACATION_SPECIAL.name}
                            onChange={e => _specialVacationHandler(e.target.value)}
                        />
                        <span className="we-checkbox__input">&nbsp;</span>
                    </label>
                </div>
                {_requestType === LOCATION_TYPES.VACATION_SPECIAL.name ?
                    <div className="we-request__special-vacation">
                        <p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
                        Sonderurlaub muss explizit vom Teamleiter und der Gesch&auml;ftsf&uuml;hrung abgesprochen sein!
                        </p>
                        <div className="we-select__wrapper">
                            <select
                                className="we-select"
                                required
                                onChange={(e) => setSpecialVacationTitle(e.target.value)}
                            >
                                <option value="Beerdigung">Beerdigung</option>
                                <option value="Heirat">Heirat</option>
                                <option value="Geburt">Geburt</option>
                                <option value="Gerichtstermin">Gerichtstermin</option>
                                <option value="Umzug">Umzug</option>
                            </select>
                            <span className="we-select__arrow">
                                <svg width="12px" height="8px" viewBox="0 0 12 8" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                                        <g transform="translate(-212.000000, -257.000000)" stroke="#FFFFFF" strokeWidth="2">
                                            <g>
                                                <g transform="translate(18.000000, 240.000000)">
                                                    <g transform="translate(200.000000, 20.000000) rotate(90.000000) translate(-200.000000, -20.000000) translate(189.000000, 9.000000)">
                                                        <polyline transform="translate(12.000000, 11.000000) rotate(-90.000000) translate(-12.000000, -11.000000) " points="7.712 8.856 12 13.144 16.288 8.856"></polyline>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </span>
                        </div>
                    </div>
                    :
                    <label className="we-request__title">
                        Titel (optional)
                    <input
                            type="text"
                            className="we-input"
                            max="30"
                            placeholder="z.B. Sommerurlaub"
                            value={requestTitle}
                            onChange={(e) => setRequestTitle(e.target.value)}
                        />
                    </label>
                }

                <button className="we-btn" type="submit">Beantragen</button>
            </form>
        </div>
    )
}