import React from "react";

export default function Loading() {
    return (
        <div className="we-loading">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
        </div>
    )
}