
var md5 = require('md5');

var API = (function (module) {
  //API Dokumentation: https://showroom.ws-epic.de/urlaub/app/api/test.php
  //NEUE API: https://showroom.ws-epic.de/urlaub/app/api/v1.0.0/

  //jke | KLess8
  // AkpUP19

  //var API_URL = "https://showroom.ws-epic.de/urlaub/app/api.php";
  //var API_URL_V1 = "https://showroom.ws-epic.de/urlaub/app/api/v1.0.0/";
  var API_URL = "https://up.ws-epic.de/api.php"; 
  var API_URL_V1 = "https://up.ws-epic.de/api/v1.0.0/"; 

  var LOCAL_STORAGE_DASHBOARD = "WSEPIC_HOLIDAY_DASHBOARD";
  var LOCAL_STORAGE_LIST = "WSEPIC_HOLIDAY_LIST";
  var LOCAL_STORAGE_CALENDER = "WSEPIC_HOLIDAY_CALENDER";
  var LOCAL_STORAGE_TEAMS = "WSEPIC_TEAMS";
  var LOCAL_STORAGE_PROFILE = "WSEPIC_OFFICE_PROFILE";
  var LOCAL_STORAGE_USER = "WSEPIC_OFFICE_USER";
  var LOCAL_STORAGE_VERSION = "4.4";

  function getWeeks(token = null, teamID = null, date = null, weekNumber = null) {
    if (token === null) return null;
    return fetch(API_URL_V1, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=getWeeks&token=${token}${teamID !== null ? '&teamId=' + teamID : ''}${date !== null ? '&date=' + date : ''}${weekNumber !== null ? '&weekNumber=' + weekNumber : ''}`

    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return null;
      }
    }).then(data => {
      //localStorage.setItem(LOCAL_STORAGE_DASHBOARD, JSON.stringify(data));
      return data;
    }).catch(err => {
      return new Promise(function (resolve, reject) {
        let data = localStorage.getItem(LOCAL_STORAGE_DASHBOARD);
        if (data !== null) {
          data = JSON.parse(data);
          resolve(data);
        } else {
          reject(null);
        }
      });
    });
  }

  function getProfile( token = null ) {
    if (token === null) return null;
    return fetch(API_URL_V1, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=getProfile&token=${token}`

    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return null;
      }
    }).then(data => {
      localStorage.setItem(LOCAL_STORAGE_PROFILE, JSON.stringify(data));
      return data;
    }).catch(err => {
      return new Promise(function (resolve, reject) {
        let data = localStorage.getItem(LOCAL_STORAGE_TEAMS);
        if (data !== null) {
          data = JSON.parse(data);
          resolve(data);
        } else {
          reject(null);
        }
      });
    });
  }

  function getOpenRequests(token = null, userID = null) {
    if (token === null) return null;
    return fetch(API_URL_V1, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=getOpenRequests&token=${token}${userID !== null ? '&userId=' + userID : ''}`

    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return null;
      }
    });
  }

  function getOpenRequestsNumber(token = null, userID = null) {
    if (token === null) return null;
    return fetch(API_URL_V1, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=getOpenRequests&token=${token}${userID !== null ? '&userId=' + userID : ''}`

    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return null;
      }
    }).then((data) => {
      var number = 0;
      var user = API.getStorageUser();
      if( data && data.data && user ) {
        data.data.map( employee => {
          number += Object.keys(employee.requests).filter(request => !employee.requests[request].approvals || !employee.requests[request].approvals[user.uid]).length;
        });
      }
      return number;
    });
  }

  function getClaim(token = null, year = null, type = null) {
    if (token === null) return null;
    return fetch(API_URL_V1, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=getClaim&token=${token}${year !== null ? '&year=' + year : ''}${type !== null ? '&type=' + type : ''}`

    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return null;
      }
    });
  }

  function getRequestDetails(token = null, requestId = null) {
    if (token === null) return null;
    return fetch(API_URL_V1, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=getRequestDetails&token=${token}${requestId !== null ? '&requestId=' + requestId : ''}`

    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return null;
      }
    });
  }

  function getPlan(token = null, teamID = null, year = null, types = null) {
    if (token === null) return null;
    var body = `task=getPlan&token=${token}${year !== null ? '&year=' + year : ''}${teamID !== null ? '&teamId=' + teamID : ''}${types.length > 0 ? types.map(type => `&types[]=${type}`).join("") : ""}`.replace(",","");
    return fetch(API_URL_V1, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: body

    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return null;
      }
    });
  }

  function getVacationOverview( token = null, year = null ) {
    if (token === null) return [];
    return fetch(API_URL_V1, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=getVacationOverview&token=${token}${year ? "&year="+year : ""}`

    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return null;
      }
    });
  }

  function cancelVacation( token = null, requestId = null ) {
    if (token === null) return [];
    return fetch(API_URL_V1, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=cancelVacation&token=${token}${requestId ? "&requestId="+requestId : ""}`

    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return null;
      }
    });
  }

  function getVacationRequests( token = null, year = null ) {
    if (token === null) return [];
    return fetch(API_URL_V1, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=getVacationRequests&token=${token}${year ? "&year="+year : ""}`

    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return null;
      }
    });
  }

  function getReportList( token = null ) {
    if (token === null) return [];
    return fetch(API_URL_V1, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=getReportList&token=${token}`

    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return null;
      }
    });
  }

  function getTeams(token = null, userID = null) {
    if (token === null) return null;
    return fetch(API_URL_V1, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=getTeams&token=${token}${userID !== null ? '&userId=' + userID : ''}`

    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return null;
      }
    }).then(data => {
      localStorage.setItem(LOCAL_STORAGE_TEAMS, JSON.stringify(data));
      return data;
    }).catch(err => {
      return new Promise(function (resolve, reject) {
        let data = localStorage.getItem(LOCAL_STORAGE_TEAMS);
        if (data !== null) {
          data = JSON.parse(data);
          resolve(data);
        } else {
          reject(null);
        }
      });
    });
  }

  //TODO Deprecated
  function getDasboard(token = null) {
    if (token === null) return null;
    return fetch(API_URL, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: "task=getDashboardData&token=" + token

    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return null;
      }
    }).then(data => {
      localStorage.setItem(LOCAL_STORAGE_DASHBOARD, JSON.stringify(data));
      return data;
    }).catch(err => {
      return new Promise(function (resolve, reject) {
        let data = localStorage.getItem(LOCAL_STORAGE_DASHBOARD);
        if (data !== null) {
          data = JSON.parse(data);
          resolve(data);
        } else {
          reject(null);
        }
      });
    });
  }

  function getHolidayList(token = null) {
    if (token === null) { return null; }
    return fetch(API_URL, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: "task=getHolidays&token=" + token
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return null;
        }
      }).then(data => {
        if (data.success) {
          localStorage.setItem(LOCAL_STORAGE_LIST, JSON.stringify(data.data));
          return data.data;

        } else {
          return null;
        }
      }).catch((error) => {
        return new Promise(function (resolve, reject) {
          let data = localStorage.getItem(LOCAL_STORAGE_LIST);
          if (data !== null) {
            data = JSON.parse(data);
            resolve(data);
          } else {
            reject(null);
          }
        });
      });
  }

  //TODO Deprecated
  function getHolidayCalender(token = null, number = 2, date = "") {
    if (token === null) { return null; }
    return fetch(API_URL, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: "task=getWeeks&token=" + token + "&number=" + number //+"&date="+date
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return null;
        }
      }).then(data => {
        localStorage.setItem(LOCAL_STORAGE_CALENDER, JSON.stringify(data));
        return data;
      }).catch((error) => {
        return new Promise(function (resolve, reject) {
          let data = localStorage.getItem(LOCAL_STORAGE_CALENDER);
          if (data !== null) {
            data = JSON.parse(data);
            resolve(data);
          } else {
            reject(null);
          }
        });
      });
  }

  function userLogin(username, password, googleIdToken, googleLogin = true) {
    return fetch(API_URL_V1, {
      method: "POST",
      //credentials: "include",
      referrerPolicy: 'no-referrer',
      // AkpUP19
      body: `task=login${username !== "" ? "&uname=" + username : ""}${password !== "" ? "&pwd=" + md5(password) : ""}${googleIdToken !== "" ? "&googleIdToken=" + googleIdToken : ""}`,
      headers: new Headers(
        { "content-type": "application/x-www-form-urlencoded" })
    }).then((response) => {
      return response.json();
      // do something awesome that makes the world a better place
    }).then((data) => {
      if (data.success && data.token) {
          setLocalStorageUser( { token: data.token, isAdmin: data.isAdmin, userID: data.uid, version: LOCAL_STORAGE_VERSION, teams: data.teams, role: data.role, authURL: data.authURL, maxVacationDays: data.maxVacationDays, gl: googleLogin } );
      } 
      return data;
    });
  }

  function setLocalStorageUser( data ) {
    localStorage.setItem( LOCAL_STORAGE_USER, JSON.stringify(data) );
  }

  function getCalendarEvents(token) {
    fetch(API_URL_V1, {
      method: "POST",
      //credentials: "include",
      referrerPolicy: 'no-referrer',
      body: `task=getCalendarEvents${token !== "" ? "&token=" + token : ""}`,
      headers: new Headers(
        { "content-type": "application/x-www-form-urlencoded" })
    }).then((response) => {
      return response.json();
      // do something awesome that makes the world a better place
    });
  }

  function userLogout(cb) {
    return fetch(API_URL_V1, {
      method: "POST",
      // AkpUP19
      body: `task=logout`,
      headers: new Headers(
        { "content-type": "application/x-www-form-urlencoded" })
    }).then((response) => {
      // do something awesome that makes the world a better place
      localStorage.removeItem(LOCAL_STORAGE_DASHBOARD);
      localStorage.removeItem(LOCAL_STORAGE_LIST);
      localStorage.removeItem(LOCAL_STORAGE_CALENDER);
      localStorage.removeItem(LOCAL_STORAGE_DASHBOARD);
      localStorage.removeItem(LOCAL_STORAGE_LIST);
      localStorage.removeItem(LOCAL_STORAGE_CALENDER);
      localStorage.removeItem(LOCAL_STORAGE_TEAMS);
      localStorage.removeItem(LOCAL_STORAGE_PROFILE);
      localStorage.removeItem(LOCAL_STORAGE_USER);
      localStorage.removeItem("WSEPIC_HOLIDAY");
      if( cb ) {
        cb();
      }
      return response;
    });
  }

  function sendHolidayRequest(token, startDate, endDate, factor = "1", holidayType, title = "", sickNotes = "", weekDays = []) {
    var data = new FormData();
    data.append("task", "sendHolidayRequest");
    if( startDate ) {
      data.append("startDate", startDate);
    }
    if( endDate ) {
      data.append("endDate", endDate);
    }
    if( title ) {
      data.append("title", title);
    }
    if( factor ) {
      data.append("factor", factor);
    }
    if( token ) {
      data.append("token", token);
    }
    if( holidayType ) {
      data.append("type", holidayType);
    }
    if( sickNotes && sickNotes.length > 0 ) {
      data.append("files", sickNotes[0]);
    }
    if( weekDays.length > 0 ) {
      weekDays.map(weekday => data.append("weekdays[]", weekday) );
    }
    //data.append("weekdays[]", sickNotes[0]);
    //`task=sendHolidayRequest&startDate=${startDate}&endDate=${endDate}&title=${title}&factor=${factor}&token=${token}&type=${holidayType}${sickNotes !== "" ? '&files=' + sickNotes[0] : ""}${weekDays.length > 0 ? weekDays.map(weekDay => `&weekdays[]=${weekDay}`).join("") : ""}`
    return fetch(API_URL_V1, {
      method: "POST",
      body: data,
    }).then((response) => {
      return response.json();
    }).then(data => {
      return data;
    });
  }

  function sendSickRequest(formData = null, cb = null) {
    var url = `${API_URL_V1}?task=sendHolidayRequest`;
    var xhr = new XMLHttpRequest();

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          var response = JSON.parse(xhr.response);
          if (response.success) {
            cb(true);
          } else {
            cb(false);
          }
        }
      }
    }
    xhr.open('POST', url, true);
    xhr.send(formData);
    return;
  }

  function deleteRequest(token, requestDate) {
    return fetch(API_URL_V1, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=deleteRequest&requestDate=${requestDate}&token=${token}`,
    }).then((response) => {
      return response.json();
    }).then(data => {
      return data;
    });
  }

  function deleteHomeoffice(token, requestDate = null, date = null) {
    return fetch(API_URL_V1, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=deleteHomeoffice${requestDate !== null ? "&requestDate=" + requestDate : ""}${date !== null ? "&date=" + date : ""}&token=${token}`,
    }).then((response) => {
      return response.json();
    }).then(data => {
      return data;
    });
  }

  function setGoogleAccessToken( token = null, googleAuthCode = null ) {
    if( token === null || googleAuthCode === null ) { return {success: false}; }
    return fetch(API_URL_V1, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=setGoogleAccessToken${googleAuthCode !== null ? "&googleAuthCode=" + googleAuthCode : ""}&token=${token}`,
    }).then((response) => {
      return response.json();
    }).then(data => {
      return data;
    });
  }

  function syncWithGoogle( token = null, gl = null ) {
    if( token === null ) { return {success: false}; }
    let user = getStorageUser();
    return fetch(API_URL_V1, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=syncWithGoogle&token=${token}&gl=${user.gl}`,
    }).then((response) => {
      return response.json();
    }).then(data => {
      return data;
    });
    //
  }

  //TODO Deprecated
  function getAllRequests(token = null) {
    if (token === null) { return null; }
    return fetch(API_URL, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=getAllRequests&token=${token}`,
    }).then((response) => {
      return response.json();
    }).then(data => {
      if (data.success) {
        data = Object.values(data.data);
        return data;
      } else {
        return [];
      }
    });
  }

  //TODO Deprecated
  function getDeniedRequests(token = null) {
    if (token === null) { return null; }
    return fetch(API_URL, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=getDeniedRequests&token=${token}`,
    }).then((response) => {
      return response.json();
    }).then(data => {
      if (data.success) {
        return Object.values(data.data.requests);
      } else {
        return [];
      }
    });
  }

  //TODO Deprecated
  function getApprovedRequests(token = null) {
    if (token === null) { return null; }
    return fetch(API_URL, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=getApprovedRequests&token=${token}`,
    }).then((response) => {
      return response.json();
    }).then(data => {
      if (data.success) {
        return Object.values(data.data.requests);
      } else {
        return [];
      }
    });
  }

  //TODO DEPRECATED
  function acceptHolidayRequest(token = null, requestDate = null, userID = null) {
    if (token === null || requestDate === null || userID === null) return;
    return fetch(API_URL_V1, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=acceptRequest&token=${token}&requestDate=${requestDate}&userId=${userID}`,
    }).then((response) => {
      return response.json();
    }).then(data => {
      return data.success === true;
    });
  }

  function approveRequest(token = null, requestDate = null, userID = null) {
    if (token === null || requestDate === null || userID === null) return;
    return fetch(API_URL_V1, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=approveRequest&token=${token}&requestDate=${requestDate}&userId=${userID}`,
    }).then((response) => {
      return response.json();
    }).then(data => {
      return data.success === true;
    });
  }

  function approveRequests(token = null, openRequests = []) {
    if (token === null || openRequests.length <= 0) return;
    return fetch(API_URL_V1, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=approveRequests&token=${token}${openRequests.length > 0 ? openRequests.map(requestID => `&requestIds[]=${requestID}`).join("") : ""}`,
    }).then((response) => {
      return response.json();
    }).then(data => {
      return data.success === true;
    });
  }

  function denyRequest(token = null, requestDate = null, userID = null) {
    if (token === null || requestDate === null || userID === null) return;
    return fetch(API_URL_V1, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=denyRequest&token=${token}&requestDate=${requestDate}&userId=${userID}`,
    }).then((response) => {
      return response.json();
    }).then(data => {
      return data.success === true;
    });
  }

  function deleteDeniedHolidayRequests(token = null, requestDates = null) {
    if (token === null || requestDates === null) return null;
    return fetch(API_URL_V1, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=deleteRequests&token=${token}&requestDates=${JSON.stringify(requestDates)}`,
    }).then((response) => {
      return response.json();
    }).then(data => {
      return data;
    });
  }

  //TODO Deprecated
  function deDirtyHolidayRequests(token = null, requestDates = null) {
    if (token === null || requestDates === null) return null;
    return fetch(API_URL, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=dedirtyRequests&token=${token}&requestDates=${JSON.stringify(requestDates)}`,
    }).then((response) => {
      return response.json();
    }).then(data => {
      return data.success === true;
    });
  }

  function getPhoneNumbers(token = null) {
    return fetch(API_URL_V1, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=getPhoneNumbers&token=${token}`,
    }).then((response) => {
      return response.json();
    });
  }

  function activatePhoneNumber(token = null, phoneNumberID = null) {
    return fetch(API_URL_V1, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=activatePhoneNumber&token=${token}${phoneNumberID !== null ? `&phoneNumberId=${phoneNumberID}` : ""}`,
    }).then((response) => {
      return response.json();
    });
  }

  function deletePhoneNumber(token = null, phoneNumberID = null) {
    return fetch(API_URL_V1, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=deletePhoneNumber&token=${token}${phoneNumberID !== null ? `&phoneNumberId=${phoneNumberID}` : ""}`,
    }).then((response) => {
      return response.json();
    });
  }

  function addPhoneNumber(token = null, phoneNumber = null) {
    return fetch(API_URL_V1, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=addPhoneNumber&token=${token}${phoneNumber !== null ? `&phoneNumber=${phoneNumber}` : ""}`,
    }).then((response) => {
      return response.json();
    });
  }

  function createTeam(token = null, teamName = null) {
    if (token === null || teamName === null) return null;
    return fetch(API_URL_V1, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=createTeam&token=${token}${teamName !== null ? `&teamName=${teamName}` : ""}`,
    }).then((response) => {
      return response.json();
    });
  }

  function createUser(
    token = "", 
    firstname = "", 
    lastname = "", 
    number = "", 
    email = "", 
    username = "", 
    password = "", 
    teamIds = [], 
    employeeRegion = "",
    startDate = "", 
    endDate = "", 
    birthdate = "", 
    currentVacationClaim = "", 
    lastYearVacationClaim = "", 
    sickChildClaim = "", 
    freeWeekdays = "" 
  ) {
    var paramURL = `task=createUser${token !== "" ? "&token=" + token : ""}${firstname !== "" ? "&firstname=" + firstname : ""}${lastname !== "" ? "&lastname=" + lastname : ""}${number !== "" ? "&number=" + number : ""}${email !== "" ? "&email=" + email : ""}${username !== "" ? "&username=" + username : ""}${password !== "" ? "&password=" + md5(password) : ""}${teamIds.length > 0 ? teamIds.map(teamID => `&teamIds[]=${teamID}`).join("") : ""}${employeeRegion !== "" ? `&regionId=${employeeRegion}` : ""}${startDate !== "" ? "&startDate=" + startDate : ""}${endDate !== "" ? "&endDate=" + endDate : ""}${birthdate !== "" ? "&birthdate=" + birthdate : ""}${currentVacationClaim !== "" ? "&currentVacationClaim=" + currentVacationClaim : ""}${lastYearVacationClaim !== "" ? "&lastYearVacationClaim=" + lastYearVacationClaim : ""}${sickChildClaim !== "" ? "&sickChildClaim=" + sickChildClaim : ""}${freeWeekdays.length > 0 ? freeWeekdays.map(weekDay => `&freeWeekdays[]=${weekDay}`).join("") : ""}`;
    paramURL = paramURL.replace(/,/g, "");
    return fetch(API_URL_V1, {
      method: "POST",
      referrerPolicy: 'no-referrer',
      body: paramURL,
      headers: new Headers(
        { "content-type": "application/x-www-form-urlencoded" })
    }).then((response) => {
      return response.json();
      // do something awesome that makes the world a better place
    }).then((data) => {
      return data;
    });
  }

  function getEmployee( token = null, employeeId = null ) {
    if (token === null) return [];
    return fetch(API_URL_V1, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=getEmployee&token=${token}${employeeId ? "&userId="+employeeId : ""}`

    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return {status: 200, employee: {firstname: "Jasper", lastname: "Keßler", img: null}};
      }
    });
  }
  function getEmployeeData( token = null, userId = null, year= null ) {
    if (token === null) return [];
    return fetch(API_URL_V1, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=getEmployeeData&token=${token}${userId ? "&userId="+userId : ""}${year ? "&year="+year : ""}`

    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return {status: 200, employee: {firstname: "Jasper", lastname: "Keßler", img: null}};
      }
    });
  }

  function setEmployee(token = null, userId = null, firstname = null, lastname = null, number = null, active = null, birthdate = null ) {
    if (token === null) return null;
    return fetch(API_URL_V1, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=setEmployee${token !== null ? `&token=${token}` : ''}${userId !== null ? `&userId=${userId}` : ''}${firstname !== null ? `&firstname=${firstname}` : ''}${lastname !== null ? `&lastname=${lastname}` : ''}${number !== null ? `&number=${number}` : ''}${active !== null ? `&active=${active}` : ''}${birthdate !== null ? `&birthdate=${birthdate}` : ''}`

    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return null;
      }
    });
  }

  function downloadAttachment(token = null, requestId = null) {
    if (token === null) return null;
    return fetch(API_URL_V1, {
      method: "POST",
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `task=downloadAttachment${token !== null ? `&token=${token}` : ''}${requestId !== null ? `&requestId=${requestId}` : ''}`

    }).then(response => response);
  }

  function getStorageUser() {
    let data = localStorage.getItem(LOCAL_STORAGE_USER);
    if (data !== null) {
      data = JSON.parse(data);
      return data
    } else {
      return null;
    }
  }

  function getLocalStorageVersion() {
    return LOCAL_STORAGE_VERSION;
  }

  function getApiUrl() {
    return API_URL_V1;
  }

  module.getDasboard = getDasboard;
  module.getAllRequests = getAllRequests;
  module.getHolidayList = getHolidayList;
  module.getHolidayCalender = getHolidayCalender;
  module.userLogin = userLogin;
  module.sendHolidayRequest = sendHolidayRequest;
  module.deleteRequest = deleteRequest;
  module.acceptHolidayRequest = acceptHolidayRequest;
  module.denyRequest = denyRequest;
  module.userLogout = userLogout;
  module.getLocalStorageVersion = getLocalStorageVersion;
  module.deleteDeniedHolidayRequests = deleteDeniedHolidayRequests;
  module.getDeniedRequests = getDeniedRequests;
  module.getApprovedRequests = getApprovedRequests;
  module.deDirtyHolidayRequests = deDirtyHolidayRequests;
  module.deleteHomeoffice = deleteHomeoffice;
  module.sendSickRequest = sendSickRequest;
  module.getWeeks = getWeeks;
  module.getTeams = getTeams;
  module.getProfile = getProfile;
  module.getStorageUser = getStorageUser;
  module.getOpenRequests = getOpenRequests;
  module.approveRequest = approveRequest;
  module.getClaim = getClaim;
  module.getVacationOverview = getVacationOverview;
  module.getReportList = getReportList;
  module.getVacationRequests = getVacationRequests;
  module.getOpenRequestsNumber = getOpenRequestsNumber;
  module.createUser = createUser;
  module.createTeam = createTeam;
  module.getCalendarEvents = getCalendarEvents;
  module.addPhoneNumber = addPhoneNumber;
  module.deletePhoneNumber = deletePhoneNumber;
  module.activatePhoneNumber = activatePhoneNumber;
  module.getPhoneNumbers = getPhoneNumbers;
  module.setGoogleAccessToken = setGoogleAccessToken;
  module.syncWithGoogle = syncWithGoogle;
  module.setLocalStorageUser = setLocalStorageUser;
  module.getPlan = getPlan;
  module.getEmployee = getEmployee;
  module.getEmployeeData = getEmployeeData;
  module.approveRequests = approveRequests;
  module.getRequestDetails = getRequestDetails;
  module.cancelVacation = cancelVacation;
  module.setEmployee = setEmployee;
  module.downloadAttachment = downloadAttachment;
  module.getApiUrl = getApiUrl;

  return module;
}(API || {}));

window.logout = API.userLogout;

export default API;
