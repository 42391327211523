import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";

import LOCATION_TYPES from '../classes/LOCATION_TYPES';

export default function SickRequest({ sessionToken, setRequestType, startDate, setStartDate, endDate, setEndDate, factor, formatDate, setIsRequestSuccessful, AddSnackbar, API }) {
    const [sickNotes, setSickNotes] = useState("");
    const [_requestType, _setRequestType] = useState(LOCATION_TYPES.SICK_INOFFICIAL.name);
    const sickNoteInputRef = useRef();
    const [sickChildClaim, setSickChildClaim] = useState( null );
    const [isSickWithoutNote, setIsSickWithoutNote] = useState( false );
    var sickNoteContent = "";
    var sickOnChildContent = "";

    const _handleSubmit = (e) => {
        {/*
          2023-01-27 RGO: overruling SICK type based on selected type in frontend
        */}
   
        let requestType = _requestType;
        e.preventDefault();

        /* @since 2023-11-02 */
        console.log(_requestType);
        if ( isSickWithoutNote ) {
            requestType = LOCATION_TYPES.SICK_INOFFICIAL.name;
        } else {
            if ( requestType=="SICK_CHILD" ) {
                requestType = LOCATION_TYPES.SICK_CHILD.name;
            } else {
                requestType = LOCATION_TYPES.SICK.name;
            }
            
        }

        {/*
        if( !isSickWithoutNote && !sickNotes ) {
            AddSnackbar("Achtung! ⚠️", 'Bitte lade einen Krankenschein hoch oder wähle die Option "Ich habe keinen Schein" aus.', "warning");
            return;
        }
        */}

        //console.log("before: "+_requestType);
        /* @depricated 2023-11-02 
        if(!isSickWithoutNote){
            //console.log("changing type");
            //console.log("changed: "+_requestType);
            requestType = LOCATION_TYPES.SICK.name;
        }
        */
        //console.log(isSickWithoutNote);
        
        API.sendHolidayRequest(sessionToken, formatDate(startDate), formatDate(endDate), factor, requestType, "", sickNotes).then(response => {
            if (response.success) {
                AddSnackbar("Gute Besserung!", `Deine Krankmeldung wurde eingereicht.`, LOCATION_TYPES.SICK.name);
                setIsRequestSuccessful(true);
            } else {
                AddSnackbar("Hoppla! ⚠️", "Da ist wohl etwas schief gelaufen.", "warning");
            }
        });
        
        
    }

    const _setStartDateHandler = (newStartDate) => {
        setStartDate(newStartDate, setEndDate(newStartDate));
    }

    const _setEndDateHandler = (newEndDate = null) => {
        if (newEndDate === null) { return; }
        if (new Date(startDate).setHours(0, 0, 0, 0) > new Date(newEndDate).setHours(0, 0, 0, 0)) {
            //TODO Snackbar for Warning
            return;
        }
        setEndDate(newEndDate);

    }

    const _changeSickNoteInput = (e) => {
        setSickNotes(e.target.files, _setRequestType(LOCATION_TYPES.SICK.name));
    }

    const _clearSickNotes = () => {
        setSickNotes("", _setRequestType(LOCATION_TYPES.SICK_INOFFICIAL.name), setEndDate(startDate));
    }

    const _sickOnChildHandler = (e) => {
        if( _requestType === e ) {
            _setRequestType( LOCATION_TYPES.SICK.name );
            /* @depricated 2023-11-02 
            { 
                
                if( sickNotes ) {
                    _setRequestType( LOCATION_TYPES.SICK.name );
                } else {
                    _setRequestType( LOCATION_TYPES.SICK_INOFFICIAL.name );
                }
                
            }*/
        } else {
            _setRequestType( e );
        }
    }

    const _maxDateHandler = () => {
        var d = new Date( startDate );
        if( !sickChildClaim || sickChildClaim.claim <= 0 || sickChildClaim.claimLeft <= 0 ) { return null; }
        d.setDate( d.getDate() + sickChildClaim.claimLeft );
        return formatDate( d ).split(".").reverse().join("-") ;
    }

    useEffect(function () {
        
        if (sickNotes === "" && sickNoteInputRef.current !== undefined) {
            sickNoteInputRef.current.value = ""
        }
        

        if (_requestType === LOCATION_TYPES.SICK_INOFFICIAL.name ) {
            {/*setEndDate(startDate);*/}
        }
        
        if( isSickWithoutNote ) {
            _clearSickNotes();
            setEndDate(startDate);
        }

        if( sickChildClaim === null ) {
            API.getClaim(sessionToken, new Date().getFullYear(), LOCATION_TYPES.SICK_CHILD.name ).then( response => {
                if( response.success ) {
                    setSickChildClaim( {claim: response.claim, claimLeft: response.claimLeft, claimTaken: response.claimTaken} );
                }
            });
        }

    }, [_requestType, setEndDate, sickNotes, startDate, isSickWithoutNote]);

    if( !isSickWithoutNote && !sickNotes ) {
        sickNoteContent = <div className="we-request__sick-note">
            <p>Krankenschein</p>
            <label style={{width:'57%',textAlign:'center'}}>
                {/*
                <span className="we-btn small">
                    Anhang hochladen
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                </span>
                <input
                    name="files[]"
                    type="file"
                    onChange={_changeSickNoteInput}
                    multiple
                />
                */}
                <small><i>(wird bei der Krankenkasse abgerufen)</i></small>
            </label>
        </div>;
    } else if ( (_requestType === LOCATION_TYPES.SICK.name && sickNotes || _requestType === LOCATION_TYPES.SICK_CHILD.name) && !isSickWithoutNote && sickNotes ) {
        sickNoteContent = <div className="we-request__sick-note">
                                Krankenschein
                            <button type="button" className="we-btn small remove" onClick={_clearSickNotes}>
                                    {sickNotes && sickNotes.length > 1 ? "Anhänge entfernen" : "Anhang entfernen"}
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                                </button>
                            </div>;
    }
    if( sickChildClaim && sickChildClaim.claim > 0 && !isSickWithoutNote ) { 
        //_requestType !== LOCATION_TYPES.SICK_INOFFICIAL.name && 
        sickOnChildContent = <label className="we-checkbox">
            <span>Krank auf Kind</span>
            <input
                type="checkbox"
                value={LOCATION_TYPES.SICK_CHILD.name}
                checked={_requestType === LOCATION_TYPES.SICK_CHILD.name}
                onChange={e => _sickOnChildHandler(e.target.value)}
                disabled={sickChildClaim.claimLeft <= 0}
            />
            <span className="we-checkbox__input">&nbsp;</span>
        </label>;
    }
    
    return (
        <div className="we-request sick">
            <NavLink to={`${process.env.PUBLIC_URL}/antrag-hinzufuegen`} className="we-request__back-btn" onClick={() => setRequestType(null)}>
                <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                        <g transform="translate(-35.000000, -70.000000)" stroke="#FFFFFF" strokeWidth="2">
                            <g>
                                <g transform="translate(18.000000, 53.000000)">
                                    <g transform="translate(24.104000, 23.903000) rotate(180.000000) translate(-24.104000, -23.903000) translate(18.604000, 18.403000)">
                                        <line x1="0.403" y1="5.403" x2="10.403" y2="5.403" id="Linie_41" strokeLinejoin="round"></line>
                                        <polyline points="5.403 0 10.803 5.4 5.403 10.8"></polyline>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </NavLink>
            <h2>Hinzuf&uuml;gen</h2>
            <h3><b>Krankmeldung</b> einreichen</h3>
            <div>
                <label className="we-toggle">
                    <input type="checkbox" value={isSickWithoutNote} onChange={() => setIsSickWithoutNote(!isSickWithoutNote)} />
                    <span>
                        <span>&nbsp;</span>
                    </span>
                    <p style={{textAlign:'left'}}>Ich habe keine Krankschreibung vom Arzt.</p>
                </label>
                <p className="we-request__note">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
                    {isSickWithoutNote ? "Bei mehr als 3-tägiger Abwesenheit ist eine Krankschreibung vom Arzt notwendig. Sobald du einen Krankenschein hast, reiche diese schnellstmöglich nach." : "Bitte gib bei Start- und Enddatum die Daten an, die auf dem Krankenschein stehen." }
                </p>
            </div>
            <form onSubmit={_handleSubmit}>
                {sickNoteContent}
                {sickOnChildContent}
                <label>
                    Startdatum
                    <input
                        type="date"
                        className="we-input"
                        name="startDate"
                        value={formatDate(startDate, "yyyy-mm-dd")}
                        onChange={(e) => _setStartDateHandler(e.target.value)}
                    />
                </label>
                {isSickWithoutNote ? "" : 
                    <label>
                        Enddatum
                        <input
                            type="date"
                            className="we-input"
                            name="endDate"
                            value={formatDate(endDate, "yyyy-mm-dd")}
                            onChange={(e) => _setEndDateHandler(e.target.value)}
                            max={_maxDateHandler()}
                            disabled={false}
                        />
                    </label>
                }
                <button className="we-btn" type="submit">Einreichen</button>
            </form>
        </div>
    )
}