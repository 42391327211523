export function register() {
  let swURL = `${process.env.PUBLIC_URL}/serviceWorker.js`;
  navigator.serviceWorker.register(swURL);
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        registration.unregister();
      })
      .catch(error => {
        console.error(error.message);
      });
  }
}