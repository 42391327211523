import React from "react";
import { NavLink } from "react-router-dom";


export default function Snackbar({ snackbar, closeSnackbar }) {
    var snackbarLink = "";
    const isFunction = (functionToCheck) => {
        return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
    }

    if( snackbar.link ) {
        if( isFunction( snackbar.link ) ) {
            snackbarLink = 
                <div className="we-snackbar__action is--confirm">
                    <button onClick={() => closeSnackbar(snackbar.id)} className="we-snackbar__link we-btn small">{snackbar.declineText}</button>
                    <button onClick={() => {snackbar.link(); closeSnackbar(snackbar.id)}} className="we-snackbar__link we-btn small">{snackbar.linkTitle}</button>
                </div>;
        } else {
            snackbarLink = <div className="we-snackbar__action"><NavLink to={`${process.env.PUBLIC_URL}/${snackbar.link}`} onClick={() => closeSnackbar(snackbar.id)} className="we-snackbar__link we-btn small">{snackbar.linkTitle} <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></NavLink></div>;
        }
    }
    return (
        <li key={`WE_SNACKBAR_${snackbar.id}`}className={`we-snackbar ${snackbar.type ? snackbar.type.toLowerCase() : ""}`}>
            { isFunction( snackbar.link ) ? "" : 
                <button className="we-snackbar__close-btn" onClick={() => closeSnackbar(snackbar.id)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                </button>
            }
            { snackbar.title !== "" ? <p className="we-snackbar__title">{snackbar.title}</p> : ""}
            { snackbar.message !== "" ? <p className="we-snackbar__message">{snackbar.message}</p> : ""}
            {snackbarLink}
        </li>
    );
}