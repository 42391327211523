import React, {useState,useContext} from "react";
import { GoogleLogin } from 'react-google-login';
import API from '../classes/API';
import logo from './../assets/icons/we-office-logo.png'; // Tell webpack this JS file uses this image

import { AppStateContext } from './../components/AppStateProvider';

const CLIENT_ID = "455752339862-q9h5p2q0mifg9jmej38j9b7cj3af45ma.apps.googleusercontent.com";

export default function Login({setSessionToken}) {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loginWithUsername, setLoginWithUsername] = useState( false );
    const { AddSnackbar } = useContext(AppStateContext);

    const _submitHandler = (e) => {
        e.preventDefault();
        if( username.trim() === "") { AddSnackbar("Hoppla! ⚠️", "Die eingegebenen Benutzerdaten sind nicht korrekt.", "warning"); return; }
        if( password.trim() === "") { AddSnackbar("Hoppla! ⚠️", "Die eingegebenen Benutzerdaten sind nicht korrekt.", "warning");; return; }
        userLogin( username, password );
    }

    const userLogin = (username, password) => {
        API.userLogin(username, password, "", false).then(response => {
            if( response.success ) {
                setSessionToken( response );
            } else {
                AddSnackbar("Hoppla! ⚠️", "Die eingegebenen Benutzerdaten sind nicht korrekt.", "warning");
            }
        });
    }

    const responseGoogleSuccess = (response) => {
        API.userLogin("", "", response.tokenId, true).then(response => {
            if( response.success ) {
                if( response.authURL ) {
                    setSessionToken( response, window.open(response.authURL) );
                } else {
                    setSessionToken( response );
                }
            } else {

            }
            
        });
    }

    const responseGoogleFailure = (response) => {
        AddSnackbar("Hoppla! ⚠️", "Da ist wohl etwas schief gelaufen.", "warning")
    }
    return(
        <div className="we-login">
            <img className="we-login__logo" src={logo} alt="Logo" title="WE-Office" />
            {!loginWithUsername ?
                <>
                    <h2>Hi <span role="img" aria-label="Wink Emoji">👋🏻</span></h2>
                    <p>Wie m&ouml;chtest du dich anmelden?</p>
                    <button className="we-btn small" onClick={() => setLoginWithUsername( true )}>Mit Benutzername anmelden</button>
                </>
                : ""
            }
            {!loginWithUsername ? 
                    <>
                        {/* @todo 2023-01-23 remove code
                        <GoogleLogin
                         clientId={CLIENT_ID}
                         buttonText="Mit Google Anmelden"
                         onSuccess={responseGoogleSuccess}
                         onFailure={responseGoogleFailure}
                         cookiePolicy={'single_host_origin'}
                         className="we-btn small"
                        />
                        */}
                        {/* @todo client id auslagern in Konstante */}
                        <div id="g_id_onload" 
                         class="weo-login-google"
                         data-client_id="188233611511-8us4eq1l11cmbg4q5lrlo9gm2aj8re5v.apps.googleusercontent.com"
                         data-callback="handleCredentialResponse"
                         data-auto_prompt="false"></div>
                        <div class="g_id_signin"
                         data-type="standard"
                         data-size="large"
                         data-theme="outline"
                         data-text="sign_in_with"
                         data-shape="rectangular"
                         data-logo_alignment="center"></div>        
                    </>
            : ""
            }
            {loginWithUsername ?
                <>
                    <form onSubmit={(e) => _submitHandler(e)}>
                        <h2>Login</h2>
                        <p>&nbsp;</p>
                        <input 
                            type="text"
                            className="we-input"
                            value={username}
                            placeholder="Benutzername"
                            onChange={e => setUsername(e.target.value)}
                        />
                        <input 
                            type="password"
                            className="we-input"
                            value={password}
                            placeholder="Passwort"
                            onChange={e => setPassword(e.target.value)}
                        />
                        <button className="we-btn" type="submit">Anmelden</button>
                    </form>
                    <button className="we-a" onClick={() => setLoginWithUsername( false ) }>Andere Anmelde-Methode</button>
                </>
            : ""
            }
        </div>
    )
}
