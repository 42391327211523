
import React, { useState, useEffect } from "react";

export default function ProfileEditPhoneNumber({sessionToken, API}) {
    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [newPhoneNumber, setNewPhoneNumber] = useState("");
    var selectablePhoneNumbers = <li className="is--empty">Du hast noch keine Telefonummer hinzugefügt.</li>;

    const _changeActivePhoneNumberHandler = (phoneNumberID) => {
        API.activatePhoneNumber(sessionToken, phoneNumberID).then(response => {
            if( response.success ) {
                setPhoneNumbers( phoneNumbers.filter( phoneNumber => {
                    if( parseInt( phoneNumber.id ) === parseInt( phoneNumberID ) ) {
                        phoneNumber.active = true;
                    } else {
                        phoneNumber.active = false;
                    }
                    return phoneNumber;
                } ) );
            }
        });
    }

    const _addNewPhoneNumberHandler = ( e ) => {
        e.preventDefault();
        if( newPhoneNumber.trim() === "" ) { return } 
        API.addPhoneNumber(sessionToken, newPhoneNumber).then(response => {
            if( response.success ) {
                setPhoneNumbers([...phoneNumbers, {id: response.newPhoneNumberId, phonenumber: newPhoneNumber}], setNewPhoneNumber( "" ) );
            }
        });
    }

    const deletePhoneNumber = ( phoneNumberID = null ) => {
        API.deletePhoneNumber(sessionToken, phoneNumberID).then(response => {
            if( response.success ) {
                setPhoneNumbers( phoneNumbers.filter( phoneNumber => phoneNumber.id !== phoneNumberID ) );
            }
        });
    }

    useEffect(function() {
        API.getPhoneNumbers(sessionToken).then(response => {
            if( response.success ) {
                setPhoneNumbers( response.phoneNumbers );
            }
        });
    }, [API, sessionToken]);

    if( phoneNumbers.length > 0 ) {
        selectablePhoneNumbers = phoneNumbers.map(phoneNumber => {
            return <li key={phoneNumber.id}>
                <label className="we-checkbox">
                    <input 
                        type="radio"
                        value={phoneNumber.id}
                        checked={phoneNumber.active}
                        onChange={() => _changeActivePhoneNumberHandler( phoneNumber.id )}
                    />
                    <span className="we-checkbox__input">&nbsp;</span>
                </label>
                <p>{phoneNumber.phonenumber}</p>
                <button className="we-btn small" onClick={() => deletePhoneNumber(phoneNumber.id)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                </button>
            </li>
        })
    }

    return(
        <div className="we-profile-edit-phone">
            <ul>{selectablePhoneNumbers}</ul>
            <hr/>
            <form className="we-profile-edit-phone__new-phone-number" onSubmit={_addNewPhoneNumberHandler}>
                <p>Nummer Hinzufügen</p>
                <div>
                    <label>
                        <input
                            className="we-input"
                            type="number"
                            placeholder="Neue Telefonnummer"
                            value={newPhoneNumber}
                            onChange={(e) => setNewPhoneNumber(e.target.value)}
                        />
                    </label>
                    <button className="we-btn small">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                    </button>
                </div>
            </form>
        </div>
    )
}