import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, useMatch } from "react-router-dom";

import CalendarWeekSelect from './../components/CalendarWeekSelect';
import EmployeeFilter from './../components/EmployeeFilter';
import LocationCard from './../components/LocationCard';
import HelpLink from './../components/HelpLink';

import LocationDayDetails from './../pages/LocationDayDetails';

import { AppStateContext } from './../components/AppStateProvider';


import API from '../classes/API';
import LOCATION_TYPES from "../classes/LOCATION_TYPES";
import Loading from "../components/Loading";
import Calendar from "../classes/Calendar";
import PlanningView from './PlanningView';

const CALENDAR = new Calendar();

export default function Dashboard({ sessionToken, setSessionToken, teams, id }) {
    const { snackbarsVisible } = useContext(AppStateContext);
    const [isLoading, setIsLoading] = useState(true);
    const [weekData, setWeekData] = useState(null);
    const [userData, setUserData] = useState(null);
    const [userInformations, setUserInformations] = useState(null);
    const [calendarWeek, setCalendarWeek] = useState(CALENDAR.getCalendarWeek());
    const [selectedDay, setSelectedDay] = useState(`${CALENDAR.getCurrentDate('yyyy-mm-dd')}`); //useState(`${CALENDAR.getCurrentDate('yyyy-mm-dd')}`);
    const [teamIDFilter, setTeamIDFilter] = useState(teams[0] ? teams[0].id : "");
    const [employeeNameFilter, setEmployeeNameFilter] = useState("");
    const [selectedLocationType, setSelectedLocationType] = useState(null);

    const _formatWeekData = (data = null) => {
        var formatedData = data;
        if (formatedData === null) { return null; }
        if (!formatedData.hasOwnProperty("weekData")) { return null; }
        if (!formatedData.weekData.hasOwnProperty(calendarWeek.number)) { return null; }

        return formatedData.weekData;
    }

    const _formatUserData = (data = null) => {
        var formatedData = data;
        if (formatedData === null) { return null; }
        if (!formatedData.hasOwnProperty("userData")) { return null; }
        return formatedData.userData;
    }

    const teamIdFilterHandler = (value) => {
        setTeamIDFilter( value );
    }

    const _syncWithGoogle = () => {
        API.syncWithGoogle(sessionToken.token).then(response => {
            if (response.data.hasOwnProperty("authURL")) {
                if (response.data.authURL !== null) {
                    window.location.href = response.data.authURL;
                }
            }
        });
    }

    function isGoogleAuthCodeAvailable(sessionToken) {
        var searchMatch = window.location.href.match(/(?=code=)(.*?)(?=&)/g);
        var googleAuthCode = null;
        if (!searchMatch) { return false; }
        if (searchMatch.length <= 0) { return false; }
        googleAuthCode = searchMatch[0].replace("code=", "");
        if (!googleAuthCode) { return false; }
        API.setGoogleAccessToken(sessionToken.token, googleAuthCode).then(response => {
            if (response.success && sessionToken.gl ) {
                _syncWithGoogle();
            }
        });
        return true;
    }

    useEffect(() => {
        isGoogleAuthCodeAvailable(sessionToken);
        API.getWeeks(sessionToken.token, teamIDFilter, selectedDay).then(request => {
            if (request !== null) {
                setWeekData(
                    _formatWeekData(request.data),
                    setUserData(
                        _formatUserData(request.data),
                        setUserInformations(
                            request.data.userInformation,
                            setIsLoading(false)
                        )
                    )
                );
            }
        });

        if( sessionToken.gl ) {
            _syncWithGoogle();
        }

    }, [sessionToken, calendarWeek, teamIDFilter, selectedDay]);

    if (weekData === null || isLoading) {
        return (
            <div className={`we-dashboard ${snackbarsVisible ? "blur" : ""}`}>
                <CalendarWeekSelect selectedDay={selectedDay} setSelectedDay={setSelectedDay} _calendar={CALENDAR} />
                <EmployeeFilter API={API} sessionToken={sessionToken.token} teamIDFilter={teamIDFilter} setTeamIDFilter={teamIdFilterHandler} setEmployeeNameFilter={setEmployeeNameFilter} employeeNameFilter={employeeNameFilter} />
                <Loading />

            </div>
        )

    } else {
        var employees = {};
        employees[LOCATION_TYPES.OFFICE.name] = [];
        employees[LOCATION_TYPES.HOMEOFFICE.name] = [];
        employees[LOCATION_TYPES.VACATION.name] = [];
        employees[LOCATION_TYPES.SICK.name] = [];
        employees[LOCATION_TYPES.OUTOFOFFICE.name] = [];
        if (weekData.hasOwnProperty(calendarWeek.number)) {
            if (weekData[calendarWeek.number].hasOwnProperty(selectedDay)) {
                weekData[calendarWeek.number][selectedDay] = weekData[calendarWeek.number][selectedDay].map(employee => {
                    if( employee.hasOwnProperty("userId") ) {
                        if( userInformations.hasOwnProperty(employee.userId) ) {
                            employee = {...employee, ...userInformations[employee.userId] };
                        }
                    }
                    return employee;
                });
            }
        }
        //console.log(selectedDay, weekData[calendarWeek.number][selectedDay] );
        if (weekData.hasOwnProperty(calendarWeek.number)) {
            if (weekData[calendarWeek.number].hasOwnProperty(selectedDay)) {
                employees[LOCATION_TYPES.OFFICE.name] = weekData[calendarWeek.number][selectedDay].filter(employee => employee.type === LOCATION_TYPES.OFFICE.filterName);
                employees[LOCATION_TYPES.HOMEOFFICE.name] = weekData[calendarWeek.number][selectedDay].filter(employee => employee.type === LOCATION_TYPES.HOMEOFFICE.filterName);
                employees[LOCATION_TYPES.VACATION.name] = weekData[calendarWeek.number][selectedDay].filter(employee => (employee.type === LOCATION_TYPES.VACATION.filterName || employee.type === LOCATION_TYPES.VACATION_SPECIAL.filterName));
                employees[LOCATION_TYPES.SICK.name] = weekData[calendarWeek.number][selectedDay].filter(employee => (employee.type === LOCATION_TYPES.SICK.filterName || employee.type === LOCATION_TYPES.SICK_INOFFICIAL.filterName || employee.type === LOCATION_TYPES.SICK_CHILD.filterName));
                employees[LOCATION_TYPES.OUTOFOFFICE.name] = weekData[calendarWeek.number][selectedDay].filter(employee => employee.type === LOCATION_TYPES.OUTOFOFFICE.filterName || employee.type === LOCATION_TYPES.NOWORK.filterName);
            }
        }

        if (employeeNameFilter) {
            if (employeeNameFilter.split(" ")[1]) {
                employees[LOCATION_TYPES.OFFICE.name] = employees[LOCATION_TYPES.OFFICE.name].filter(employee => (employee.firstname.toLowerCase().includes(employeeNameFilter.split(" ")[0].toLowerCase()) || employee.firstname.toLowerCase().includes(employeeNameFilter.split(" ")[1].toLowerCase())));
                employees[LOCATION_TYPES.HOMEOFFICE.name] = employees[LOCATION_TYPES.HOMEOFFICE.name].filter(employee => (employee.firstname.toLowerCase().includes(employeeNameFilter.split(" ")[0].toLowerCase()) || employee.firstname.toLowerCase().includes(employeeNameFilter.split(" ")[1].toLowerCase())));
                employees[LOCATION_TYPES.VACATION.name] = employees[LOCATION_TYPES.VACATION.name].filter(employee => (employee.firstname.toLowerCase().includes(employeeNameFilter.split(" ")[0].toLowerCase()) || employee.firstname.toLowerCase().includes(employeeNameFilter.split(" ")[1].toLowerCase())));
                employees[LOCATION_TYPES.SICK.name] = employees[LOCATION_TYPES.SICK.name].filter(employee => (employee.firstname.toLowerCase().includes(employeeNameFilter.split(" ")[0].toLowerCase()) || employee.firstname.toLowerCase().includes(employeeNameFilter.split(" ")[1].toLowerCase())));
                employees[LOCATION_TYPES.OUTOFOFFICE.name] = employees[LOCATION_TYPES.OUTOFOFFICE.name].filter(employee => (employee.firstname.toLowerCase().includes(employeeNameFilter.split(" ")[0].toLowerCase()) || employee.firstname.toLowerCase().includes(employeeNameFilter.split(" ")[1].toLowerCase())));

            } else {
                employees[LOCATION_TYPES.OFFICE.name] = employees[LOCATION_TYPES.OFFICE.name].filter(employee => (employee.firstname.toLowerCase().includes(employeeNameFilter.toLowerCase().trim()) || employee.lastname.toLowerCase().includes(employeeNameFilter.toLowerCase().trim())));
                employees[LOCATION_TYPES.HOMEOFFICE.name] = employees[LOCATION_TYPES.HOMEOFFICE.name].filter(employee => (employee.firstname.toLowerCase().includes(employeeNameFilter.toLowerCase().trim()) || employee.lastname.toLowerCase().includes(employeeNameFilter.toLowerCase().trim())));
                employees[LOCATION_TYPES.VACATION.name] = employees[LOCATION_TYPES.VACATION.name].filter(employee => (employee.firstname.toLowerCase().includes(employeeNameFilter.toLowerCase().trim()) || employee.lastname.toLowerCase().includes(employeeNameFilter.toLowerCase().trim())));
                employees[LOCATION_TYPES.SICK.name] = employees[LOCATION_TYPES.SICK.name].filter(employee => (employee.firstname.toLowerCase().includes(employeeNameFilter.toLowerCase().trim()) || employee.lastname.toLowerCase().includes(employeeNameFilter.toLowerCase().trim())));
                employees[LOCATION_TYPES.OUTOFOFFICE.name] = employees[LOCATION_TYPES.OUTOFOFFICE.name].filter(employee => (employee.firstname.toLowerCase().includes(employeeNameFilter.toLowerCase().trim()) || employee.lastname.toLowerCase().includes(employeeNameFilter.toLowerCase().trim())));
            }
        }

        return (
            <Routes>
                <Route path="planungs-uebersicht" element={<PlanningView API={API} sessionToken={sessionToken.token} setSessionToken={setSessionToken} teams={teams} teamIDFilter={teamIDFilter} setTeamIDFilter={setTeamIDFilter} />} />
                    
                <Route path="/" element={
                    <>
                        <div className="we-dashboard" >
                            <CalendarWeekSelect selectedDay={selectedDay} setSelectedDay={setSelectedDay} setCalendarWeek={setCalendarWeek} calendarWeek={calendarWeek} _calendar={CALENDAR} />
                            <EmployeeFilter API={API} sessionToken={sessionToken.token} teamIDFilter={teamIDFilter} setTeamIDFilter={setTeamIDFilter} setEmployeeNameFilter={setEmployeeNameFilter} employeeNameFilter={employeeNameFilter} />
                            <ul className="we-dashboard__location-list">
                                {employees[LOCATION_TYPES.OFFICE.name].length > 0 ? <LocationCard key={1} locationName={LOCATION_TYPES.OFFICE.label} locationType={LOCATION_TYPES.OFFICE.name} employees={employees[LOCATION_TYPES.OFFICE.name]} setSelectedLocationType={setSelectedLocationType} userData={userData} selectedDay={selectedDay} calendar={CALENDAR} userInformations={userInformations} /> : ""}
                                {employees[LOCATION_TYPES.HOMEOFFICE.name].length > 0 ? <LocationCard key={2} locationName={LOCATION_TYPES.HOMEOFFICE.label} employees={employees[LOCATION_TYPES.HOMEOFFICE.name]} setSelectedLocationType={setSelectedLocationType} locationType={LOCATION_TYPES.HOMEOFFICE.name} userData={userData} selectedDay={selectedDay} calendar={CALENDAR} userInformations={userInformations} /> : ""}
                                {employees[LOCATION_TYPES.VACATION.name].length > 0 ? <LocationCard key={3} locationName={LOCATION_TYPES.VACATION.label} employees={employees[LOCATION_TYPES.VACATION.name]} setSelectedLocationType={setSelectedLocationType} locationType={LOCATION_TYPES.VACATION.name} userData={userData} selectedDay={selectedDay} calendar={CALENDAR} userInformations={userInformations} /> : ""}
                                {employees[LOCATION_TYPES.SICK.name].length > 0 ? <LocationCard key={4} locationName={LOCATION_TYPES.SICK.label} employees={employees[LOCATION_TYPES.SICK.name]} setSelectedLocationType={setSelectedLocationType} locationType={LOCATION_TYPES.SICK.name} userData={userData} selectedDay={selectedDay} calendar={CALENDAR} userInformations={userInformations} /> : ""}
                                {employees[LOCATION_TYPES.OUTOFOFFICE.name].length > 0 ? <LocationCard key={5} locationName={LOCATION_TYPES.OUTOFOFFICE.label} employees={employees[LOCATION_TYPES.OUTOFOFFICE.name]} setSelectedLocationType={setSelectedLocationType} locationType={LOCATION_TYPES.OUTOFOFFICE.name} userData={userData} selectedDay={selectedDay} calendar={CALENDAR} userInformations={userInformations} /> : ""}
                            </ul>
                            <HelpLink />
                        </div>
                    </>
                }/>
                <Route path="/:locType" element={<LocationDayDetails userData={userData} selectedDay={selectedDay} calendarWeek={calendarWeek} employees={employees[selectedLocationType]} setSelectedLocationType={setSelectedLocationType} selectedLocationType={selectedLocationType} userInformations={userInformations} />} />
            </Routes>

        );
    }
}