class Month {
    constructor(params = {}) {
        this.id = params.id !== undefined ? params.id : null;
        this.name = params.name !== undefined ? params.name : null;
        this.digit = params.digit !== undefined ? params.digit : null;
        this.days = params.days !== undefined ? params.days : [];
    }
}

class Day {
    constructor(params = {}) {
        this.id = params.id !== undefined ? params.id : null;
        this.name = params.name !== undefined ? params.name : null;
        this.shortName = params.shortName !== undefined ? params.shortName : null;
        this.digit = params.digit !== undefined ? params.digit : null;
        this.month = params.month !== undefined ? params.month : null;
        this.year = params.year !== undefined ? params.year : null;
        this.calendarWeek = params.calendarWeek !== undefined ? params.calendarWeek : null;
    }
}

export default class Calendar {
    constructor(year = null) {
        this.date = new Date();
        this.content = this._createCalendar(year);

        Date.prototype.getCalendarWeekNumber = function () {
            var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
            var dayNum = d.getUTCDay() || 7;
            d.setUTCDate(d.getUTCDate() + 4 - dayNum);
            var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
            console.log("yearStart", yearStart);
            return Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
        };
    }

    getCurrentDate(format = "") {
        var date = new Date();

        switch (format.toLowerCase()) {
            case "yyyy-m-d":
                date = `${this.getCurrentYear()}-${this.getCurrentMonth()}-${this.getCurrentDay()}`;
                break;
            case "yyyy-mm-dd":
                date = `${this.getCurrentYear()}-${this._leadingZero(this.getCurrentMonth())}-${this._leadingZero(this.getCurrentDay())}`;
                break;
            default:
                date = `${this.getCurrentYear()}-${this._leadingZero(this.getCurrentMonth())}-${this._leadingZero(this.getCurrentDay())}`;
                break;
        }
        return date;
    }

    getCurrentDay() {
        return this.date.getDate();
    }

    getWeekday(year, month, day) {
        var dateObj = new Date(`${year}-${this._leadingZero(month)}-${this._leadingZero(day)}`);
        return dateObj.toLocaleString("default", { weekday: "long" });
    }

    getWeekdayByDate(date) {
        var dateObj = new Date(date);
        return dateObj.toLocaleString("default", { weekday: "long" });
    }

    getWeekdayShort(year, month, day) {
        var dateObj = new Date(`${year}-${this._leadingZero(month)}-${this._leadingZero(day)}`);
        return dateObj.toLocaleString("default", { weekday: "short" });
    }

    getMonthName(month) {
        var months = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
        return months[(month - 1)];
    }

    getMonths() {
        return [{ id: 1, name: "Januar", number: "01" }, { id: 2, name: "Februar", number: "02" }, { id: 3, name: "März", number: "03" }, { id: 4, name: "April", number: "04" }, { id: 5, name: "Mai", number: "05" }, { id: 6, name: "Juni", numbber: "06" }, { id: 7, name: "Juli", number: "07" }, { id: 8, name: "August", number: "08" }, { id: 9, name: "September", number: "09" }, { id: 10, name: "Oktober", number: "10" }, { id: 11, name: "November", number: "11" }, { id: 12, name: "Dezember", number: "12" }];
    }

    getCurrentMonth() {
        return this.date.getMonth() + 1;
    }

    getCurrentYear() {
        return this.date.getFullYear();
    }

    isToday( date ) {
        const today = new Date()
        const someDate = new Date(date)
        return someDate.getDate() === today.getDate() &&
            someDate.getMonth() === today.getMonth() &&
            someDate.getFullYear() === today.getFullYear();
    }

    getCalendarWeekNumber(year = this.getCurrentYear(), month = this.getCurrentMonth(), day = this.getCurrentDay()) {
        var d = new Date(`${parseInt(year)}-${this._leadingZero(parseInt(month))}-${this._leadingZero(parseInt(day))}`);
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        var dayNum = d.getUTCDay() || 7;
        d.setUTCDate(d.getUTCDate() + 4 - dayNum);
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    }

    getCalendarWeek(year = this.getCurrentYear(), month = this.getCurrentMonth(), day = this.getCurrentDay()) {
        return {
            number: this.getCalendarWeekNumber(year, month, day),
            ...this.getWeekRange(year, month, day)
        }
    }

    getWeekRange(year = this.getCurrentYear(), month = this.getCurrentMonth(), day = this.getCurrentDay()) {
        var weekNumber = this.getCalendarWeekNumber(year, month, day);
        var week = this.content[parseInt(year)][parseInt(month)].days.filter(day => day.calendarWeek === weekNumber);
        return { firstDate: week[0], lastDate: week[(week.length - 1)] };
    }

    getDaysInMonth(month = this.getCurrentMonth(), year = this.getCurrentYear()) {
        return new Date(year, month, 0).getDate();
    }

    getAllDays() {
        var days = [];
        for (var year in this.content) {
            for (var month in this.content[year]) {
                this.content[year][month].days.forEach(day => {
                    days.push(day);
                });
            }
        }
        return days;
    }

    formatDate(date) {
        date = date.split("-").reverse();
        date.pop();
        return date.join(".") + ".";
    }

    diffDaysBetweenDates(firstDate, secondDate) {
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        firstDate.setHours(0, 0, 0, 0);
        secondDate.setHours(0, 0, 0, 0);
        if (firstDate === null || secondDate === null) return 0;
        return Math.round((firstDate - secondDate) / oneDay);
    }

    getFirstDateOfCalendarWeek(calendarWeekNumber = this.getCalendarWeekNumber(), year = this.getCurrentYear()) {
        var week = [];
        for (var month in this.content[year]) {
            week = this.content[year][month].days.filter(day => day.calendarWeek === calendarWeekNumber);

            if (week.length > 0) {
                return `${week[0].year}-${week[0].month}-${week[0].digit}`;
            }
        }

        return null;
    }

    _leadingZero(number) {
        return ('0' + (number)).slice(-2);
    }

    _createCalendar(year = this.getCurrentYear()) {
        if( !year ) { year = this.getCurrentYear() }
        var calendar = {};
        var dayPosition = new Date(year + "-01-01").getDay();
        var calendarWeek = 1;
        var lastCalenderWeekPreviousYear = this.getCalendarWeekNumber((year - 1), 12, 31);

        if (dayPosition === 0) {
            dayPosition = 7;
        }
        if (dayPosition !== 1) {
            calendarWeek = lastCalenderWeekPreviousYear;
        }
        calendar[year] = {};
        for (var month = 1; month < 13; month++) {
            var numberOfDays = this.getDaysInMonth(month, year);
            calendar[year][month] = new Month({ id: month, name: this.getMonthName(month), digit: this._leadingZero(month) });
            for (var day = 1; day < (numberOfDays + 1); day++) {
                var date = new Date(`${year}-${this._leadingZero(month)}-${this._leadingZero(day)}`);
                if (dayPosition > 7 || date.getDay() === 1) {
                    calendarWeek++;
                    if (lastCalenderWeekPreviousYear > 1 && month === 1 && calendarWeek > lastCalenderWeekPreviousYear) {
                        calendarWeek = 1;
                    }
                    dayPosition = 1;
                }
                calendar[year][month].days.push(new Day({ id: day, name: this.getWeekday(year, month, day), shortName: this.getWeekdayShort(year, month, day), digit: this._leadingZero(day), month: this._leadingZero(month), year: year, calendarWeek: calendarWeek }));
                dayPosition++;
            }
        }
        return calendar;
    }
}

window.Calendar = Calendar;