import React, { useState, useEffect, useContext } from "react";
import { Redirect, Routes, Route, NavLink, useMatch } from "react-router-dom";
import Select from 'react-select/creatable';

import Loading from './../components/Loading';

import { AppStateContext } from './../components/AppStateProvider';

import API from './../classes/API';
import EmployeeDetails from "./EmployeeDetails";
//createUser

export default function Employee({sessionToken}) { 
    const [isLoading, setIsLoading] = useState( false );

    if( isLoading ) {
        return(
            <Loading/>
        )
    }

    return (
        <Routes>
            <Route path="/" element={
                <section className="we-employee">
                    <h2>Hinzuf&uuml;gen</h2>
                </section>
            } 
            />
            <Route path="/:employeeId" element={<EmployeeDetails sessionToken={sessionToken} />} />
        </Routes>
    )
}