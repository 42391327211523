import React, { useState, useEffect, useRef } from "react";

import EmployeeFilter from './../components/EmployeeFilter';

import LOCATION_TYPES from "../classes/LOCATION_TYPES";
import Loading from "../components/Loading";
import Calendar from "../classes/Calendar";

var CALENDAR;

export default function PlanningView({ API, sessionToken, setSessionToken, teams, id, teamIDFilter, setTeamIDFilter }) {
    const [isLoading, setIsLoading] = useState(true);
    const [isOfficeType] = useState(false);
    const [isVacationType, setIsVacationType] = useState(true);
    const [isHomeofficeType, setIsHomeofficeType] = useState(true);
    const [isOutOfOffice, setIsOutOfOffice] = useState(true);
    const [isSickType, setIsSickType] = useState(true);
    const [employees, setEmployees] = useState([]);
    const [employeeNameFilter, setEmployeeNameFilter] = useState("");
    const [selectedYear, setSelectedYear] = useState( new Date().getFullYear() );

    CALENDAR = new Calendar(selectedYear);

    var locationTypeContent = [];
    var daysContent = [];
    var monthContent = [];
    const myRef = useRef(null);
    var isPastToday = false;

    const _scrollToSelectedDay = () => {
        
        if( myRef.current === null ) { return; }
        myRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    }

    for (var locationType in LOCATION_TYPES) {
        if (!LOCATION_TYPES[locationType].hide) { 
            locationTypeContent.push(<div>
                <p>{LOCATION_TYPES[locationType].label}</p>
            </div>);
        }
    }

    for(var year in CALENDAR.content) {
        for( var month in CALENDAR.content[year] ) {
            daysContent = [];
            CALENDAR.content[year][month].days.map( day => {
                var userContent = [];

                if( CALENDAR.isToday( year+"-"+CALENDAR._leadingZero(month)+"-"+day.digit ) || CALENDAR.getCurrentYear() !== selectedYear ) {
                    isPastToday = true;
                }

                if( isPastToday ) {
                    daysContent.push(
                        <li ref={CALENDAR.isToday( selectedYear+"-"+CALENDAR._leadingZero(month)+"-"+day.digit ) ? myRef : null}>
                            <div className={`we-planning-view__day${CALENDAR.isToday( selectedYear+"-"+CALENDAR._leadingZero(month)+"-"+day.digit ) ? " is--today" : ""}`}><small>{day.shortName}</small>{day.digit}</div>
                            {employees.forEach(user => {
                                if( (user.name.toLowerCase().includes(employeeNameFilter.split(" ")[0].toLocaleLowerCase()) || (employeeNameFilter.split(" ")[1] && user.name.toLowerCase().includes(employeeNameFilter.split(" ")[1].toLowerCase() ) )) ) {
                                    var employeePeriodsClass = "we-planning-view__period";
                                    Object.keys(user.timePeriods).forEach( date => {
                                        if( date === selectedYear+"-"+CALENDAR._leadingZero(month)+"-"+day.digit ) {
                                            var period = user.timePeriods[date];
    
                                            if(period.status !== "approved") {
                                                employeePeriodsClass = employeePeriodsClass+" is--muted";
                                            }
    
                                            if(period.factor === "0.50" || period.factor === 0.5) {
                                                employeePeriodsClass = employeePeriodsClass+" is--halfday";
                                            }
    
                                            if( period.isFirst ) {
                                                employeePeriodsClass = employeePeriodsClass+" is--first";
                                            }
    
                                            if( period.isLast ) {
                                                employeePeriodsClass = employeePeriodsClass+" is--last";
                                            }
                                            if( user.timePeriods[date].type ) {
                                                employeePeriodsClass += " "+period.type.toLowerCase()
                                            }
                                        }
                                    });
                                    userContent.push( <div className={employeePeriodsClass}><span></span></div> ); 
                                }
                            })}
                            {userContent}
                        </li>);
                }
            });

            if( isPastToday ) {
                monthContent.push( <li><span className="we-planning-view__month">{CALENDAR.getMonthName(month)}</span><ul className="we-planning-view__days">{daysContent}</ul></li> );
            }
        }
    }

    useEffect(() => {
        var planningTypes = [];
        if( isHomeofficeType ) {
            planningTypes.push("HOMEOFFICE");
        }

        if( isSickType ) {
            planningTypes.push("SICK");
        }

        if( isVacationType ) {
            planningTypes.push("VACATION");
        }

        if( isOutOfOffice ) {
            planningTypes.push("OUTOFOFFICE");
        }

        if( planningTypes.length <= 0 ) { return; }

        API.getPlan(sessionToken, teamIDFilter, selectedYear, planningTypes).then(res => {
            if( res.success ) {
                setIsLoading( false, setEmployees( res.data, _scrollToSelectedDay() ) );
            }
        });

        CALENDAR = new Calendar(selectedYear);

    }, [sessionToken, API, isOfficeType, isVacationType, isHomeofficeType, isSickType, teamIDFilter, isOutOfOffice, selectedYear]);

    if ( isLoading) {
        return (
            <div>
                <Loading />
            </div>
        )

    } else {
        var _employees = employees;
        if ( employeeNameFilter ) {
            _employees = _employees.filter(employee => (employee.name.toLowerCase().includes(employeeNameFilter.split(" ")[0].toLocaleLowerCase()) || (employeeNameFilter.split(" ")[1] && employee.name.toLowerCase().includes(employeeNameFilter.split(" ")[1].toLowerCase() ) )));
        }
        return(
            <div className="we-planning-view">
                <h2 className="we-title">Planung</h2>
                <EmployeeFilter API={API} sessionToken={sessionToken} teamIDFilter={teamIDFilter} setTeamIDFilter={setTeamIDFilter} setEmployeeNameFilter={setEmployeeNameFilter} employeeNameFilter={employeeNameFilter} />
                <div className="we-planning-view__types">
                    <label className="homeoffice">
                        <input 
                            type="checkbox"
                            value={true}
                            onChange={() => setIsHomeofficeType(!isHomeofficeType)}
                            checked={isHomeofficeType}
                        />
                        <b>Homeoffice</b>
                        <span>{isHomeofficeType ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg> : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye-off"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>}</span>
                    </label>
                    <label className="vacation">
                        <input 
                            type="checkbox"
                            value={true}
                            onChange={() => setIsVacationType(!isVacationType)}
                            checked={isVacationType}
                        />
                        <b>Urlaub</b>
                        <span>{isVacationType ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg> : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye-off"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>}</span>
                    </label>
                    <label className="sick">
                        <input 
                            type="checkbox"
                            value={true}
                            onChange={() => setIsSickType(!isSickType)}
                            checked={isSickType}
                        />
                        <b>Krank</b>
                        <span>{isSickType ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg> : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye-off"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>}</span>
                    </label>
                    <label className="outofoffice">
                        <input 
                            type="checkbox"
                            value={true}
                            onChange={() => setIsOutOfOffice(!isOutOfOffice)}
                            checked={isOutOfOffice}
                        />
                        <b>Out of Office</b>
                        <span>{isOutOfOffice ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg> : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye-off"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>}</span>
                    </label>
                </div>
                <div className="we-planning-view__wrapper">
                    <div className="we-select__wrapper">
                        <select 
                            className="we-select" 
                            onChange={(e) => setSelectedYear(e.target.value)}
                            value={selectedYear}
                        >
                            <option key={`FILTER_OPTION_0`} value="2020" >2020</option>
                            <option key={`FILTER_OPTION_1`} value="2021" >2021</option>
                            <option key={`FILTER_OPTION_2`} value="2022" >2022</option>
                            <option key={`FILTER_OPTION_3`} value="2023" >2023</option>
                            <option key={`FILTER_OPTION_3`} value="2024" >2024</option>
                        </select>
                        <span className="we-select__arrow">
                            <svg width="12px" height="8px" viewBox="0 0 12 8" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                                    <g transform="translate(-212.000000, -257.000000)" stroke="#FFFFFF" strokeWidth="2">
                                        <g>
                                            <g transform="translate(18.000000, 240.000000)">
                                                <g transform="translate(200.000000, 20.000000) rotate(90.000000) translate(-200.000000, -20.000000) translate(189.000000, 9.000000)">
                                                    <polyline transform="translate(12.000000, 11.000000) rotate(-90.000000) translate(-12.000000, -11.000000) " points="7.712 8.856 12 13.144 16.288 8.856"></polyline>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </span>
                    </div>
                    <ul className="we-planning-view__employees">
                        { _employees.map( user => <li>{user.imageUrl === null ? <span className="img">&nbsp;</span> : <img  className="img" src={`data:image/png;base64,${user.imageUrl}`} alt="" title="" />}<span className="employee__name">{user.name}</span></li>)}
                    </ul>
                    <div className="we-planning-view__days-wrapper">
                        <ul className="we-planning-view__months">
                            {monthContent}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
