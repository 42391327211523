import React, { useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
import Select from 'react-select/creatable';

import { AppStateContext } from './../components/AppStateProvider';

import API from './../classes/API';

//createUser

export default function AddEmployee({sessionToken}) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [staffNumber, setStaffNumber] = useState("");
    const [employeeTeams, setEmployeeTeams] = useState([]);
    const [employeeRegion, setEmployeeRegion] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [birthdate, setBirthdate] = useState("");
    const [currentVacationClaim, setCurrentVacationClaim] = useState("");
    const [lastYearVacationClaim, setLastYearVacationClaim] = useState("");
    const [sickChildClaim, setSickChildClaim] = useState("");
    const [freeWeekdays, setFreeWeekdays] = useState(["6","7"]);
    const [isRequestSuccessful, setIsRequestSuccessful] = useState(false);
    
    const { AddSnackbar } = useContext(AppStateContext);
    const [teamOptions, setTeamOptions] = useState( [] );
    const [regionOptions] = useState([{label:"Berlin",value:"1"},{label:"Niedersachsen",value:"2"},{label:"Nordrhein-Westfalen",value:"3"}]);

    const _handleSubmit = (e) => {
        e.preventDefault();
        API.createUser(
            sessionToken, 
            firstname, 
            lastname, 
            staffNumber, 
            email, 
            username, 
            password, 
            employeeTeams.map(team => team.value), 
            employeeRegion.map(region => region.value), 
            startDate, 
            endDate, 
            birthdate, 
            currentVacationClaim, 
            lastYearVacationClaim, 
            sickChildClaim, 
            freeWeekdays.map(weekDay => weekDay ) 
        ).then( response => {
            if (response.success) {
                AddSnackbar("Herzlichen Glückwunsch!", `${firstname} ${lastname} wurde erfolgreich angelegt.`);
                setIsRequestSuccessful(true);
            } else {
                AddSnackbar("Hoppla! ⚠️", "Es konnte kein Mitarbeiter angelegt werden.", "warning");
            }
        });
    }

    const removeSelectedTeam = ( teamID ) => {
        setEmployeeTeams( employeeTeams.filter(team => team.value !== teamID ) );
    }

    const _handleTeamSelect = (newValue, actionMeta ) => {
        if( actionMeta.action === "create-option" ) {
            var newTeamName = actionMeta.option.value;
            API.createTeam(sessionToken, newTeamName).then(response => {
                if( response.success ) {
                    newValue = newValue.map(team => {
                        if( team.label === newTeamName ) {
                            team.value = response.teamId;
                        }
                        return team;
                    });
                }
                setEmployeeTeams( newValue );
            });
        }
    };
    const _handleRegionSelect = (newValue) => {
        setEmployeeRegion( [newValue] );
    };
      
    const _handlerSelectFreeDays = ( e ) => {
        var newFreeWeekdays = freeWeekdays;
        if( e.target.checked ) {
            setFreeWeekdays([...freeWeekdays, e.target.value]);
        } else {
            setFreeWeekdays([...freeWeekdays.filter(weekDay => weekDay !== e.target.value )]);
        }
    }

    useEffect(function() {
        if( teamOptions.length <= 0 ) {
            API.getTeams(sessionToken).then(response => {
                if( response.success ) {
                    setTeamOptions( response.teams.map(team => {return {label: team.name, value:team.id}}) );
                }
            });
        }
    });
    
    if (isRequestSuccessful) {
        return (<Navigate push to={`${process.env.PUBLIC_URL}/dashboard/finish`} />);
    }

    return(
        <div className="we-add-employee">
            <h2>Mitarbeiter</h2>
            <p>Mitarbeiter anlegen</p>
            <h3><b>Mitarbeiter</b>anlegen</h3>
            <hr />
            <form id="up-create-user" onSubmit={_handleSubmit}>
                <label>
                    Username
                    <input
                        type="text"
                        className="we-input"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </label>
                <label>
                    Passwort
                    <input
                        type="password"
                        className="we-input"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </label>
                <label>
                    <span>Name<sup>*</sup></span>
                    <input
                        type="text"
                        className="we-input"
                        value={firstname}
                        onChange={(e) => setFirstname(e.target.value)}
                        required
                    />
                </label>
                <label>
                    <span>Nachname<sup>*</sup></span>
                    <input
                        type="text"
                        className="we-input"
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value)}
                        required
                    />
                </label>
                <label>
                    <span>Mailadresse<sup>*</sup></span>
                    <input
                        type="mail"
                        className="we-input"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                        required
                    />
                </label>
                <label>
                    Geburtstag
                    <input
                        type="date"
                        className="we-input"
                        name="birthdate"
                        value={birthdate}
                        onChange={(e) => setBirthdate(e.target.value)}
                    />
                </label>
                <label>
                    <span>Personalnummer<sup>*</sup></span>
                    <input
                        type="number"
                        className="we-input"
                        value={staffNumber}
                        onChange={(e) => setStaffNumber(e.target.value)}
                        required
                    />
                </label>
                <hr/>
                <div className="we-add-employee__teams">
                    <div className="wrapper">
                        <span>Teams wählen<sup>*</sup></span>
                        <Select 
                            value={employeeTeams}
                            options={teamOptions} 
                            isMulti
                            onChange={_handleTeamSelect}
                            required
                        />
                    </div>
                    {employeeTeams.length > 0 ?
                    <ul>
                        {employeeTeams.map((team, index) => 
                            <li key={`SELECTED_TEAM_${index}`}>
                                <button onClick={() => removeSelectedTeam(team.value)} >
                                    {team.label}
                                    <svg width="10px" height="8px" viewBox="0 0 10 8" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                                            <g id="WE_Office_iPhoneX_06_Mitarbeiter_anlegen_2" transform="translate(-92.000000, -700.000000)" stroke="#FFFFFF">
                                                <g id="Gruppe_450" transform="translate(93.000000, 700.000000)">
                                                    <line x1="8" y1="0" x2="0" y2="8" id="Linie_43"></line>
                                                    <line x1="0" y1="0" x2="8" y2="8" id="Linie_44"></line>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </button>
                            </li>)
                        }
                    </ul>
                    : ""}
                    
                </div>
                <hr/>
                <div className="we-add-employee__region">
                    <div className="wrapper">
                        <span>Region wählen<sup>*</sup></span>
                        <Select 
                            value={employeeRegion}
                            options={regionOptions} 
                            onChange={_handleRegionSelect}
                            required
                        />
                    </div>
                </div>
                <hr/>
                <label>
                    Vertragsbeginn
                    <input
                        type="date"
                        className="we-input"
                        name="startDate"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                </label>
                <label>
                    Vertragsende
                    <input
                        type="date"
                        className="we-input"
                        name="endDate"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        min={startDate}
                    />
                </label>
                <hr/>
                <label>
                    <span>Urlaubsanspruch<sup>*</sup></span>
                    <input
                        type="number"
                        className="we-input"
                        value={currentVacationClaim}
                        onChange={(e) => setCurrentVacationClaim(e.target.value)}
                        required
                    />
                </label>
                <label>
                    Vorjahresanspruch
                    <input
                        type="number"
                        className="we-input"
                        value={lastYearVacationClaim}
                        onChange={(e) => setLastYearVacationClaim(e.target.value)}
                    />
                </label>
                <label>
                    Krank auf Kind
                    <input
                        type="number"
                        className="we-input"
                        value={sickChildClaim}
                        onChange={(e) => setSickChildClaim(e.target.value)}
                    />
                </label>
                <hr/>
                <p className="we-add-employee__subline">Freie Tage</p>
                <div className="we-add-employee__days">
                    <label className="we-checkbox">
                        Mo.
                        <input
                            type="checkbox"
                            value="1"
                            checked={freeWeekdays.filter(day => day === "1").length > 0}
                            onChange={_handlerSelectFreeDays}
                        />
                        <span className="we-checkbox__input">&nbsp;</span>
                    </label>
                    <label className="we-checkbox">
                        Di.
                        <input
                            type="checkbox"
                            value="2"
                            checked={freeWeekdays.filter(day => day === "2").length > 0}
                            onChange={_handlerSelectFreeDays}
                        />
                        <span className="we-checkbox__input">&nbsp;</span>
                    </label>
                    <label className="we-checkbox">
                        Mi.
                        <input
                            type="checkbox"
                            value="3"
                            checked={freeWeekdays.filter(day => day === "3").length > 0}
                            onChange={_handlerSelectFreeDays}
                        />
                        <span className="we-checkbox__input">&nbsp;</span>
                    </label>
                    <label className="we-checkbox">
                        Do.
                        <input
                            type="checkbox"
                            value="4"
                            checked={freeWeekdays.filter(day => day === "4").length > 0}
                            onChange={_handlerSelectFreeDays}
                        />
                        <span className="we-checkbox__input">&nbsp;</span>
                    </label>
                    <label className="we-checkbox">
                        Fr.
                        <input
                            type="checkbox"
                            value="5"
                            checked={freeWeekdays.filter(day => day === "5").length > 0}
                            onChange={_handlerSelectFreeDays}
                        />
                        <span className="we-checkbox__input">&nbsp;</span>
                    </label>
                    <label className="we-checkbox">
                        Sa.
                        <input
                            type="checkbox"
                            value="6"
                            checked={freeWeekdays.filter(day => day === "6").length > 0}
                            onChange={_handlerSelectFreeDays}
                        />
                        <span className="we-checkbox__input">&nbsp;</span>
                    </label>
                    <label className="we-checkbox">
                        So.
                        <input
                            type="checkbox"
                            value="7"
                            checked={freeWeekdays.filter(day => day === "7").length > 0}
                            onChange={_handlerSelectFreeDays}
                        />
                        <span className="we-checkbox__input">&nbsp;</span>
                    </label>
                    
                </div>
                <hr/>
                <button className="we-btn">Anlegen</button>
            </form>
        </div>
    )
}