const LOCATION_TYPES = {
    OFFICE: {id: 2, label: "Office", name: "OFFICE", hide: true, filterName: "OFFICE"},
    HOMEOFFICE: {id: 2, label: "Homeoffice", name: "HOMEOFFICE", hide: false, filterName: "HOMEOFFICE"},
    VACATION: {id: 1, label: "Urlaub", name: "VACATION", hide: false, filterName: "VACATION"},
    VACATION_SPECIAL: {id: 6, label: "Sonderurlaub", name: "VACATION_SPECIAL", hide: true, filterName: "VACATION_SPECIAL"},
    SICK_INOFFICIAL: {id: 3, label: "Krankmeldung", name: "SICK_INOFFICIAL", hide: false, filterName: "SICK_INOFFICIAL"},
    SICK: {id: 4, label: "Krank", name: "SICK", hide: true, filterName: "SICK"},
    HOLIDAY: {id: 5, label: "Feiertag", name: "HOLIDAY", hide: true, filterName: "HOLIDAY"},
    OUTOFOFFICE: {id: 6, label: "Out of Office", name: "OUTOFOFFICE", hide: false, filterName: "OUTOFOFFICE"},
    NOWORK: {id: 8, label: "No Work", name: "NOWORK", hide: true, filterName: "NOWORK"},
    SICK_CHILD: {id: 7, label: "Krank auf Kind", name: "SICK_CHILD", hide: true, filterName: "SICK_CHILD"}
}

export default LOCATION_TYPES;