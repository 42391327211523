import React, { useEffect, useContext, useState } from "react";
import { Link, Routes, useNavigate, Route, useMatch, useParams } from "react-router-dom";

import RequestCard from '../components/RequestCard';
import RequestDetails from '../pages/RequestDetails';
import Loading from "../components/Loading";
import ProfileSettings from "../components/ProfileSettings";

import { AppStateContext } from './../components/AppStateProvider';

import API from './../classes/API';
//import REQUEST_STATUS from "../classes/REQUEST_STATUS";
import LOCATION_TYPES from "../classes/LOCATION_TYPES";
import ProfileEditPhoneNumber from "../components/ProfileEditPhoneNumber";
import ReleaseNotes from "../components/ReleaseNotes";

//import Calendar from '../classes/Calendar';

function RequestFilter({setShowRequestFilter, setLocationTypeFilter, setMonthFilter, locationTypeFilter, monthFilter}) {
    const [locationTypeData, setLocationTypeData] = useState( locationTypeFilter );
    const [monthData, setMonthData] = useState( monthFilter );

    const _locationTypeFilterChange = ( locationID ) => {
        var newLocationType = locationTypeData.map( (locationType, index) => {
            if( parseInt( locationType.id ) === parseInt(locationID) ) {
                locationTypeData[index].isChecked = !locationTypeData[index].isChecked;
            }
            return locationTypeData[index];
        });

        setLocationTypeData( newLocationType );
    }

    const _monthTypeFilterChange = ( monthID ) => {
        var newMonthData = monthData.map( (month, index) => {
            if( parseInt( month.id ) === parseInt(monthID) ) {
                monthData[index].isChecked = !monthData[index].isChecked;
            }
            return monthData[index];
        });

        setMonthData( newMonthData );
    }

    const applyFilter = () => {
        setLocationTypeFilter( 
            locationTypeData,
            setMonthFilter( monthData,
                setShowRequestFilter( false )
            )
        );
    }

    return(
        <div className="we-request-filter">
            <button className="we-request-filter__close" onClick={() => setShowRequestFilter( false )}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
            </button>
            <p className="we-request-filter__title">Filter <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" className="feather feather-sliders"><line x1="4" y1="21" x2="4" y2="14"></line><line x1="4" y1="10" x2="4" y2="3"></line><line x1="12" y1="21" x2="12" y2="12"></line><line x1="12" y1="8" x2="12" y2="3"></line><line x1="20" y1="21" x2="20" y2="16"></line><line x1="20" y1="12" x2="20" y2="3"></line><line x1="1" y1="14" x2="7" y2="14"></line><line x1="9" y1="8" x2="15" y2="8"></line><line x1="17" y1="16" x2="23" y2="16"></line></svg></p>
            <p>Kategorien</p>
            <ul>
                {locationTypeData.map(locationType => 
                <li key={`LOCATION_FILTER_${locationType.name}`}>
                    <label className="we-checkbox-small">
                        <input 
                            type="checkbox"
                            value={locationType.id}
                            checked={locationType.isChecked}
                            onChange={(e) => _locationTypeFilterChange(e.target.value)}
                        />
                        <span>{locationType.name}</span>
                    </label>
                </li>
            )}
            </ul>
            <p>Zeitraum</p>
            <ul>
                {monthData.map( month => <li key={`MONTH_FILTER_${month.name}`}>
                    <label className="we-checkbox-small">
                        <input 
                            type="checkbox"
                            value={month.id}
                            checked={month.isChecked}
                            onChange={(e) => _monthTypeFilterChange(e.target.value)}
                        />
                        <span>{month.name}</span>
                    </label>
                </li>
            )}</ul>
            <button className="we-btn" onClick={applyFilter}>Anwenden</button>
        </div>
    )
}

export default function Profil({ sessionToken, setSessionToken, calendar }) {
    const { AddSnackbar, snackbarsVisible } = useContext(AppStateContext);
    const [isLoading, setIsLoading] = useState(true);
    const [profile, setProfile] = useState(null);
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [showRequestFilter, setShowRequestFilter] = useState( false );
    const [locationTypeFilter, setLocationTypeFilter] = useState( Object.keys(LOCATION_TYPES).filter(type => !LOCATION_TYPES[type].hide).map( (type, index) => {return {id: index, isChecked: false, name: LOCATION_TYPES[type].label, filterName: LOCATION_TYPES[type].filterName }} ) );
    const [monthFilter, setMonthFilter] = useState( calendar.getMonths().map( month => {month["isChecked"] = false; return month;}) );
    const [showReleaseNotes, setShowReleaseNotes] = useState( false );
    var userName = "-";
    var userImage = "";
    var countFilterOptions = 0;
    let history = useNavigate();
    
    if( profile && profile.user ) {
        if( profile.user.firstname || profile.user.lastname ) {
            userName = profile.user.firstname+" "+profile.user.lastname;
        }
        if( profile.user.img ) {
            userImage = profile.user.img;//profile.user.img;
        }
    }

    const logoutHandler = (res) => {
        API.userLogout(function() {
            setSessionToken( null );
            window.location.href = "/";
        });
    }

    const formatDate = (date) => {
        date = date.split("-").reverse();
        date = date.join('.');
        return date;
    }

    const _getRequestStartEndDate = (request = null) => {
        if (request === null) return "-";
        if (request.dates.length > 1) {
            return "für den " + formatDate(request.dates[0]) + " - " + formatDate(request.dates[(request.dates.length - 1)]);
        } else {
            return "für den " + formatDate(request.dates[0]);
        }
    }

    const _deleteRequestHandler = (request, date = null ) => {
        if (
            request.type === LOCATION_TYPES.VACATION.name 
            || request.type === LOCATION_TYPES.VACATION_SPECIAL.name 
            || request.type === LOCATION_TYPES.OUTOFOFFICE.name
        ) {
            API.deleteRequest(sessionToken, request.requestDate).then(response => {
                if (response.success) {
                    setIsLoading(true );
                    API.getProfile(sessionToken, "").then(response => {
                        if (response.success) {
                            setProfile(response.data, setIsLoading(false) );
                        }
                    });
                } else {
                    alert("Dein Urlaub konnte nicht Storniert werden.");
                }
            });
        } else if (request.type === LOCATION_TYPES.HOMEOFFICE.name) {
            API.deleteHomeoffice(sessionToken, request.requestDate, date).then(response => {
                if (response.success) {
                    setIsLoading(true );
                    API.getProfile(sessionToken, "").then(response => {
                        if (response.success) {
                            setProfile(response.data, setIsLoading(false, history.push(`${process.env.PUBLIC_URL}/profil`) ) );
                        }
                    });
                } else {
                    alert("Dein Homeoffice konnte nicht Storniert werden");
                }
            });
        }
    }

    const _deleteDeniedRequest = ( date ) => {
        API.deleteDeniedHolidayRequests(sessionToken, date).then(response => {
            if( response.success ) {
                setIsLoading(true);
                API.getProfile(sessionToken, "").then(response => {
                    if (response.success) {
                        setProfile(response.data, setIsLoading(false, setSelectedRequest( null ) ) );
                    }
                });
            } else {
                alert("Der Antrag konnte nicht gelöscht werden!")
            }
        });
    }

    useEffect(() => {
        API.getProfile(sessionToken, "").then(response => {
            if (response.success) {
                setProfile(response.data, setIsLoading(false));
            }
        })

    }, [sessionToken]);

    countFilterOptions = locationTypeFilter.filter( locationType => locationType.isChecked ).length + monthFilter.filter(month => month.isChecked ).length;

    if (profile === undefined || profile === null || isLoading) {
        return (
            <div className="we-profile">
                <Loading />
            </div>
        )
    } else {
        var futureRequests = [];
        var pastRequests = [];
        var deniedRequests = [];

        if (profile.futureRequests && profile.futureRequests.length > 0) {
            futureRequests = profile.futureRequests.map((request, index) => {
                if( locationTypeFilter.filter(type => type.isChecked ).length <= 0 ) { 
                    return request;
                }
                if( locationTypeFilter.filter( type => ( type.isChecked && request.type.includes(type.filterName) ) ).length > 0 ) { 
                    return request;
                }
            });

            futureRequests = futureRequests.filter( ele => ele !== undefined );
            if( monthFilter.filter( month => month.isChecked ).length > 0 ) {
                futureRequests = futureRequests.filter( request => ( request.dates.filter( date => monthFilter.filter( month => ( month.isChecked && date.split("-")[1] === month.number ) ).length > 0 ) ).length > 0 );
            }

            //futureRequests = futureRequests.filter( request => (request.type === LOCATION_TYPES.OUTOFOFFICE.name || request.type === LOCATION_TYPES.name ));
        }

        if (profile.pastRequests && profile.pastRequests.length > 0) {
            pastRequests = profile.pastRequests.map((request, index) => {
                if( locationTypeFilter.filter(type => type.isChecked ).length <= 0 ) { 
                    return request;
                }
                if( locationTypeFilter.filter( type => ( type.isChecked && request.type.includes(type.filterName) ) ).length > 0 ) {
                    return request;
                }
            });

            pastRequests = pastRequests.filter( ele => ele !== undefined );

            if( monthFilter.filter( month => month.isChecked ).length > 0 ) {
                pastRequests = pastRequests.filter( request => ( request.dates.filter( date => monthFilter.filter( month => ( month.isChecked && date.split("-")[1] === month.number ) ).length > 0 ) ).length > 0 );
            }

            pastRequests = pastRequests.filter( request => (request.type !== LOCATION_TYPES.OUTOFOFFICE.name || request.type === LOCATION_TYPES.OUTOFOFFICE.name && request.title !== "Frei" ));

        }

        if (profile.deniedRequests && profile.deniedRequests.length > 0) {
            deniedRequests = profile.deniedRequests.map((request, index) => {
                if( locationTypeFilter.filter(type => type.isChecked ).length <= 0 ) {
                    return request;
                }
                if( locationTypeFilter.filter( type => ( type.isChecked && request.type.includes(type.filterName) ) ).length > 0 ) {
                    return request;
                }
            });

            deniedRequests = deniedRequests.filter( ele => ele !== undefined );

            if( monthFilter.filter( month => month.isChecked ).length > 0 ) {
                deniedRequests = deniedRequests.filter( request => ( request.dates.filter( date => monthFilter.filter( month => ( month.isChecked && date.split("-")[1] === month.number ) ).length > 0 ) ).length > 0 );
            }
            deniedRequests = deniedRequests.filter( request => (request.type !== LOCATION_TYPES.OUTOFOFFICE.name || request.type === LOCATION_TYPES.OUTOFOFFICE.name ));
        }

        var profileView = <div className={`we-profile ${(snackbarsVisible || selectedRequest !== null) ? "blur" : ""}`}>
                            <Link className="we-profile__logout-btn" to={`${process.env.PUBLIC_URL}/profil/settings`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
                            </Link>
                            <Link className="we-profile__info-btn" to={`${process.env.PUBLIC_URL}/hilfe`}>
                            <svg viewBox="0 0 120.568 126.799"><path d="M108.714,96.183a60.292,60.292,0,1,0-23.32,18.907l-.044.035L120.568,126.8,108.726,96.174Zm-77.4-25.262a8.966,8.966,0,1,1,8.966-8.966A8.966,8.966,0,0,1,31.309,70.921Zm28.786,0a8.966,8.966,0,1,1,8.967-8.966A8.966,8.966,0,0,1,60.1,70.921Zm28.785,0a8.966,8.966,0,1,1,8.967-8.966A8.966,8.966,0,0,1,88.88,70.921Z" fill="#fff"/></svg>
                            </Link>
                            {showRequestFilter ? <><span className="we-profile__fader"></span><RequestFilter setShowRequestFilter={setShowRequestFilter} setLocationTypeFilter={setLocationTypeFilter} setMonthFilter={setMonthFilter} locationTypeFilter={locationTypeFilter} monthFilter={monthFilter} /></> : ""}
                            <div className="we-profile__header">
                                <p className="we-profile__headline">Profil</p>
                                <p className="we-profile__name">{userName}<small>{profile.nickname ? profile.nickname : ""}</small></p>
                                <div className="we-profile__information">
                                    <div>
                                        <p>Restanspruch<b>{profile.claimLeft ? profile.claimLeft : "-"}</b></p>
                                    </div>
                                    <div>
                                        {(userImage !== "") ?
                                            <img className="we-profile__image" src={`data:image/png;base64,${userImage}`} alt="" title="" />
                                            : <span className="we-profile__image"></span>}
                                    </div>
                                    <div>
                                        <p>Unbest&auml;tigt<b>{profile.claimUnapproved ? profile.claimUnapproved : "-"}</b></p>
                                    </div>
                                </div>
                            </div>
                            <div className="we-profile__requests-wrapper">
                                {deniedRequests.length > 0 ?
                                    <div className="we-profile__requests future">
                                        <p className="we-request-title">Abgelehnt</p>
                                        <ul >
                                            {deniedRequests.map( (request, index) => <RequestCard key={`DENIED_REQUEST_${index}`} request={request} setSelectedRequest={setSelectedRequest} deleteRequest={(request) => AddSnackbar("Achtung! ☝️", "Möchtest du " + LOCATION_TYPES[request.type].label + " " + _getRequestStartEndDate(request) + " wirklich löschen?", "warning", () => {_deleteRequestHandler(request)}, "Löschen", "Abbrechen")} deleteDeniedRequest={_deleteDeniedRequest} /> )}
                                        </ul>
                                    </div>
                                    : ""}
                                {futureRequests.length > 0 ?
                                    <div className="we-profile__requests future">
                                        <p className="we-request-title">Bevorstehend</p>
                                        <ul >
                                            {futureRequests.map( (request, index) => <RequestCard key={`FUTURE_REQUEST_${index}`} request={request} setSelectedRequest={setSelectedRequest} deleteRequest={(request) => AddSnackbar("Achtung! ☝️", "Möchtest du " + LOCATION_TYPES[request.type].label + " " + _getRequestStartEndDate(request) + " wirklich löschen?", "warning", () => {_deleteRequestHandler(request)}, "Löschen", "Abbrechen")} /> )}
                                        </ul>
                                    </div>
                                    : ""}
                                {pastRequests.length > 0 ?
                                    <div className="we-profile__requests past">
                                        <p className="we-request-title">Vergangen</p>
                                        <ul >
                                            {pastRequests.map( (request, index) => <RequestCard key={`PAST_REQUEST_${index}`} request={request} past={true} setSelectedRequest={setSelectedRequest} deleteRequest={(request) => AddSnackbar("Achtung! ☝️", "Möchtest du " + LOCATION_TYPES[request.type].label + " " + _getRequestStartEndDate(request) + " wirklich löschen?", "warning", () => {_deleteRequestHandler(request)}, "Löschen", "Abbrechen")} /> )}
                                        </ul>
                                    </div>
                                    : ""}
                            </div>
                            <button className="we-btn small" onClick={() => setShowRequestFilter( true )}>
                                Filter
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" className="feather feather-sliders"><line x1="4" y1="21" x2="4" y2="14"></line><line x1="4" y1="10" x2="4" y2="3"></line><line x1="12" y1="21" x2="12" y2="12"></line><line x1="12" y1="8" x2="12" y2="3"></line><line x1="20" y1="21" x2="20" y2="16"></line><line x1="20" y1="12" x2="20" y2="3"></line><line x1="1" y1="14" x2="7" y2="14"></line><line x1="9" y1="8" x2="15" y2="8"></line><line x1="17" y1="16" x2="23" y2="16"></line></svg>
                                {countFilterOptions > 0 ? <span className="we-filter-btn-count">{countFilterOptions}</span> : ""}
                            </button>
                        </div>;

        return (
            <Routes>
                <Route path="/" element={<>
                    {selectedRequest !== null ? <RequestDetails sessionToken={sessionToken} request={selectedRequest} setSelectedRequest={setSelectedRequest} /> : ""}
                    {profileView}
                    </>}/>
                <Route path="/settings/*" element={<>
                    <span className="we-profile__fader"></span>
                    <ProfileSettings sessionToken={sessionToken} API={API} logoutHandler={logoutHandler} />
                    {profileView}
                    </>} />
                <Route path="/:requestID" element={<>
                    <span className="we-profile__fader"></span>
                    <RequestDetailsLink sessionToken={sessionToken} futureRequests={futureRequests} pastRequests={pastRequests} deniedRequests={deniedRequests} setSelectedRequest={setSelectedRequest} _deleteRequestHandler={_deleteRequestHandler} />
                    {profileView}
                    </>}/>
            </Routes>
        )
    }
}

function RequestDetailsLink({sessionToken, futureRequests, pastRequests, deniedRequests, setSelectedRequest, _deleteRequestHandler}) {
    let { requestID } = useParams();
    var request = null;
    if( request === null || request === undefined ) {
        request = futureRequests.filter( request => requestID === btoa(request.requestDate ) )[0];
    }
    if( request === null || request === undefined ) {
        request = pastRequests.filter( request => requestID === btoa(request.requestDate ) )[0];
    }
    if( request === null || request === undefined ) {
        request = deniedRequests.filter( request => requestID === btoa(request.requestDate ) )[0];
    }

    return(
        <RequestDetails sessionToken={sessionToken} request={request} _deleteRequestHandler={_deleteRequestHandler} />
    )
}
