import React, { useState, useEffect } from "react";

export default function Help({sessionToken, reportList, teams}) {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [mail, setMail] = useState("");
    const [note, setNote] = useState("");
    const [concern, setConcern] = useState("");
    const [formSuccess, setFormSuccess] = useState(false);
    const [formError, setFormError] = useState( false );

    const _submitHandler = (e) => {
        e.preventDefault();
        var formElement = document.getElementById("we-help-form");
        var formData = new FormData( formElement );
        var url = `https://showroom.ws-epic.de/we-anfragen/api/v1.0.0/`;
        var xhr = new XMLHttpRequest();
        var json = {
            Betreff: concern,
            Anmerkung: note
        };

        formData.append("json", JSON.stringify(json));
        xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
            if (xhr.status === 200) {
            var response = JSON.parse(xhr.response);
            if (response.success) {
                setFormSuccess( true );
            } else {
                setFormError( true );
            }
            }
        }
        }
        xhr.open('POST', url, true);
        xhr.send(formData);
    }

    if( formSuccess ) {
        return(
            <div className="we-help">
                <h2>Hilfe</h2>
                <p>Vielen Dank für deine Anfrage! Wir melden uns bei dir!</p>
            </div>
        )
    }

    if( formError ) {
        return(
            <div className="we-help">
                <h2>Hilfe</h2>
                <p>🤖 Bibup. ERROR. Bzzzt.</p>
            </div>
        )
    }

    return(
        <div className="we-help">
            <h2>Hilfe</h2>
            <p>Brauchst du Hilfe?<br/>Schreib uns gerne eine Nachricht und wir melden uns so schnell wie möglich bei dir.</p>
            <form onSubmit={_submitHandler} id="we-help-form">
                <input type="hidden" name="lastname" value="" />
                <input type="hidden" name="task" value="setRequest" />
                <input type="hidden" name="project" value="WE-Office" />
                <input type="hidden" name="company" value="W&S Epic." />
                <label>
                    <input
                        className="we-input"
                        type="text"
                        placeholder="Name"
                        name="firstname"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </label>
                <label>
                    <input
                        className="we-input"
                        type="number"
                        placeholder="Telefonnummer"
                        value={phone}
                        name="phone"
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </label>
                <label>
                    <input
                        className="we-input"
                        type="text"
                        placeholder="E-Mail"
                        value={mail}
                        name="email"
                        onChange={(e) => setMail(e.target.value)}
                    />
                </label>
                <div className="we-select__wrapper">
                    <select 
                        className="we-select" 
                        onChange={(e) => setConcern(e.target.value)}
                        value={concern}
                    >
                        <option key={`FILTER_OPTION_0`} value="" >Anliegen</option>
                        <option key={`FILTER_OPTION_1`} value="Frage zum Ablauf" >Frage zum Ablauf</option>
                        <option key={`FILTER_OPTION_2`} value="Frage zur Benutzung der App" >Frage zur Benutzung der App</option>
                        <option key={`FILTER_OPTION_3`} value="Frage zu meinen Daten" >Frage zu meinen Daten</option>
                        
                        
                    </select>
                    <span className="we-select__arrow">
                        <svg width="12px" height="8px" viewBox="0 0 12 8" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                                <g transform="translate(-212.000000, -257.000000)" stroke="#FFFFFF" strokeWidth="2">
                                    <g>
                                        <g transform="translate(18.000000, 240.000000)">
                                            <g transform="translate(200.000000, 20.000000) rotate(90.000000) translate(-200.000000, -20.000000) translate(189.000000, 9.000000)">
                                                <polyline transform="translate(12.000000, 11.000000) rotate(-90.000000) translate(-12.000000, -11.000000) " points="7.712 8.856 12 13.144 16.288 8.856"></polyline>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </span>
                </div>
                <label>
                    <textarea
                        className="we-input"
                        placeholder="Anmerkung"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                    ></textarea>
                </label>
                <button className="we-btn">Absenden</button>
            </form>
        </div>
    )
}