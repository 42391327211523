import React, {useState, useEffect, useContext} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { CSSTransitionGroup } from 'react-transition-group' // ES6

import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import PlanningView from './pages/PlanningView';
import Requests from './pages/Requests';
import ManageRequests from './pages/ManageRequests';
import AddEmployee from './pages/AddEmployee';
import Profil from './pages/Profil';
import Reporting from './pages/Reporting';
import Help from './pages/Help';
import Employee from './pages/Employee';

import Snackbar from './components/Snackbar';

import Calendar from './classes/Calendar';

import API from './classes/API';
import { AppStateContext } from './components/AppStateProvider';
import Navigation from "./components/Navigation";


export default function App() {
  const [sessionToken, setSessionToken] = useState(null);
  const [showedOfflineBanner] = useState(false);
  const [isLoading, setIsLoading] = useState( true );
  const [reportList, setReportList] = useState( null );
  const calendar = new Calendar();
  const { Snackbars, CloseSnackbar } = useContext(AppStateContext);


  const _ifUserIsLogedIn = () => {
    let localStorageItem = API.getStorageUser();
    if( localStorageItem === null ){
      setSessionToken( null , setIsLoading( false ) );
      return;
    }

    if( localStorageItem === null || localStorageItem.version !== API.getLocalStorageVersion() ) {
      API.userLogout();
      setSessionToken( null , setIsLoading( false ) );
      return;
    }
    setSessionToken( localStorageItem , setIsLoading( false ) );
  }

  const formatDate = ( date, stripYear = false ) => {
    date = date.split("-").reverse();
    if( stripYear ) {
      date.pop();
      return date.join(".")+".";
    } else {
      return date.join(".");
    }
  }

  const getPercent = (x, y ) => {
    if( y === 0 ) {
      return 0;
    }
    return ((x / y) * 100);
  }

  const getPercentValueOf = (percent, base ) => {
    
    return ((percent / 100) * base);
  }

  const getSessionToken = () => {
    return sessionToken;
  }

  window.formatDate = formatDate;
  window.getPercent = getPercent;
  window.getPercentValueOf = getPercentValueOf;
  window.getSessionToken = getSessionToken;

  useEffect(() => {
    if( isLoading ) {
      _ifUserIsLogedIn();
    }

    if( reportList === null && sessionToken !== null ) {
      API.getReportList( sessionToken.token ).then(response => {
        if( response.success ) {
          setReportList( response.reports );
        } else {
          setReportList( [] );
        }
      });
    }

  }, [showedOfflineBanner, isLoading, reportList, sessionToken]);

  const _setSessionToken = (token) => {
    localStorage.setItem("WSEPIC_HOLIDAY", JSON.stringify(token) );
    setSessionToken( token );
  }

  return (
    <Router>
      <>
        <CSSTransitionGroup 
          component="ul"
          className={`we-snackbars ${Snackbars.length > 0 ? "has-content" : ""}`}
          transitionName={ {
            enter: 'is--enter',
            enterActive: 'is--enter-active',
            leave: 'is--leave',
            leaveActive: 'is--leave-active',
            appear: 'is--appear',
            appearActive: 'is--appear-active'
          } }
          transitionAppear={true}
          transitionAppearTimeout={500}
          transitionEnter={true}
          transitionLeave={true}
        >
        {Snackbars.map(snackbar => <Snackbar snackbar={snackbar} closeSnackbar={CloseSnackbar} />)}
        </CSSTransitionGroup>
        <span className="we-beta-badge">Beta</span>
        {sessionToken !== null ? <Navigation sessionToken={sessionToken} reportList={reportList} /> : "" }

        { sessionToken === null ? <Login setSessionToken={_setSessionToken} />
        :
        <Routes>
          {/** 
          <Route path="/holidays">
            <HolidayList sessionToken={sessionToken.token} setSessionToken={setSessionToken} />
          </Route>
            {sessionToken.isAdmin === true ? 
              <Route path="/admin">
                <AdminList sessionToken={sessionToken.token} setSessionToken={setSessionToken} />
              </Route>
            : ""}
          <Route path="/calender">
            <HolidayCalender sessionToken={sessionToken.token} setSessionToken={setSessionToken} />
          </Route>
          
          */}
          <Route path={`${process.env.PUBLIC_URL}/reporting/*`} element={<Reporting sessionToken={sessionToken.token} reportList={reportList} />} />
          <Route path={`${process.env.PUBLIC_URL}/hilfe`} element={<Help sessionToken={sessionToken.token} />} />
          <Route path={`${process.env.PUBLIC_URL}/mitarbeiter/*`} element={<Employee sessionToken={sessionToken.token} />}/>
          <Route path={`${process.env.PUBLIC_URL}/profil/*`} element={<Profil sessionToken={sessionToken.token} setSessionToken={setSessionToken} calendar={calendar} /> }/>
          <Route path={`${process.env.PUBLIC_URL}/antrag-hinzufuegen/*`} element={<Requests sessionToken={sessionToken.token} setSessionToken={setSessionToken} /> } />
          <Route path={`${process.env.PUBLIC_URL}/mitarbeiter-hinzufuegen`} element={<AddEmployee sessionToken={sessionToken.token} setSessionToken={setSessionToken} /> }/>
          <Route path={`${process.env.PUBLIC_URL}/antraege-verwalten/*`} element={<ManageRequests sessionToken={sessionToken.token} setSessionToken={setSessionToken} /> }/>
          <Route path={`${process.env.PUBLIC_URL}/planungs-uebersicht`} element={<PlanningView sessionToken={sessionToken.token} setSessionToken={setSessionToken} teams={sessionToken.teams} />}/>
          <Route path={`${process.env.PUBLIC_URL}/dashboard/*`} element={<Dashboard sessionToken={sessionToken} setSessionToken={setSessionToken} teams={sessionToken.teams} />}/>
          <Route path={`${process.env.PUBLIC_URL}/`} element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />}/>
        </Routes>
        }
      </>
      
    </Router>
  );
}