import React from "react";
import EmployeeDetails from './../components/EmployeeDetails';
import LOCATION_TYPES from './../classes/LOCATION_TYPES';
import { Navigate } from "react-router";

export default function LocationDayDetails({userData, selectedDay, calendarWeek, employees, setSelectedLocationType, selectedLocationType, userInformations}) {
    const _formatedData = ( date ) => {
        var fDate = date.split("-").reverse();
        return fDate[0]+"."+fDate[1];
    } 

    if( !Array.isArray( employees ) ) {
        employees = [];
    } 
    employees.sort((a,b) => (a.status > b.status) ? 1 : ((b.status > a.status) ? -1 : 0));

    for( var i = 0; i < employees.length; i++ ) {
        let isBirthday = false;
        if( employees[i].birthdate && selectedDay ) {
            if( employees[i].birthdate.split("-")[1] === selectedDay.split("-")[1] && employees[i].birthdate.split("-")[2] === selectedDay.split("-")[2] ) {
                isBirthday = true;
            }
        }
        employees[i]["isBirthday"] = isBirthday;
    }
    
    employees.sort(function(a, b) {
        if( !a.hasOwnProperty("isBirthday") || !b.hasOwnProperty("isBirthday") ){ return false; }
        return (a.isBirthday === b.isBirthday)? 0 : a.isBirthday? -1 : 1;
    });

    if( selectedLocationType ) {
        return (
            <div className="we-day-details">
                <p className={`we-day-details__title ${selectedLocationType ? selectedLocationType.toLowerCase() : ""}`}>{selectedLocationType ? LOCATION_TYPES[selectedLocationType].label : ""}<small>{_formatedData( selectedDay )}</small></p>
                <button className="we-day-details__back-btn" onClick={() => setSelectedLocationType( null )}>
                    <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                            <g transform="translate(-35.000000, -70.000000)" stroke="#FFFFFF" strokeWidth="2">
                                <g>
                                    <g transform="translate(18.000000, 53.000000)">
                                        <g transform="translate(24.104000, 23.903000) rotate(180.000000) translate(-24.104000, -23.903000) translate(18.604000, 18.403000)">
                                            <line x1="0.403" y1="5.403" x2="10.403" y2="5.403" id="Linie_41" strokeLinejoin="round"></line>
                                            <polyline points="5.403 0 10.803 5.4 5.403 10.8"></polyline>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </button>
                <ul>
                    {employees.map( (employee, index) => {
                        return <li key={`EMPLOYEE_DETAILS_${index}`}><EmployeeDetails key={`USER_DETAIL_${index}`} employee={employee} calendar={userData[employee.userId]} selectedDay={selectedDay} small={true} isBirthday={employee.isBirthday} userInformations={userInformations} /></li>
                    })}
                </ul>
            </div>
        )
    } else {
        return <Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />;
    }

    
}