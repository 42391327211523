import React, { useState, useRef, useEffect } from "react";

import Calendar from "../classes/Calendar";

function CalendarDay({ day, setSelectedDay, selectedDay, calendar, myRef }) {
    const dayDate = `${parseInt(day.year)}-${calendar._leadingZero( parseInt(day.month) )}-${calendar._leadingZero( parseInt(day.digit) )}`;
    return (
        <li key={`DAY_${day.year}_${day.month}_${day.digit}`}
            className={(new Date(selectedDay).setHours(0, 0, 0, 0) === new Date(dayDate).setHours(0, 0, 0, 0)) ? "is-active" : ""}
            ref={myRef ? myRef : null}
        >
            <button onClick={() => setSelectedDay(dayDate)}>
                <small>{day.shortName}</small>
                {day.digit}
            </button>
        </li>
    )
}

export default function CalendarWeekSelect({ selectedDay, setSelectedDay, calendarWeek, setCalendarWeek, _calendar }) {
    const [calendar] = useState(_calendar || new Calendar());
    const [_calendarWeek, _setCalendarWeek] = useState(calendarWeek || calendar.getCalendarWeek());
    const [_selectedDay, _setSelectedDay] = useState(selectedDay || `${calendar.getCurrentDate('yyyy-mm-dd')}`);
    const myRef = useRef(null);

    const _scrollToSelectedDay = () => {
        if( myRef.current === null ) { return; }
        myRef.current.scrollIntoView({block: "end", behavior: "smooth"});
    }

    const _setSelectedDayHandler = (selectedDay = null ) => {
        if( selectedDay === null ) { return; }
        var date = selectedDay.split("-");
        var newCalendarWeek = calendar.getCalendarWeek(
            parseInt( date[0]), 
            calendar._leadingZero( parseInt(date[1]) ), 
            calendar._leadingZero( parseInt(date[2]) ) 
        );
        if (setSelectedDay && setCalendarWeek) {
            setSelectedDay(selectedDay,
                setCalendarWeek( newCalendarWeek,
                    _setCalendarWeek( 
                        newCalendarWeek,
                        _setSelectedDay(selectedDay)
                    )
                )
                
            );
        } else {
            _setSelectedDay( selectedDay, 
                _setCalendarWeek(
                    newCalendarWeek
                ) 
            );
        }
    }

    const _getFirstDateOfCalendarWeek = ( nextWeekNumber ) => {
        var week = [];
        var year = calendar.getCurrentYear();
        if( nextWeekNumber < 1 ) { return null; }
        for( var month in calendar.content[year] ) {
            week = calendar.content[year][month].days.filter( day => day.calendarWeek === nextWeekNumber );

            week = week.filter( day => new Date(`${day.year}-${day.month}-${day.digit}`).setHours(0,0,0,0) >= new Date().setHours(0,0,0,0));

            if( week.length > 0 ) {
                return `${week[0].year}-${week[0].month}-${week[0].digit}`;
            }
        }

        return null;
    }

    const _switchToCurrentDay = () => {
        _setSelectedDayHandler( calendar.getCurrentDate() );
    }

    const _switchToNextWeek = () => {
        var nextCalendarWeekDate = _getFirstDateOfCalendarWeek( ( _calendarWeek.number + 1 ) );
        if( nextCalendarWeekDate === null ) { return; }
        _setSelectedDayHandler( nextCalendarWeekDate );
    }
    
    const _switchToPrevWeek = () => {
        var nextCalendarWeekDate = _getFirstDateOfCalendarWeek( ( _calendarWeek.number - 1 ) );
        if( nextCalendarWeekDate === null ) { return; }

        _setSelectedDayHandler( nextCalendarWeekDate );
    }

    useEffect(() => {
        _scrollToSelectedDay();
    }, [selectedDay]);

    return (
        <div className="we-calendar-week-select">
            <div className="we-calendar-week-select__information">
                { (_calendarWeek.number > calendar.getCalendarWeekNumber() || _calendarWeek - 1 < 0) ?
                    <button className="we-calendar-week-select__btn prev" onClick={_switchToPrevWeek}>
                        <svg width="7px" height="12px" viewBox="0 0 7 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                                <g transform="translate(-45.000000, -75.000000)" stroke="#FFFFFF" strokeWidth="2">
                                    <g id="Gruppe_181">
                                        <g transform="translate(25.000000, 57.000000)">
                                            <polyline id="chevron-down-6" transform="translate(23.856000, 24.212000) rotate(90.000000) translate(-23.856000, -24.212000) " points="19.568 22.068 23.856 26.356 28.144 22.068"></polyline>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </button>
                : ""}
                
                {
                    /*
                 _calendarWeek ? 
                <p onClick={_switchToCurrentDay}>
                    KW {_calendarWeek.number}
                    <small>{`${(_calendarWeek && _calendarWeek.firstDate && _calendarWeek.firstDate.digit ) ? _calendarWeek.firstDate.digit : ""}.${(_calendarWeek && _calendarWeek.firstDate && _calendarWeek.firstDate.month ) ? _calendarWeek.firstDate.month : ""}`} - {`${_calendarWeek.lastDate.digit}.${_calendarWeek.lastDate.month}`}</small>
                </p>
                : ""
                    */
                }
                <button className="we-calendar-week-select__btn next" onClick={_switchToNextWeek}>
                    <svg width="7px" height="12px" viewBox="0 0 7 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                            <g transform="translate(-362.000000, -75.000000)" stroke="#FFFFFF" strokeWidth="2">
                                <g>
                                    <g transform="translate(365.000000, 81.000000) rotate(180.000000) translate(-365.000000, -81.000000) translate(341.000000, 57.000000)">
                                        <polyline id="chevron-down-7" transform="translate(23.856000, 24.212000) rotate(90.000000) translate(-23.856000, -24.212000) " points="19.568 22.068 23.856 26.356 28.144 22.068"></polyline>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </button>
            </div>
            <ul className="we-calendar-week-select__days">
                {calendar.getAllDays().map(day => {
                    if (new Date(`${parseInt(day.year)}-${calendar._leadingZero( parseInt(day.month) )}-${calendar._leadingZero( parseInt(day.digit) )}`).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0) ) {
                        if( new Date(`${parseInt(day.year)}-${calendar._leadingZero( parseInt(day.month) )}-${calendar._leadingZero( parseInt(day.digit) )}`).setHours(0, 0, 0, 0) === new Date(_selectedDay).setHours(0, 0, 0, 0) ) {
                            return <CalendarDay key={`DAY_${parseInt(day.year)}-${parseInt(day.month)}-${parseInt(day.digit)}`} day={day} setSelectedDay={_setSelectedDayHandler} selectedDay={_selectedDay} calendar={_calendar} myRef={myRef} />;
                        }else {
                            return <CalendarDay key={`DAY_${parseInt(day.year)}-${parseInt(day.month)}-${parseInt(day.digit)}`} day={day} setSelectedDay={_setSelectedDayHandler} selectedDay={_selectedDay} calendar={_calendar} />;
                        }
                    } else {
                        return "";
                    }
                })}
            </ul>
        </div>
    )
}