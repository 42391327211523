import React, { useState, useEffect, useContext } from "react";
import LOCATION_TYPES from "../classes/LOCATION_TYPES";
import { Redirect, Switch, Route, NavLink, useParams, useLocation } from "react-router-dom";
import Select from 'react-select/creatable';

import Calendar from "../classes/Calendar";

import RequestCard2 from "../components/RequestCard2";

import Loading from './../components/Loading';

import { AppStateContext } from './../components/AppStateProvider';

import API from './../classes/API';

//createUser

export default function EmployeeDetails({sessionToken}) { 
    const [isLoading, setIsLoading] = useState( true );
    const [employee, setEmployee] = useState( null );
    const [employeeData, setEmployeeData] = useState( null );
    const [employeeTeams, setEmployeeTeams] = useState([]);
    const [employeeRegion, setEmployeeRegion] = useState([]);
    const [employeeCopy, setEmployeeCopy] = useState( null );
    const [entitlements, setEntitlements] = useState({});
    const [showSaveButton, setShowSaveButton] = useState( false );

    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    const [teamOptions, setTeamOptions] = useState( [] );
    const [regionOptions, setRegionOptions] = useState( [] );
    const [systemData, setSystemData] = useState( {} );
    const [freeWeekdays, setFreeWeekdays] = useState(["6","7"]);

    const [selectHomeofficeRequests, setSelectHomeofficeRequests] = useState( true );
    const [selectVacationRequests, setSelectVacationRequests] = useState( true );
    const [selectSickeRequests, setSelectSickRequests] = useState( true );
    const [selectOutOfOfficeRequests, setSelectOutOfOfficeRequests] = useState( true );

    var calendar = new Calendar();

    var totalHomeofficeCount = 0;
    var totalVacationCount = 0;
    var totalSickCount = 0;
    var totalOutOfOfficeCount = 0;
    var totalRequests = 0;
    var totalRequests = employeeData ? employeeData.length : 0;

    let { employeeId } = useParams(2);
    let location = useLocation();
    var totalFactor = 0;
    var requests = [];

    const removeRequest = (requestId) => {
        var requests = employeeData.filter(request => request.id !== requestId);
        setEmployeeData( requests );
    }

    const isPropertyExists = (obj, level, ...rest) => {
        if (obj === undefined) return false;
        if (rest.length == 0 && obj.hasOwnProperty(level)) return true;
        return isPropertyExists(obj[level], ...rest)
    }

    const _handlerSelectNoWorkDays = ( e ) => {
        var noWorkDays = isPropertyExists( employeeCopy, "defaults", "daysNOWORK") ? employeeCopy.defaults.daysNOWORK : [];
        if( e.target.checked ) {
            noWorkDays = [...noWorkDays, parseInt(e.target.value)];
            setEmployeeCopyHandler({...employeeCopy, defaults: {...employeeCopy.defaults, daysNOWORK: [...employeeCopy.defaults.daysNOWORK, e.target.value]}});
        } else {
            noWorkDays = noWorkDays.filter(weekDay => weekDay !== parseInt(e.target.value));
        }
        setEmployeeCopyHandler({...employeeCopy, defaults: {
            ...employeeCopy.defaults,
            daysNOWORK: noWorkDays
        }});
    }

    const setEmployeeCopyHandler = ( updatedEmployee ) => {
        setEmployeeCopy( updatedEmployee, setShowSaveButton( (JSON.stringify(employee) !== JSON.stringify(updatedEmployee)) ) );
    }

    const updateEmployee = () => {
        if( !employeeId ) { return; }
        API.setEmployee(sessionToken, employeeId, employeeCopy.firstname, employeeCopy.lastname, employeeCopy.nr, employeeCopy.isActive, employeeCopy.birthdate ).then(response => {
            if( response.success ) {
                setEmployee( employeeCopy, setShowSaveButton( false ) );
            }
        });
    }

    useEffect(() => {
        if( isLoading && employee === null ) {
            API.getEmployee(sessionToken, employeeId).then(response => {
                if( response.success ) {
                    if( response.hasOwnProperty("system") ) {
                        setEmployee( response.data, setEmployeeCopy(response.data, setSystemData( response.system ), setIsLoading( false ) ) );
                    } else {
                        setEmployee( response.data, setEmployeeCopy(response.data, setIsLoading( false ) ) );
                    }
                } else {
                    setIsLoading( false );
                }
            });
        }
        if( employeeId ) {
            API.getEmployeeData( sessionToken, employeeId, selectedYear ).then(response => {
                if( response.success ) {
                    if( response.data && response.data.requests ) {
                        if( response.hasOwnProperty("system") ) {
                            setEmployeeData( response.data.requests, setSystemData({...systemData, entitlements: response.system.entitlements}, setEntitlements( response.data.entitlements ) ) );
                        } else {
                            setEmployeeData( response.data.requests, setEntitlements( response.data.entitlements ) );
                        }
                    }
                }
            });
        }
    },[isLoading, selectedYear]);

    if( employeeData ) {
        employeeData.forEach( request => {
            var factor = 0;
            request.days.forEach(day => factor+= day.factor);
            totalFactor+=factor;
            switch( request.type ) {
                case LOCATION_TYPES.OUTOFOFFICE.filterName:
                    totalOutOfOfficeCount+=factor;
                    break;
                case LOCATION_TYPES.HOMEOFFICE.filterName:
                    totalHomeofficeCount+=factor;
                    break;
                case LOCATION_TYPES.VACATION.filterName:
                    totalVacationCount+=factor;
                    break;
                case LOCATION_TYPES.VACATION_SPECIAL.filterName:
                    totalVacationCount+=factor;
                    break;
                case LOCATION_TYPES.SICK.filterName:
                    totalSickCount+=factor;
                    break;
                case LOCATION_TYPES.SICK_CHILD.filterName:
                    totalSickCount+=factor;
                    break;
                case LOCATION_TYPES.SICK_INOFFICIAL.filterName:
                    totalSickCount+=factor;
                    break;
            }
        });
    }

    if( isLoading ) {
        return(
            <section className="we-employee">
                <h2>Detailansicht</h2>
                <Loading/>
            </section>
        )
    }

    if( employee === null || employeeCopy === null ) {
        return (
            <section className="we-employee">
                <h2>Detailansicht</h2>
                <p>Leider kein Nutzer gefunden</p>
            </section>
        )    
    }

    if( location ) {
        if( location.state ) {
            if( location.state.lastLocation ) {
                
            }
        }
    }

    if( selectHomeofficeRequests && employeeData ) {
        requests = requests.concat( employeeData.filter(request => request.type === LOCATION_TYPES.HOMEOFFICE.filterName));
    }
    
    if( selectOutOfOfficeRequests && employeeData  ) {
        requests = requests.concat(employeeData.filter(request => request.type === LOCATION_TYPES.OUTOFOFFICE.filterName));
        
    }
    
    if( selectSickeRequests && employeeData  ) {
        requests = requests.concat(employeeData.filter(request => (request.type === LOCATION_TYPES.SICK.filterName || request.type === LOCATION_TYPES.SICK_CHILD.filterName || request.type === LOCATION_TYPES.SICK_INOFFICIAL.filterName)));
    }
    
    if( selectVacationRequests && employeeData  ) {
        requests = requests.concat(employeeData.filter(request => (request.type === LOCATION_TYPES.VACATION.filterName || request.type === LOCATION_TYPES.VACATION_SPECIAL.filterName )));
    }
    requests.sort((a,b) => {
        if( !a.days && !b.days ) { return false; }
        if( !a.days[0] && !b.days[0] ) { return false; }
        if( !a.days[0].day && !b.days[0].day ) { return false; }
        return (a.days[0].day > b.days[0].day) ? 1 : ((b.days[0].day > a.days[0].day) ? -1 : 0);
    });

    return (
        <section className="we-employee">
            <NavLink className="we-employee__back-btn" to={(location && location.state && location.state.lastLocation) ? `${process.env.PUBLIC_URL}` : `${process.env.PUBLIC_URL}/dashboard`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 14 14" version="1.1"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round"><g transform="translate(-35.000000, -70.000000)" stroke="#FFFFFF" strokeWidth="2"><g><g transform="translate(18.000000, 53.000000)"><g transform="translate(24.104000, 23.903000) rotate(180.000000) translate(-24.104000, -23.903000) translate(18.604000, 18.403000)"><line x1="0.403" y1="5.403" x2="10.403" y2="5.403" id="Linie_41" strokeLinejoin="round"></line><polyline points="5.403 0 10.803 5.4 5.403 10.8"></polyline></g></g></g></g></g></svg>
            </NavLink>
            <h2>Detailansicht<small>Mitarbeiter</small></h2>
            <div className="we-employee__wrapper">
                <div className="we-employee__details">
                    <div className="img-wrapper">
                        {employeeCopy.img ? 
                            <img className="we-employee__img" src={`data:image/png;base64,${employeeCopy.img}`} alt="" title="" /> 
                            : 
                            <span className="we-employee__img">
                            {employeeCopy.firstname ? employeeCopy.firstname.charAt(0) : ""}
                            {employeeCopy.lastname ? employeeCopy.lastname.charAt(0) : ""}
                        </span> }
                        <p className="we-employee__name">{employeeCopy.firstname} {employeeCopy.lastname}</p>
                    </div>
                <hr />
                <div>
                    <label className="we-toggle">
                        <input
                            type="checkbox"
                            value={employeeCopy.isActive}
                            checked={employeeCopy.isActive}
                            onChange={() => setEmployeeCopyHandler({...employeeCopy, isActive: !employeeCopy.isActive})}
                        />
                        <span>
                            <span>&nbsp;</span>
                        </span>
                        
                        <p>{employeeCopy.isActive ? "Der Nutzer ist Aktiv" : "Der Nutzer ist deaktiviert."}</p>
                    </label>
                    {employeeCopy.isActive ? "" : 
                    <p className="we-request__note">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
                        Der Nutzer ist im System deaktiviert. Er kann sich nicht mehr anmelden und wird nirgends angezeigt.
                    </p>
                    }
                </div>
                <label>
                    Personal Nr.
                    <input
                        type="number"
                        className="we-input"
                        value={employeeCopy.nr ? employeeCopy.nr : ""}
                        onChange={(e) => setEmployeeCopyHandler({...employeeCopy, nr: e.target.value})}
                    />
                </label>
                <label>
                    Vorname
                    <input
                        type="text"
                        className="we-input"
                        value={employeeCopy.firstname}
                        onChange={(e) => setEmployeeCopyHandler({...employeeCopy, firstname: e.target.value})}
                    />
                </label>
                <label>
                    Nachname
                    <input
                        type="text"
                        className="we-input"
                        value={employeeCopy.lastname}
                        onChange={(e) => setEmployeeCopyHandler({...employeeCopy, lastname: e.target.value} )}
                    />
                </label>
                <label>
                    Geburtstag
                    <input
                        type="date"
                        className="we-input"
                        value={employeeCopy.birthdate}
                        onChange={(e) => setEmployeeCopyHandler({...employeeCopy, birthdate: e.target.value})}
                    />
                </label>
                <p className="we-add-employee__subline">
                    Daten bearbeiten 
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22.008" viewBox="0 0 20 22.008">
                        <g id="Gruppe_613" transform="translate(-788 -582.367)" opacity="0.33">
                            <g id="unlock" transform="translate(789 583.37)">
                            <rect id="Rechteck_495" width="18" height="11" rx="2" transform="translate(0 9.005)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                            <path id="Pfad_218" d="M7,11V7a5,5,0,0,1,9.9-1" transform="translate(-3 -1.995)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                            </g>
                            <line id="Linie_138" y2="4.972" transform="translate(802.929 587.41)" fill="none" stroke="#fff" strokeWidth="2"/>
                        </g>
                    </svg>

                </p>
                <label>
                    Urlaubsanspruch
                    <input
                        type="number"
                        className="we-input"
                        value={employeeCopy.defaults.entitlement}
                        onChange={(event) => { setEmployeeCopyHandler({ ...employeeCopy, defaults: {  ...employeeCopy.defaults, entitlement: event.target.value } })} }
                        disabled
                    />
                </label>
                <label>
                    Vertragsbegin
                    <input
                        type="date"
                        className="we-input"
                        value={isPropertyExists(employeeCopy, "contract", "startDate") ? employeeCopy.contract.startDate : ""}
                        onChange={(e) => setEmployeeCopyHandler({...employeeCopy, contract: {...employeeCopy.contract, startDate: e.target.value}})}
                        disabled
                    />
                </label>
                <label>
                    Vertragsende
                    <input
                        type="date"
                        className="we-input"
                        value={isPropertyExists(employeeCopy, "contract", "endDate") ? employeeCopy.contract.endDate : ""}
                        onChange={(e) => setEmployeeCopyHandler({...employeeCopy, contract: {...employeeCopy.contract, endDate: e.target.value}})}
                        disabled
                    />
                </label>
                <label>
                    Region
                    <div className="we-select__wrapper">
                        <select 
                            className="we-select" 
                            onChange={(e) => setEmployeeCopyHandler({...employeeCopy, regionId: e.target.value})}
                            value={employeeCopy.regionId}
                            disabled
                        >
                            <option key={`REGION_OPTION_0`} value={""} >Bitte wählen</option>
                            {systemData.regions ? 
                            
                            systemData.regions.map( (region, index) => <option key={`REGION_OPTION_${(index+1)}`} value={region.id} >{region.name}</option>)
                            : ""}
                        </select>
                        <span className="we-select__arrow">
                            <svg width="12px" height="8px" viewBox="0 0 12 8" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                                    <g transform="translate(-212.000000, -257.000000)" stroke="#FFFFFF" strokeWidth="2">
                                        <g>
                                            <g transform="translate(18.000000, 240.000000)">
                                                <g transform="translate(200.000000, 20.000000) rotate(90.000000) translate(-200.000000, -20.000000) translate(189.000000, 9.000000)">
                                                    <polyline transform="translate(12.000000, 11.000000) rotate(-90.000000) translate(-12.000000, -11.000000) " points="7.712 8.856 12 13.144 16.288 8.856"></polyline>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </span>
                    </div>
                </label>
                <label>
                    Krank-auf-Kind Tage
                    <input
                        type="number"
                        className="we-input"
                        value={isPropertyExists(employeeCopy, "defaults", "sickOnChild") ? employeeCopy.defaults.sickOnChild : ""}
                        onChange={(event) => { setEmployeeCopyHandler({ ...employeeCopy, defaults: {  ...employeeCopy.defaults, sickOnChild: event.target.value } })} }
                        disabled   
                    />
                </label>
                <p className="we-add-employee__subline">Freie Tage</p>
                    <div className="we-add-employee__days">
                        <label className="we-checkbox">
                            Mo.
                            <input
                                type="checkbox"
                                value="1"
                                checked={employeeCopy.defaults.daysNOWORK.filter(day => day === 1).length > 0}
                                onChange={_handlerSelectNoWorkDays}
                                disabled
                            />
                            <span className="we-checkbox__input">&nbsp;</span>
                        </label>
                        <label className="we-checkbox">
                            Di.
                            <input
                                type="checkbox"
                                value="2"
                                checked={employeeCopy.defaults.daysNOWORK.filter(day => day === 2).length > 0}
                                onChange={_handlerSelectNoWorkDays}
                                disabled
                            />
                            <span className="we-checkbox__input">&nbsp;</span>
                        </label>
                        <label className="we-checkbox">
                            Mi.
                            <input
                                type="checkbox"
                                value="3"
                                checked={employeeCopy.defaults.daysNOWORK.filter(day => day === 3).length > 0}
                                onChange={_handlerSelectNoWorkDays}
                                disabled
                            />
                            <span className="we-checkbox__input">&nbsp;</span>
                        </label>
                        <label className="we-checkbox">
                            Do.
                            <input
                                type="checkbox"
                                value="4"
                                checked={employeeCopy.defaults.daysNOWORK.filter(day => day === 4).length > 0}
                                onChange={_handlerSelectNoWorkDays}
                                disabled
                            />
                            <span className="we-checkbox__input">&nbsp;</span>
                        </label>
                        <label className="we-checkbox">
                            Fr.
                            <input
                                type="checkbox"
                                value="5"
                                checked={employeeCopy.defaults.daysNOWORK.filter(day => day === 5).length > 0}
                                onChange={_handlerSelectNoWorkDays}
                                disabled
                            />
                            <span className="we-checkbox__input">&nbsp;</span>
                        </label>
                        <label className="we-checkbox">
                            Sa.
                            <input
                                type="checkbox"
                                value="6"
                                checked={employeeCopy.defaults.daysNOWORK.filter(day => day === 6).length > 0}
                                onChange={_handlerSelectNoWorkDays}
                                disabled
                            />
                            <span className="we-checkbox__input">&nbsp;</span>
                        </label>
                        <label className="we-checkbox">
                            So.
                            <input
                                type="checkbox"
                                value="7"
                                checked={employeeCopy.defaults.daysNOWORK.filter(day => day === 7).length > 0}
                                onChange={_handlerSelectNoWorkDays}
                                disabled
                            />
                            <span className="we-checkbox__input">&nbsp;</span>
                        </label>
                        
                    </div>
                    <hr />
                </div>
                <div className="we-employee__statistic">
                    <label>
                    Liste aller Anträge
                    <div className="we-select__wrapper">
                        <select 
                            className="we-select" 
                            onChange={(e) => setSelectedYear(e.target.value)}
                            value={selectedYear}
                        >
                            {systemData.entitlements ? systemData.entitlements.map((entitlement, index) => <option key={`YEAR_OPTION_${index+1}`} value={entitlement.year} >{entitlement.year}</option>) : ""}
                            
                            
                        </select>
                        <span className="we-select__arrow">
                            <svg width="12px" height="8px" viewBox="0 0 12 8" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                                    <g transform="translate(-212.000000, -257.000000)" stroke="#FFFFFF" strokeWidth="2">
                                        <g>
                                            <g transform="translate(18.000000, 240.000000)">
                                                <g transform="translate(200.000000, 20.000000) rotate(90.000000) translate(-200.000000, -20.000000) translate(189.000000, 9.000000)">
                                                    <polyline transform="translate(12.000000, 11.000000) rotate(-90.000000) translate(-12.000000, -11.000000) " points="7.712 8.856 12 13.144 16.288 8.856"></polyline>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </span>
                    </div>
                </label>
                <div className="we-employee__requests-select">
                    <label>
                        <input 
                            type="checkbox" 
                            value={selectHomeofficeRequests}
                            checked={selectHomeofficeRequests}
                            onChange={() => setSelectHomeofficeRequests( !selectHomeofficeRequests ) }
                        />
                        <p>{totalHomeofficeCount ? totalHomeofficeCount : ""}</p>
                        <span className="we-employee-grafic homeoffice" style={{height: `${window.getPercentValueOf( window.getPercent(totalHomeofficeCount, totalFactor), 130)}px`}}></span>
                        <p>Home<br/>Office</p>
                        {selectHomeofficeRequests ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg> : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye-off"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>}
                    </label>
                    <label>
                        <input 
                            type="checkbox" 
                            value={selectVacationRequests}
                            checked={selectVacationRequests}
                            onChange={() => setSelectVacationRequests( !selectVacationRequests ) }
                        />
                        <p>{totalVacationCount ? totalVacationCount : ""}</p>
                        <span className="we-employee-grafic vacation" style={{height: `${window.getPercentValueOf( window.getPercent(totalVacationCount, totalFactor), 130)}px`}}></span>
                        <p>Urlaub</p>
                        {selectVacationRequests ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg> : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye-off"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>}
                    </label>
                    <label>
                        <input 
                            type="checkbox" 
                            value={selectSickeRequests}
                            checked={selectSickeRequests}
                            onChange={() => setSelectSickRequests( !selectSickeRequests ) }
                        />
                        <p>{totalSickCount ? totalSickCount : ""}</p>
                        <span className="we-employee-grafic sick" style={{height: `${window.getPercentValueOf( window.getPercent(totalSickCount, totalFactor), 130)}px`}}></span>
                        <p>Krank</p>
                        {selectSickeRequests ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg> : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye-off"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>}
                    </label>
                    <label>
                        <input 
                            type="checkbox" 
                            value={selectOutOfOfficeRequests}
                            checked={selectOutOfOfficeRequests}
                            onChange={() => setSelectOutOfOfficeRequests( !selectOutOfOfficeRequests ) }
                        />
                        <p>{totalOutOfOfficeCount ? totalOutOfOfficeCount : ""}</p>
                        <span className="we-employee-grafic outofoffice" style={{height: `${window.getPercentValueOf( window.getPercent(totalOutOfOfficeCount, totalFactor), 130)}px`}}></span>
                        <p>Neutr. Abws.</p>
                        {selectOutOfOfficeRequests ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg> : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye-off"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>}
                    </label>
                </div>
                {entitlements ?
                    <p className="we-employee__entitlements-details">
                        Der Gesamtanspruch <b>{selectedYear ? selectedYear : "-"}</b> beträgt <b>{(entitlements.vacation && entitlements.vacation.hasOwnProperty("entitlement")) ? <>{entitlements.vacation.entitlement}&nbsp;{entitlements.vacation.entitlement === 1 ? "Tag" : "Tage"}</> : "-"}</b>. Es besteht ein Restanspruch von <b>{(entitlements.vacation && entitlements.vacation.hasOwnProperty("rest") && entitlements.vacation.hasOwnProperty("lastYear")) ? <>{(entitlements.vacation.rest + entitlements.vacation.lastYear)}&nbsp;{entitlements.vacation.rest === 1 ? "Tag" : "Tage"}</> : "-"}</b> mit <b>{(entitlements.vacation && entitlements.vacation.hasOwnProperty("lastYear")) ? <>{entitlements.vacation.lastYear}&nbsp;{entitlements.vacation.lastYear === 1 ? "Tag" : "Tage"}</> : "-"}</b> aus dem Vorjahr.
                    </p>
                : ""}
                
                {requests && requests.length > 0 ? requests.map( request => <RequestCard2 request={request} calendar={calendar} removeRequest={removeRequest} />) : ""}
                </div>
            </div>
            
            {showSaveButton ? <button className="we-employee-details__save-btn we-btn" onClick={updateEmployee}>Speichern</button> : ""}
        </section>
    )
}

function EmployeeRequests( {requests,selectedYear} ) {
    const [selectHomeofficeRequests, setSelectHomeofficeRequests] = useState( true );
    const [selectVacationRequests, setSelectVacationRequests] = useState( true );
    const [selectSickeRequests, setSelectSickRequests] = useState( true );
    const [selectOutOfOfficeRequests, setSelectOutOfOfficeRequests] = useState( true );

    if( requests ) {
        var totalHomeofficeCount = 0;
        var totalVacationCount = 0;
        var totalSickCount = 0;
        var totalOutOfOfficeCount = 0;
        var totalRequests = 0;
        var keys = Object.keys(requests);
        var homeOfficeRequests = [];
        var vacationRequests = [];
        var sickRequests = [];
        var outofofficeRequests = [];

        const requestKeys = Object.keys(requests)
        for( var i = 0; i < requestKeys.length; i++ ) {
            for( var x = 0; x < requests[requestKeys[i]].length; x++ ) {
                var totalDays = 0;
                requests[requestKeys[i]][x].type = requestKeys[i];
                requests[requestKeys[i]][x].days.forEach(day => {
                    totalDays += day.factor
                } );
                switch( requestKeys[i] ) {
                    case LOCATION_TYPES.HOMEOFFICE.name:
                        totalHomeofficeCount += totalDays;
                        break;
                    case LOCATION_TYPES.VACATION.name:
                        totalVacationCount += totalDays;
                        break;
                    case LOCATION_TYPES.SICK_INOFFICIAL.name:
                    case LOCATION_TYPES.SICK_CHILD.name:
                    case LOCATION_TYPES.SICK.name:
                        totalSickCount += totalDays;
                        break;
                    case LOCATION_TYPES.OUTOFOFFICE.name:
                        totalOutOfOfficeCount += totalDays;
                        break;
                }
            }
        }


        totalRequests = totalHomeofficeCount + totalOutOfOfficeCount + totalSickCount + totalVacationCount;
        
        if( requests[LOCATION_TYPES.HOMEOFFICE.name] ) {
            homeOfficeRequests = requests[LOCATION_TYPES.HOMEOFFICE.name];
        }

        if( requests[LOCATION_TYPES.VACATION.name] ) {
            vacationRequests = requests[LOCATION_TYPES.VACATION.name];
        }

        if( requests[LOCATION_TYPES.SICK.name] ) {
            Array.prototype.push.apply(sickRequests, requests[LOCATION_TYPES.SICK.name]);
        }
        if( requests[LOCATION_TYPES.SICK_CHILD.name] ) {
            Array.prototype.push.apply(sickRequests, requests[LOCATION_TYPES.SICK_CHILD.name]);
        }
        if( requests[LOCATION_TYPES.SICK_INOFFICIAL.name] ) {
            Array.prototype.push.apply(sickRequests, requests[LOCATION_TYPES.SICK_INOFFICIAL.name]);
        }

        if( requests[LOCATION_TYPES.OUTOFOFFICE.name] ) {
            outofofficeRequests = requests[LOCATION_TYPES.OUTOFOFFICE.name];
        }

        return(
            <>
                <div className="we-employee__requests-select">
                    {homeOfficeRequests ? 
                        <label>
                            <input 
                                type="checkbox" 
                                value={selectHomeofficeRequests}
                                checked={selectHomeofficeRequests}
                                onChange={() => setSelectHomeofficeRequests( !selectHomeofficeRequests ) }
                            />
                            {/*<p>{totalHomeofficeCount ? totalHomeofficeCount : ""}</p>*/}
                            <span className="we-employee-grafic homeoffice" style={{height: `${window.getPercentValueOf( window.getPercent(totalHomeofficeCount, totalRequests), 130)}px`}}></span>
                            <p>Home<br/>Office</p>
                            {selectHomeofficeRequests ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg> : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye-off"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>}
                        </label>
                    : ""}
                    {vacationRequests ? 
                        <label>
                            <input 
                                type="checkbox" 
                                value={selectVacationRequests}
                                checked={selectVacationRequests}
                                onChange={() => setSelectVacationRequests( !selectVacationRequests ) }
                            />
                            {/*<p>{totalVacationCount ? totalVacationCount : ""}</p>*/}
                            <span className="we-employee-grafic vacation" style={{height: `${window.getPercentValueOf( window.getPercent(totalVacationCount, totalRequests), 130)}px`}}></span>
                            <p>Urlaub</p>
                            {selectVacationRequests ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg> : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye-off"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>}
                        </label>
                    : ""}
                    {sickRequests ? 
                        <label>
                            <input 
                                type="checkbox" 
                                value={selectSickeRequests}
                                checked={selectSickeRequests}
                                onChange={() => setSelectSickRequests( !selectSickeRequests ) }
                            />
                            {/*<p>{totalSickCount ? totalSickCount : ""}</p>*/}
                            <span className="we-employee-grafic sick" style={{height: `${window.getPercentValueOf( window.getPercent(totalSickCount, totalRequests), 130)}px`}}></span>
                            <p>Krank</p>
                            {selectSickeRequests ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg> : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye-off"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>}
                        </label>
                    : ""}
                    {outofofficeRequests ? 
                        <label>
                            <input 
                                type="checkbox" 
                                value={selectOutOfOfficeRequests}
                                checked={selectOutOfOfficeRequests}
                                onChange={() => setSelectOutOfOfficeRequests( !selectOutOfOfficeRequests ) }
                            />
                            <p>{totalOutOfOfficeCount ? totalOutOfOfficeCount : ""}</p>
                            <span className="we-employee-grafic outofoffice" style={{height: `${window.getPercentValueOf( window.getPercent(totalOutOfOfficeCount, totalRequests), 130)}px`}}></span>
                            <p>Neutr. Abws.</p>
                            {selectOutOfOfficeRequests ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg> : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye-off"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>}
                        </label>
                    : ""}
                </div>
            </>
        )
    } else {
        return "";
    }
}

function EmployeeRequest ({type,request,selectedYear} ) {
    var dayCount = 0;
    var startDate = request.days[0].day ? request.days[0].day : "";
    var endDate = request.days[(request.days.length - 1)].day ? request.days[(request.days.length - 1)].day : "";
    var days = [];

    for( var i = 0; i < request.days.length; i++ ) {
        var entitlementInfo = "";
        var entitlement = parseInt(request.days[i].entitlement);
        if( entitlement < parseInt( selectedYear ) ) {
            entitlementInfo = "(VJ)";
        }
        if( entitlement > parseInt( selectedYear ) ) {
            entitlementInfo = "(FJ)";
        }
        dayCount += request.days[i].factor
        days.push( <li className="we-employee-request-day" key={`${type}_${request.days[i].day}`}>
            <p>
                {LOCATION_TYPES[request.type].label}
                <b>{window.formatDate(request.days[i].day)}</b> 
            </p>
            <span>{request.days[i].factor} {request.days[i].factor > 1 ? "Tage" : "Tag"} {entitlementInfo}</span>
            <div className="we-employee-request-day__action"></div>
        </li>);
    }

    return(
        <li className="we-employee-request">
            
            {request.days.length > 1 ?
            <>
            <input type="checkbox" id={`${type}_${startDate}_${endDate}_${dayCount}`}/>
            <label htmlFor={`${type}_${startDate}_${endDate}_${dayCount}`}>
                <p>
                    {LOCATION_TYPES[request.type].label}
                    <b>{startDate === endDate ? `${window.formatDate(startDate)}` : `${window.formatDate(startDate)} - ${window.formatDate(endDate)}`}</b> 
                </p>
                <span>{dayCount} {dayCount > 1 ? "Tage" : "Tag"}</span>
                <div className="we-employee-request__action">
                    <svg width="12px" height="8px" viewBox="0 0 12 8" version="1.1" xmlns="http://www.w3.org/2000/svg"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round"><g transform="translate(-212.000000, -257.000000)" stroke="#FFFFFF" strokeWidth="2"><g><g transform="translate(18.000000, 240.000000)"><g transform="translate(200.000000, 20.000000) rotate(90.000000) translate(-200.000000, -20.000000) translate(189.000000, 9.000000)"><polyline transform="translate(12.000000, 11.000000) rotate(-90.000000) translate(-12.000000, -11.000000) " points="7.712 8.856 12 13.144 16.288 8.856"></polyline></g></g></g></g></g></svg>
                </div>
            </label>
                <ul className="we-employee-request__days">{days}</ul>
            </>
            : 
            <>
                <p>
                    {LOCATION_TYPES[request.type].label}
                    <b>{startDate === endDate ? `${window.formatDate(startDate)}` : `${window.formatDate(startDate)} - ${window.formatDate(endDate)}`}</b> 
                </p>
                <span>{dayCount} {dayCount > 1 ? "Tage" : "Tag"}</span>
                <div className="we-employee-request__action"></div>
            </>
            }
        </li>
    )
}