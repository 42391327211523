import React, { useState } from "react";
import API from "../classes/API";
import { Link } from "react-router-dom";

export default function HelpLink() {
    return(
        <p className="we-help-link">
            Ist etwas unklar oder hast du Feedback?
            <Link to={`${process.env.PUBLIC_URL}/hilfe`}>
                Schreibe uns eine Nachricht! 
                <svg viewBox="0 0 120.568 126.799"><path d="M108.714,96.183a60.292,60.292,0,1,0-23.32,18.907l-.044.035L120.568,126.8,108.726,96.174Zm-77.4-25.262a8.966,8.966,0,1,1,8.966-8.966A8.966,8.966,0,0,1,31.309,70.921Zm28.786,0a8.966,8.966,0,1,1,8.967-8.966A8.966,8.966,0,0,1,60.1,70.921Zm28.785,0a8.966,8.966,0,1,1,8.967-8.966A8.966,8.966,0,0,1,88.88,70.921Z" fill="#fff"/></svg>
            </Link>
        </p>
    )
}