import React, { useState } from "react";
import API from "../classes/API";
import { Link } from "react-router-dom";

import LOCATION_TYPES from "../classes/LOCATION_TYPES";
import REQUEST_STATUS from "../classes/REQUEST_STATUS";

export default function RequestCard({ request, past = false, setSelectedRequest, deleteRequest, deleteDeniedRequest, acceptHolidayRequest, employeeID, hideTitle = false }) {
    var requestType = LOCATION_TYPES[request.type].name !== undefined ? LOCATION_TYPES[request.type].label : "";
    var requestStatus = request.status ? request.status : "";
    var cardIcon = "";
    var deniedAction = "";
    var isApproved = false; 
    const [localUser] = useState( API.getStorageUser() );

    if( request.approvals ) {
        isApproved = request.approvals[localUser.userID];
    }

    const formatDate = (date) => {
        date = date.split("-").reverse();
        date = date.join('.');
        return date;
    }

    if (requestStatus === REQUEST_STATUS.APPROVED) {
        cardIcon = <svg width="11px" height="9px" viewBox="0 0 11 9" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                <g transform="translate(-249.000000, -530.000000)" stroke="#FFFFFF" strokeWidth="2">
                    <g transform="translate(-41.000000, 0.000000)">
                        <g transform="translate(58.000000, 480.000000)">
                            <polyline points="232.991 54.728 235.831 57.568 241.511 51.888"></polyline>
                        </g>
                    </g>
                </g>
            </g>
        </svg>;
    } else if (requestStatus === REQUEST_STATUS.REQUESTED) {
        cardIcon = <svg width="13px" height="14px" viewBox="0 0 13 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-157.000000, -437.000000)" fill="#FFFFFF" fillRule="nonzero">
                    <g transform="translate(-41.000000, 0.000000)" id="noun_stop_watch_2273750">
                        <g transform="translate(197.539000, 437.233000)">
                            <g transform="translate(0.614000, 0.414000)">
                                <g>
                                    <path d="M6.295,1.972 C6.295,1.967 6.295,1.962 6.295,1.957 L6.295,1.186 L6.962,1.186 C7.28840029,1.186 7.553,0.921400287 7.553,0.595 C7.553,0.268599713 7.28840029,0.004 6.962,0.004 L4.447,0.004 C4.12059971,0.004 3.856,0.268599713 3.856,0.595 C3.856,0.921400287 4.12059971,1.186 4.447,1.186 L5.114,1.186 L5.114,1.961 C5.114,1.966 5.114,1.971 5.114,1.976 C2.09361685,2.28972147 -0.151932639,4.91404484 0.00513836076,7.9466121 C0.162209361,10.9791794 2.66686774,13.357467 5.7035,13.357467 C8.74013226,13.357467 11.2447906,10.9791794 11.4018616,7.9466121 C11.5589326,4.91404484 9.31338315,2.28972147 6.293,1.976 L6.295,1.972 Z M5.705,12.172 C3.20701608,12.172 1.182,10.1469839 1.182,7.649 C1.182,5.15101608 3.20701608,3.126 5.705,3.126 C8.20298392,3.126 10.228,5.15101608 10.228,7.649 C10.2241428,10.1450605 8.20106201,12.1672467 5.705,12.17 L5.705,12.172 Z" id="Pfad_149"></path>
                                    <path d="M11.987,3.715 C11.5141283,2.82349801 10.7818688,2.09646895 9.887,1.63 C9.59815508,1.47784556 9.24065446,1.58865509 9.08850001,1.87750001 C8.93634556,2.16634493 9.04715508,2.52384555 9.336,2.676 C10.0179614,3.03278126 10.5758049,3.58783554 10.936,4.268 C11.0347839,4.45574308 11.2262238,4.57652425 11.438206,4.58484639 C11.6501883,4.59316853 11.8505078,4.48776732 11.9637061,4.30834638 C12.0769044,4.12892543 12.085784,3.90274307 11.987,3.715 Z" id="Pfad_150"></path>
                                    <path d="M7.326,5.399 L5.391,7.329 C5.21785873,7.50214127 5.21785873,7.78285873 5.391,7.956 C5.56414127,8.12914127 5.84485873,8.12914127 6.018,7.956 L7.953,6.029 C8.12614125,5.85585873 8.12614124,5.57514128 7.95299998,5.40200002 C7.77985872,5.22885876 7.49914127,5.22885875 7.326,5.402 L7.326,5.399 Z" id="Pfad_151" stroke="#FFFFFF" strokeWidth="0.5"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>;
    }

    if ( requestStatus === "denied" ) {
        deniedAction = 
            <li>
                <button 
                    className="we-request-card__deneid-btn" 
                    onClick={() => deleteDeniedRequest([request.requestDate], employeeID)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                </button>
            </li>;
    }

    return (
        <li className={`we-request-card ${requestType.toLowerCase()} ${requestStatus.toLowerCase()} ${isApproved ? "is--muted" : ""}`}>
            <Link className="we-request-card__link" to={`${window.location.pathname}/${btoa(request.requestDate)}`}>&nbsp;</Link>
            <p className="we-request-card__title">
                <span>
                    {(request.title && request.title !== "" && !hideTitle) ? request.title : requestType}
                </span>
                {cardIcon}
                <small>
                    {request.dates.length > 1 ?
                        formatDate(request.dates[0]) + " - " + formatDate(request.dates[(request.dates.length - 1)])
                        :
                        formatDate(request.dates[0])
                    }
                    { request.days ? ` (${request.days === 0.5 ? "½" : request.days} ${request.days > 1 ? "Tage" : "Tag"})` : ""}
                </small>
            </p>
            {!isApproved ? 
            <ul className="we-request-card__actions">
                {acceptHolidayRequest ? 
                    <li>
                        <button className="we-request-card__action" onClick={() => acceptHolidayRequest( request, employeeID )}>
                            <svg width="14px" height="10px" viewBox="0 0 14 10" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                                    <g transform="translate(-260.000000, -252.000000)" stroke="#FFFFFF" strokeWidth="2">
                                        <g transform="translate(254.555000, 244.555000)">
                                            <polyline points="7.241 12.445 10.71 15.914 17.649 8.976"></polyline>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </button> 
                    </li>
                : ""}
                {requestStatus === REQUEST_STATUS.REQUESTED ? <li><button className="we-request-card__action foo" onClick={() => deleteRequest( request, employeeID )}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button></li> : ""}
                {deniedAction}
            </ul>
            : ""}
            
            <span className="we-request-card__details-btn">
                <svg width="8px" height="12px" viewBox="0 0 8 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                        <g transform="translate(-361.000000, -361.000000)" stroke="#FFFFFF" strokeWidth="2">
                            <g id="Gruppe_85">
                                <g transform="translate(18.000000, 289.000000)">
                                    <g id="Gruppe_70" transform="translate(335.000000, 67.000000)">
                                        <polyline id="chevron-down" transform="translate(12.000000, 11.000000) rotate(-90.000000) translate(-12.000000, -11.000000) " points="7.712 8.856 12 13.144 16.288 8.856"></polyline>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </span>
        </li>
    )
}